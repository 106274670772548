import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Footer from "../../components/footer/footer";
import { useGlobalContext } from "../../context/GlobalContext";
import "./index.scss";

export default function TermUse() {
  const globalContext = useGlobalContext();
  return (
    <>
      <Container>
        <div className="title">
          <h1>TERMO DE USO</h1>
        </div>
        <div className="text">
          {globalContext.configs && globalContext.configs[0] && 
            globalContext.configs[0].terms
          }
        </div>
      </Container>
      <Footer />
    </>
  );
}
