import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./index.scss";

import MyRequests from "../../components/myRequests";
import MyAccount from "../../components/myAccount";
import Contact from "../contact";
import Footer from "../../components/footer/footer";
import Loading from "../../assets/img/loading.svg";
import DB from "../../utils/firestore";
import { useGlobalContext } from "../../context/GlobalContext";
import LoginPage from "../login";
import { auth } from "../../utils/firebaseUtils";
import VerifyEmail from "../../components/verifyEmail/verifyEmail";

export default function Logged() {
  const [loadingg, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [link, setLink] = useState("MyRequests");
  const globalContext = useGlobalContext();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [globalContext.user])

  async function getData() {
    if (globalContext.user && globalContext.user.id) {
      const response = await DB.get("purchases", [
        { field: "id_user", condition: "==", value: globalContext.user.id },
      ]);
      if (response) {
        setData(response);
      } else {
        setError(true);
      }
    }
    setLoading(false);
  }

  return loadingg ? (
    <div className="loading loadingHeight">
      <img src={Loading} className="loadingMask" />
    </div>
  ) : !globalContext.user ? (
    <LoginPage noRedirect={true} />
  ) : (
    <>
      {!globalContext.user.autentication.emailVerified ? (
        <VerifyEmail />
      ) : (
        <Container>
          <div className="grid-div-logged">
            <div className="menu-logged">
              <a
                onClick={() => {
                  setLink("MyRequests");
                }}
                className={link === "MyRequests" && "active"}
              >
                <p className="link-menu">Meus Pedidos</p>
              </a>
              <div className="hr-center"></div>

              <a
                onClick={() => {
                  setLink("MyAccount");
                }}
                className={link === "MyAccount" && "active"}
              >
                <p className="link-menu">Minha Conta</p>
              </a>
              <div className="hr-center"></div>

              <a
                onClick={() => {
                  setLink("Contact");
                }}
                className={link === "Contact" && "active"}
              >
                <p className="link-menu">Fale Conosco</p>
              </a>
              <div className="hr-center"></div>
            </div>
            {link == "MyRequests" && (
              <MyRequests data={data} />
            )}
            {link == "MyAccount" && (
              <MyAccount data={data} />
            )}
            {link == "Contact" && (
              <Contact data={data} hideFooter={true} />
            )}
          </div>
        </Container>
      )}
      <Footer />
    </>
  );
}
