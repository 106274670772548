import api from "./api";

const PaymentService = {
    // {
    //     "id"            : 100,
    //     "reference_key" : "sale001",
    //     "amount"        : 100,
    //     "installments"  : 1,
    //     "customer"      : {
    //         "external_id"           : "ID_FIRESTORE",
    //         "street"                : "QS 01",
    //         "street_number"         : "S/N",
    //         "neighborhood"          : "Aguas Claras",
    //         "zipcode"               : "71950550",
    //         "complementary"         : "Ed. Connect Towers Sala 1604",
    //         "city_name"             : "Brasilia",
    //         "state_abbreviation"    : "df",
    //         "ddd"                   : "61",
    //         "phone"                 : "999999999",
    //         "name"                  : "Nome Silva Sousa",
    //         "email"                 : "email@email.com",
    //         "cpf_cnpj"              : "99999999999"
    //     },
    //     "card"          : {
    //         "card_holder_name"      : "Nome Silva Sousa",
    //         "card_expiration_date"  : "0121",
    //         "card_number"           : "9999999999999999",
    //         "card_cvv"              : "999"
    //     },
    //     "items"         : [
    //         {
    //             "id" : "1000",
    //             "description" : "passagem",
    //             "price" : 10,
    //             "quantity"  : 1,
    //             "tangible" : false
    //         }
    //     ]
    // }
    transaction: async (data) => {
        try {
            const res = await api.post("/paymentServiceV2/transaction", data);
            return res;
        } catch (error) {
            return error;
        }
    },

    //id
    refound: async (id) => {
        try {
            const res = await api.post("/paymentServiceV2/transaction/"+id+"/refound");
            return res;
        } catch (error) {
            return error;
        }
    },

    sendEmail: async (data) => {
        try {
            const res = await api.post("/utilsService/sendEmail", data);
            return res;
        } catch (error) {
            return error;
        }
    },

    // {
    //     "contact" : {
    //         "name" : "Nome do contato",
    //         "email" : "email@contato.com.br",
    //         "phone" : "61999999999",
    //         "subject" : "Assunto do email",
    //         "message"   : "Corpo do email"
    //     }
    // }
    sendEmailContact: async (data) => {
        try {
            const res = await api.post("/email/contact", data);
            return res;
        } catch (error) {
            return error;
        }
    },
}

export default PaymentService;