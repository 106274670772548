import api from "./api";

const TicketService = {
    BuscaOrigem: async () => {
        console.log('BuscaOrigem');
        try {
            const res = await api.get("/ticketService/buscaOrigem");
            return res;
        } catch (error) {
            return error;
        }
    },

    BuscaDestino: async (id) => {
        try {
            const res = await api.get("ticketService/buscaDestino/"+id);
            return res;
        } catch (error) {
            return error;
        }
    },

    // origin : id
    // destino : id
    // data : CARACTER "YYYY-MM-DD"
    BuscaCorrida: async (params) => {
        try {
            const res = await api.post("/ticketService/buscaCorrida/",params);
            return res;
        } catch (error) {
            return error;
        }
    },

    BuscaCategoriaCorrida: async (params) => {
        try {
            const res = await api.post("/ticketService/buscaCategoria/",params);
            return res;
        } catch (error) {
            return error;
        }
    },

    // origem
    // destino
    // data
    // servico
    // Fazer uma consulta para cada trecho/conexão
    // Retorna mapa do onibus e terminais de embarque e desembarque
    BuscaOnibus: async (params) => {
        try {
            const res = await api.post("/ticketService/buscaOnibus/",params);
            return res;
        } catch (error) {
            return error;
        }
    },

    // origem
    // destino
    // data
    // servico
    // poltrona
    // clienteId (OPCIONAL)
    BloquearPoltrona: async (params) => {
        try {
            const res = await api.post("/ticketService/bloquearPoltrona/",params);
            return res;
        } catch (error) {
            return error;
        }
    },

    // transacao
    DesbloquearPoltrona: async (params) => {
        try {
            const res = await api.post("/ticketService/debloquearPoltrona/",params);
            return res;
        } catch (error) {
            return error;
        }
    },

    // transacao
    // nomePassageiro
    // documentoPassageiro
    // tipoDocumento
    // seguro (OPCIONAL)
    // descontoPercentual (OPCIONAL)
    // descontoValor (OPCIONAL)
    // numAutorizacao
    // numParcelas (OPCIONAL)
    // localizador (OPCIONAL)
    // formaPagamento
    // nsu
    // hash
    // numeroFidelidade (OPCIONAL)
    // identificacaoCartaoReduzida true|false (OPCIONAL)
    // gerarQRCodeTaxaEmbarque true|false (OPCIONAL)
    // preencherTipoTaxaEmbarqueW2i true|false (OPCIONAL)
    ConfirmarVenda: async (params) => {
        try {
            const res = await api.post("/ticketService/confirmarVenda/",params);
            return res;
        } catch (error) {
            return error;
        }
    },

    // transacao
    // validarMulta true
    CancelarVenda: async (params) => {
        try {
            const res = await api.post("/ticketService/cancelarVenda/",params);
            return res;
        } catch (error) {
            return error;
        }
    },

    // data
    // servico
    BuscarItinerario: async (params) => {
        try {
            const res = await api.post("/ticketService/buscarItinerarioCorrida/",params);
            return res;
        } catch (error) {
            return error;
        }
    },

    // bilhete
    StatusBilhete: async (params) => {
        try {
            const res = await api.post("/ticketService/statusBilhete/",params);
            return res;
        } catch (error) {
            return error;
        }
    },

    // data
    // numeroSistema
    BuscaBilhete: async (params) => {
        try {
            const res = await api.post("/ticketService/buscaBilhete/",params);
            return res;
        } catch (error) {
            return error;
        }
    },
}

export default TicketService;
