import React, { useEffect, useState } from "react";
import "./index.scss";
import { Container, Badge } from "react-bootstrap";

import { useGlobalContext } from "../../context/GlobalContext";
import { toast } from "react-toastify";

import LogoEstrela from "../../assets/img/logoEstrela.svg";
import LogoRodeRotas from "../../assets/img/logoRodeRotas.svg";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { auth } from "../../utils/firebaseUtils";

export default function HeaderDefault() {
  const [openResponsive, setOpenresponsive] = useState(false);
  const history = useHistory();

  const globalContext = useGlobalContext();

  function goTo(href) {
    history.push(href);
  }

  function logout() {
    auth()
      .signOut()
      .then(() => {
        toast.success("Usuário deslogado!");
        globalContext.setUser();
      })
      .catch((error) => {
        toast.error("Erro ao sair!");
      });
  }

  return (
    <header className="header">
      <Container>
        <div className="containerHeader">
          <div className="logos" onClick={() => goTo("/")}>
            <img src={LogoEstrela} alt="Logo Estrela" />
            <img src={LogoRodeRotas} alt="Logo RodeRotas" />
          </div>
          <button
            onClick={() => setOpenresponsive(!openResponsive)}
            className="menu-responsive"
          ></button>
          <nav className={`nav ${openResponsive ? "open" : "closed"}`}>
            <ul>
              {globalContext.user ? (
                <>
                  <li>
                    <div className="profileUser">
                      <img src="" alt="" />
                      <Link to="/profile">Olá, {globalContext.user.name}
                        {/* {incomplete && <small><Badge pill className="bg-danger">Complete o cadastro</Badge></small>} */}
                      </Link>
                    </div>
                  </li>
                  <li>
                    <a onClick={() => goTo("/contact")}>Fale Conosco</a>
                  </li>
                  <li>
                    <button onClick={() => logout()}>Sair</button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a onClick={() => goTo("/contact")}>Fale Conosco</a>
                  </li>
                  <li>
                    <button onClick={() => goTo("/login")}>Entrar</button>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </Container>
    </header >
  );
}
