import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../context/GlobalContext";
import DB from "../../utils/firestore";
import InputMask from "react-input-mask";
import "./index.scss";

import Loading from "../../assets/img/loading.svg";
import UTILS from "../../utils/utils";
import { Container } from "react-bootstrap";

export default function MyAccount({ update }) {
  const globalContext = useGlobalContext();
  const [form, setForm] = useState({});
  var formData = {};

  useEffect(() => {
    if (globalContext.user) {
      formData = {
        name: globalContext.user.name || '',
        fone: globalContext.user.fone || '',
        cpf: globalContext.user.cpf || '',
        email: globalContext.user.email || '',
        addressLine1: globalContext.user.addressLine1 || '',
        cityName: globalContext.user.cityName || '',
        cnh: globalContext.user.cnh || '',
        cep: globalContext.user.cep || '',
        neighborhood: globalContext.user.neighborhood || '',
        number: globalContext.user.number || '',
        sexo: globalContext.user.sexo || '',
        dtNasc: globalContext.user.dtNasc || '',
        rg: globalContext.user.rg || '',
        stateAbbreviation: globalContext.user.stateAbbreviation || '',
        limit: globalContext.limit || '',
        updateV: 1,
      };
      setForm(formData);
    }
  }, [globalContext.user])

  // const formData = {
  //   name: globalContext.user.name || '',
  //   fone: globalContext.user.fone || '',
  //   cpf: globalContext.user.cpf || '',
  //   email: globalContext.user.email || '',
  //   addressLine1: globalContext.user.addressLine1 || '',
  //   cityName: globalContext.user.cityName || '',
  //   cnh: globalContext.user.cnh || '',
  //   cep: globalContext.user.cep || '',
  //   neighborhood: globalContext.user.neighborhood || '',
  //   number: globalContext.user.number || '',
  //   sexo: globalContext.user.sexo || '',
  //   dtNasc: globalContext.user.dtNasc || '',
  //   rg: globalContext.user.rg || '',
  //   stateAbbreviation: globalContext.user.stateAbbreviation || '',
  //   limit: globalContext.limit || '',
  // };

  const [loading, setloading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [cep, setCEP] = useState();

  useEffect(() => {
    if (cep !== undefined && cep.toString() !== "" && cep.toString().length === 10) {
      UTILS.getDataCEP(cep).then((rs) => {
        let dataCEP = rs.data;
        const temp = form;
        temp.addressLine1 = temp.addressLine1 !== "" ? temp.addressLine1 : dataCEP['logradouro'];
        temp.stateAbbreviation = temp.stateAbbreviation !== "" ? temp.stateAbbreviation : dataCEP['uf'];
        temp.cityName = temp.cityName !== "" ? temp.cityName : dataCEP['localidade'];
        temp.neighborhood = temp.neighborhood !== "" ? temp.neighborhood : dataCEP['bairro'];
        setForm(temp);
        setRefresh(!refresh);
      }).catch(err => {
        return;
      })
    }
  }, [cep])

  async function savePerfil(e) {
    e.preventDefault();
    setloading(true);

    if (form.name === "") {
      toast.error("Preencha seu nome;");
      setloading(false);
      return false;
    }

    if (form.dtNasc === "") {
      toast.error("Preencha sua data de nascimento;");
      setloading(false);
      return false;
    }

    if (form.sexo.trim() === "") {
      toast.error("Informe seu sexo biológico;");
      setloading(false);
      return false;
    }

    if (!UTILS.validateCPF(form.cpf.toString())) {
      toast.error("Preencha seu CPF corretamente;");
      setloading(false);
      return false;
    }

    if (!UTILS.validateMail(form.email.toString())) {
      toast.error("Preencha seu e-mail;");
      setloading(false);
      return false;
    }

    if (!UTILS.validatePhone(form.fone.toString())) {
      toast.error("Preencha seu telefone corretamente;");
      setloading(false);
      return false;
    }

    if (!UTILS.validateCEP(form.cep.toString())) {
      toast.error("Preencha seu CEP corretamente;");
      setloading(false);
      return false;
    }

    if (form.addressLine1.trim() === "") {
      toast.error("Preencha seu endereço;");
      setloading(false);
      return false;
    }

    if (form.stateAbbreviation === "") {
      toast.error("Informe seu estado;");
      setloading(false);
      return false;
    }

    if (form.cityName.trim() === "") {
      toast.error("Informe sua cidade;");
      setloading(false);
      return false;
    }

    DB.update("users", globalContext.user.id, form)
      .then((success) => {
        toast.success("Dados alterados com sucesso");
        setloading(false);
      })
      .catch((error) => {
        toast.danger("Erro ao salvar dados");
        setloading(false);
      });

  }

  return (
    <>
      <div className={update ? "container container-info update" : "container-info insert"}>
        <div className="box-title-myAccount">
          <h1 className="title-logged">{update ? "Atualize seus dados" : "Minha Conta"}</h1>
        </div>
        <form className="formPerfil" onSubmit={savePerfil}>
          <div className="formdiv">

            <div className="form-group col-md-5">
              <p className="textForm">Nome</p>
              <input
                type="text"
                className="form-control myAcountInput"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
            </div>

            <div className="form-group col-md-3">
              <p className="text">Sexo biológico</p>
              <select
                className="form-control"
                value={form.sexo}
                defaultValue={form.sexo}
                disabled={loading}
                onChange={(e) => {
                  const temp = form;
                  temp.sexo = e.target.value;
                  setForm(temp);
                  setRefresh(!refresh);
                }}
              >
                <option value="" selected></option>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </select>
            </div>

            <div className="form-group col-md-4">
              <p className="text">Data de Nascimento</p>
              <InputMask
                type="text"
                className="form-control"
                value={form.dtNasc}
                mask="99/99/9999"
                onChange={(e) => {
                  const temp = form;
                  temp.dtNasc = e.target.value;
                  setForm(temp);
                  setRefresh(!refresh);
                }}
              />
            </div>

            <div className="form-group col-md-5">
              <p className="textForm">Email</p>
              <input
                type="text"
                className="form-control myAcountInput"
                value={form.email}
                disabled
              />
            </div>
          </div>

          <div className="form-group col-md-4">
            <p className="textForm">CPF</p>
            <InputMask
              type="text"
              className="form-control myAcountInput"
              value={form.cpf}
              mask={"999.999.999-99"}
              onChange={(e) => setForm({ ...form, cpf: e.target.value })}
            />
          </div>

          <div className="formdiv space-top">
            <div className="form-group col-md-3">
              <p className="textForm">Telefone</p>
              <InputMask
                type="tel"
                className="form-control myAcountInput"
                value={form.fone}
                mask={"(99) 99999-9999"}
                onChange={(e) => setForm({ ...form, fone: e.target.value })}
              />
            </div>
          </div>

          {/* <div className="formdiv space-top">
          <div className="form-group col-md-2">
            <p className="textForm">Senha</p>
            <p className="textForm">******</p>
          </div>

          <div className="form-group col-md-4">
            <p className="textForm">---------------------</p>
            <p className="textForm">Alterar Senha</p>
          </div>
        </div> */}

          {/* <h2 className="title col-md-12">Endereço</h2> */}
          <div>
            <div className="form-box-payment">
              <div className="form-wrap">
                <div className="form-group col-md-6">
                  <p className="text">RG</p>
                  <InputMask
                    type="text"
                    className="form-control"
                    value={form.rg}
                    disabled={loading}
                    onChange={(e) => {
                      const temp = form;
                      temp.rg = e.target.value;
                      setForm(temp);
                      setRefresh(!refresh);
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <p className="text">CNH</p>
                  <InputMask
                    type="text"
                    className="form-control"
                    value={form.cnh}
                    disabled={loading}
                    onChange={(e) => {
                      const temp = form;
                      temp.cnh = e.target.value;
                      setForm(temp);
                      setRefresh(!refresh);
                    }}
                  />
                </div>

                <div className="form-group col-md-6">
                  <p className="text">CEP</p>
                  <InputMask
                    type="text"
                    className="form-control"
                    value={form.cep}
                    mask="99.999-999"
                    disabled={loading}
                    onChange={(e) => {
                      const temp = form;
                      form.cep = e.target.value;
                      setCEP(form.cep);
                      setForm(temp);
                      setRefresh(!refresh);
                    }}
                  />
                </div>

                <div className="form-group col-md-6">
                  <p className="text">Cidade</p>
                  <input
                    type="text"
                    className="form-control"
                    value={form.cityName}
                    disabled={loading}
                    onChange={(e) => {
                      const temp = form;
                      temp.cityName = e.target.value;
                      setForm(temp);
                      setRefresh(!refresh);
                    }}
                  />
                </div>

                <div className="form-group col-md-12">
                  <p className="text">Endereço</p>
                  <input
                    type="text"
                    className="form-control"
                    value={form.addressLine1}
                    disabled={loading}
                    onChange={(e) => {
                      const temp = form;
                      temp.addressLine1 = e.target.value;
                      setForm(temp);
                      setRefresh(!refresh);
                    }}
                  />
                </div>

                <div className="form-group col-md-2">
                  <p className="text">Nº</p>
                  <input
                    type="text"
                    className="form-control"
                    value={form.number}
                    disabled={loading}
                    onChange={(e) => {
                      const temp = form;
                      temp.number = e.target.value;
                      setForm(temp);
                      setRefresh(!refresh);
                    }}
                  />
                </div>

                <div className="form-group col-md-2">
                  <p className="text">UF</p>
                  <select
                    className="form-control"
                    value={form.stateAbbreviation}
                    disabled={loading}
                    onChange={(e) => {
                      const temp = form;
                      temp.stateAbbreviation = e.target.value;
                      setForm(temp);
                      setRefresh(!refresh);
                    }}
                  >
                    <option value="" selected></option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>
                </div>

                <div className="form-group col-md-8">
                  <p className="text">Bairro</p>
                  <input
                    type="text"
                    className="form-control"
                    value={form.neighborhood}
                    disabled={loading}
                    onChange={(e) => {
                      const temp = form;
                      temp.neighborhood = e.target.value;
                      setForm(temp);
                      setRefresh(!refresh);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="formdiv">
            <div className="btnForm">
              <button type="submit" className="btnFormSave">
                {loading ? (
                  <img
                    src={Loading}
                    className="loadingButton"
                    alt="loadingButton"
                  />
                ) : (
                  <span>Salvar</span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
