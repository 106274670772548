import React, { useEffect } from "react";
import Footer from "../../components/footer/footer";
import "./index.scss";

export default function Horarios() {
    return (<>
        <div class="horarios">
            <h1>HORÁRIOS SEMIURBANO</h1>
            <p>(Sujeito a alterações de horários aos feriados)</p>
            <div class="tabela">
                <div class="left">
                <table>
                    <thead>
                        <tr>
                            <th colspan="8">ARAGUARI X UBERLÂNDIA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td id="week">DOM</td>
                            <td id="week">SEG</td>
                            <td id="week">TER</td>
                            <td id="week">QUA</td>
                            <td id="week">QUI</td>
                            <td id="week">SEX</td>
                            <td id="week">SAB</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>04:30</td>
                            <td>04:30</td>
                            <td>04:30</td>
                            <td>04:30</td>
                            <td>04:30</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>05:00</td>
                            <td>05:00</td>
                            <td>05:00</td>
                            <td>05:00</td>
                            <td>05:00</td>
                            <td>05:00</td>
                        </tr>
                        <tr>
                            <td>06:00</td>
                            <td>06:00</td>
                            <td>06:00</td>
                            <td>06:00</td>
                            <td>06:00</td>
                            <td>06:00</td>
                            <td>06:00</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>06:15</td>
                            <td>06:15</td>
                            <td>06:15</td>
                            <td>06:15</td>
                            <td>06:15</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>06:30</td>
                            <td>06:30</td>
                            <td>06:30</td>
                            <td>06:30</td>
                            <td>06:30</td>
                            <td>06:30</td>
                        </tr>
                        <tr>
                            <td>07:00</td>
                            <td>07:00</td>
                            <td>07:00</td>
                            <td>07:00</td>
                            <td>07:00</td>
                            <td>07:00</td>
                            <td>07:00</td>
                        </tr>
                        <tr>
                            <td>07:45</td>
                            <td>07:45</td>
                            <td>07:45</td>
                            <td>07:45</td>
                            <td>07:45</td>
                            <td>07:45</td>
                            <td>07:45</td>
                        </tr>
                        <tr>
                            <td>08:00</td>
                            <td>08:00</td>
                            <td>08:00</td>
                            <td>08:00</td>
                            <td>08:00</td>
                            <td>08:00</td>
                            <td>08:00</td>
                        </tr>
                        <tr>
                            <td>09:00</td>
                            <td>09:00</td>
                            <td>09:00</td>
                            <td>09:00</td>
                            <td>09:00</td>
                            <td>09:00</td>
                            <td>09:00</td>
                        </tr>
                        <tr>
                            <td>10:00</td>
                            <td>10:00</td>
                            <td>10:00</td>
                            <td>10:00</td>
                            <td>10:00</td>
                            <td>10:00</td>
                            <td>10:00</td>
                        </tr>
                        <tr>
                            <td>11:00</td>
                            <td>11:00</td>
                            <td>11:00</td>
                            <td>11:00</td>
                            <td>11:00</td>
                            <td>11:00</td>
                            <td>11:00</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>11:30</td>
                            <td>11:30</td>
                            <td>11:30</td>
                            <td>11:30</td>
                            <td>11:30</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>12:00</td>
                            <td>12:00</td>
                            <td>12:00</td>
                            <td>12:00</td>
                            <td>12:00</td>
                            <td>12:00</td>
                            <td>12:00</td>
                        </tr>
                        <tr>
                            <td>13:00</td>
                            <td>13:00</td>
                            <td>13:00</td>
                            <td>13:00</td>
                            <td>13:00</td>
                            <td>13:00</td>
                            <td>13:00</td>
                        </tr>
                        <tr>
                            <td>14:00</td>
                            <td>14:00</td>
                            <td>14:00</td>
                            <td>14:00</td>
                            <td>14:00</td>
                            <td>14:00</td>
                            <td>14:00</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>14:30</td>
                            <td>14:30</td>
                            <td>14:30</td>
                            <td>14:30</td>
                            <td>14:30</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>15:00</td>
                            <td>15:00</td>
                            <td>15:00</td>
                            <td>15:00</td>
                            <td>15:00</td>
                            <td>15:00</td>
                            <td>15:00</td>
                        </tr>
                        <tr>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>16:00</td>
                            <td>16:00</td>
                            <td>16:00</td>
                            <td>16:00</td>
                            <td>16:00</td>
                            <td>16:00</td>
                            <td>16:00</td>
                        </tr>
                        <tr>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td>17:00</td>
                        </tr>
                        <tr>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                        </tr>
                        <tr>
                            <td>18:15</td>
                            <td>18:15</td>
                            <td>18:15</td>
                            <td>18:15</td>
                            <td>18:15</td>
                            <td>18:15</td>
                            <td>18:15</td>
                        </tr>
                        <tr>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                        </tr>
                        <tr>
                            <td>20:00</td>
                            <td>20:00</td>
                            <td>20:00</td>
                            <td>20:00</td>
                            <td>20:00</td>
                            <td>20:00</td>
                            <td>20:00</td>
                        </tr>
                        <tr>
                            <td>21:00</td>
                            <td>21:00</td>
                            <td>21:00</td>
                            <td>21:00</td>
                            <td>21:00</td>
                            <td>21:00</td>
                            <td>21:00</td>
                        </tr>
                    </tbody>
                </table>
                <p>VIGÊNCIA 21/03/2022</p>
                </div>

                <div class="right">
                <table>
                    <thead>
                        <tr>
                            <th colspan="8">UBERLÂNDIA X ARAGUARI</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td id="week">DOM</td>
                            <td id="week">SEG</td>
                            <td id="week">TER</td>
                            <td id="week">QUA</td>
                            <td id="week">QUI</td>
                            <td id="week">SEX</td>
                            <td id="week">SAB</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>06:30</td>
                            <td>06:30</td>
                            <td>06:30</td>
                            <td>06:30</td>
                            <td>06:30</td>
                            <td>06:30</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>07:00</td>
                            <td>07:00</td>
                            <td>07:00</td>
                            <td>07:00</td>
                            <td>07:00</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>07:30</td>
                            <td>07:30</td>
                            <td>07:30</td>
                            <td>07:30</td>
                            <td>07:30</td>
                            <td>07:30</td>
                            <td>07:30</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>08:00</td>
                            <td>08:00</td>
                            <td>08:00</td>
                            <td>08:00</td>
                            <td>08:00</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>08:30</td>
                            <td>08:30</td>
                            <td>08:30</td>
                            <td>08:30</td>
                            <td>08:30</td>
                            <td>08:30</td>
                            <td>08:30</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>09:00</td>
                            <td>09:00</td>
                            <td>09:00</td>
                            <td>09:00</td>
                            <td>09:00</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>09:30</td>
                            <td>09:30</td>
                            <td>09:30</td>
                            <td>09:30</td>
                            <td>09:30</td>
                            <td>09:30</td>
                            <td>09:30</td>
                        </tr>
                        <tr>
                            <td>10:30</td>
                            <td>10:30</td>
                            <td>10:30</td>
                            <td>10:30</td>
                            <td>10:30</td>
                            <td>10:30</td>
                            <td>10:30</td>
                        </tr>
                        <tr>
                            <td>11:30</td>
                            <td>11:30</td>
                            <td>11:30</td>
                            <td>11:30</td>
                            <td>11:30</td>
                            <td>11:30</td>
                            <td>11:30</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>12:00</td>
                            <td>12:00</td>
                            <td>12:00</td>
                            <td>12:00</td>
                            <td>12:00</td>
                            <td>12:00</td>
                        </tr>
                        <tr>
                            <td>12:30</td>
                            <td>12:30</td>
                            <td>12:30</td>
                            <td>12:30</td>
                            <td>12:30</td>
                            <td>12:30</td>
                            <td>12:30</td>
                        </tr>
                        <tr>
                            <td>13:30</td>
                            <td>13:30</td>
                            <td>13:30</td>
                            <td>13:30</td>
                            <td>13:30</td>
                            <td>13:30</td>
                            <td>13:30</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>14:00</td>
                            <td>14:00</td>
                            <td>14:00</td>
                            <td>14:00</td>
                            <td>14:00</td>
                            <td>14:00</td>
                        </tr>
                        <tr>
                            <td>14:30</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>15:00</td>
                            <td>15:00</td>
                            <td>15:00</td>
                            <td>15:00</td>
                            <td>15:00</td>
                            <td>15:00</td>
                        </tr>
                        <tr>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td>15:30</td>
                            <td>15:30</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>16:00</td>
                            <td>16:00</td>
                            <td>16:00</td>
                            <td>16:00</td>
                            <td>16:00</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td>16:30</td>
                            <td>16:30</td>
                        </tr>
                        <tr>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td>17:00</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                            <td>17:30</td>
                        </tr>
                        <tr>
                            <td>18:00</td>
                            <td>18:00</td>
                            <td>18:00</td>
                            <td>18:00</td>
                            <td>18:00</td>
                            <td>18:00</td>
                            <td>18:00</td>
                        </tr>
                        <tr>
                            <td>18:30</td>
                            <td>18:30</td>
                            <td>18:30</td>
                            <td>18:30</td>
                            <td>18:30</td>
                            <td>18:30</td>
                            <td>18:30</td>
                        </tr>
                        <tr>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                            <td>19:00</td>
                        </tr>
                        <tr>
                            <td>19:30</td>
                            <td>19:30</td>
                            <td>19:30</td>
                            <td>19:30</td>
                            <td>19:30</td>
                            <td>19:30</td>
                            <td>19:30</td>
                        </tr>
                        <tr>
                            <td>20:30</td>
                            <td>20:30</td>
                            <td>20:30</td>
                            <td>20:30</td>
                            <td>20:30</td>
                            <td>20:30</td>
                            <td>20:30</td>
                        </tr>
                        <tr>
                            <td>21:30</td>
                            <td>21:30</td>
                            <td>21:30</td>
                            <td>21:30</td>
                            <td>21:30</td>
                            <td>21:30</td>
                            <td>21:30</td>
                        </tr>
                        <tr>
                            <td>22:30</td>
                            <td>22:30</td>
                            <td>22:30</td>
                            <td>22:30</td>
                            <td>22:30</td>
                            <td>22:30</td>
                            <td>22:30</td>
                        </tr>
                    </tbody> 
                </table>
                <p>VIGÊNCIA 21/03/2022</p>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}