import React, { useEffect, useState } from "react";
import "./index_mobile.scss";
import { Container } from "react-bootstrap";
import Footer from "../../components/footer/footer";
import { useParams } from "react-router";
import TicketService from "../../services/ticket";
import CheckoutPage from "../../pages/checkout";
import Moment from "moment";
import OtherResults from "./results/otherResults";

import Filters from "./filters";
import Loading from "../../assets/img/loading.svg";
import { useGlobalContext } from "../../context/GlobalContext";
import LoginPage from "../login";
import Resume from "../resume";
import { toast } from "react-toastify";
import ResultsMobile from "./results/index_mobile";

export default function PassagensPageMobile() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorService, setErrorService] = useState(false);
    const [steps, setSteps] = useState("ida");
    const [purchases, setPurchases] = useState("");
    const [showFiltros, setshowFiltros] = useState(false);

    const { origem, destino, type, date } = useParams();
    const globalContext = useGlobalContext();

    const [data, setData] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    const [transaction, setTransaction] = useState({ viagem: [] });

    useEffect(async () => {
        const stepStorage = await localStorage.getItem("step");
        if (stepStorage) {
            const transactionStorage = await localStorage.getItem("transaction");
            //debugger;
            setTransaction(JSON.parse(transactionStorage));
            setSteps(stepStorage);
        } else {
            getData();
            await globalContext.setStopCount(!globalContext.stopCount);
        }
    }, []);

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, [date, steps]);

    function onLogin() {
        window.location.reload(true);
    }

    async function getData() {
        // debugger;
        if (steps !== "login") {
            setLoading(true);
            const idOrigem = origem.split("-")[1];
            const idDestino = destino.split("-")[1];
            const dateService =
                steps === "ida" || steps === "pagamento"
                    ? Moment(date.split("-")[0], "DDMMYYYY")
                    : Moment(date.split("-")[1], "DDMMYYYY");
            const params = {
                origem: steps === "ida" ? JSON.parse(idOrigem) : JSON.parse(idDestino),
                destino: steps !== "ida" ? JSON.parse(idOrigem) : JSON.parse(idDestino),
                data: dateService.format("YYYY-MM-DD"),
            };
            const result = await TicketService.BuscaCorrida(params);
            if (result.status === 200) {
                setErrorService(false);
                setData(result.data);
                setDataFilter(result.data.lsServicos);
                if (steps === "ida" && !transaction.viagem.length) {
                    const temp = transaction;
                    //debugger;
                    temp.viagem[0] = result.data;
                    const transactionStorage = await localStorage.getItem("transaction");
                    if (!transactionStorage) {
                        setTransaction(temp);
                    }
                }
            } else {
                setError(true);
            }
        }
        setLoading(false);
    }

    async function changeStep(values, bus, login) {
        // debugger;
        var temp = transaction;
        if (steps === "ida") {
            const startTime = Moment().format("DD/MM/YYYY HH:mm:ss");
            await localStorage.setItem("sessionPayment", JSON.stringify(startTime));
            globalContext.setsessionPayment(true);
            //debugger;
            temp.assentos = [values];
            if (bus) temp.viagem[0].bus = bus;
            if (type === "ida") {
                setSteps("pagamento");
                localStorage.setItem("step", "pagamento");
            } else {
                setSteps("volta");
                localStorage.setItem("step", "volta");
            }
        }
        if (steps === "volta") {
            //debugger;
            temp.viagem[1] = data;
            if (bus) temp.viagem[1].bus = bus;
            temp.assentos[1] = values;
            if (transaction.assentos.length == 1) {
                setSteps("pagamento");
                localStorage.setItem("step", "pagamento");
            } else if (transaction.assentos.length > 1 && transaction.assentos[0].length == transaction.assentos[1].length) {
                setSteps("pagamento");
                localStorage.setItem("step", "pagamento");
            } else {
                // debugger;
                let total = transaction.assentos[1].length - transaction.assentos[0].length;
                toast.error(
                    `Você deve ${total > 0 ? "retirar" : "selecionar mais"} ${Math.abs(total)} assento(s) na ${total > 0 ? "volta" : "ida"}`
                );
                setLoading(false);
            }
        }

        //debugger;
        localStorage.setItem("transaction", JSON.stringify(temp));
        setTransaction(temp);
    }

    function canBlock(sendSelecteds) {
        var _temp = { ...transaction };
        if (sendSelecteds === undefined) {
            return false;
        }

        if (steps === "volta") {
            _temp.assentos[1] = sendSelecteds;

            if (_temp.assentos.length == 1) {
                return true;
            } else if (_temp.assentos.length > 1 && _temp.assentos[0].length == _temp.assentos[1].length) {
                return true;
            } else {
                let total = _temp.assentos[0].length - _temp.assentos[1].length;
                if (_temp.assentos[0].length > _temp.assentos[1].length) {
                    toast.error(
                        `Você deve selecionar mais ${Math.abs(total)} assento(s) da volta`
                    );
                    setLoading(false);
                } else if (_temp.assentos[0].length < _temp.assentos[1].length) {
                    toast.error(
                        `Você deve retirar ${Math.abs(total)} assento(s) da volta`
                    );
                    setLoading(false);
                } else {
                    return false;
                }
            }
        } else {
            return true;
        }

    }

    useEffect(() => {
        if (purchases !== "") {
            setSteps("confirmacao");
            setLoading("false");
        }
    }, [purchases]);

    function renderPagamento() {
        return globalContext.user ? (
            transaction.assentos && transaction.viagem && (
                <CheckoutPage
                    transaction={transaction}
                    setPurchases={(val) => setPurchases(val)}
                />
            )
        ) : (
            <>
                <LoginPage noRedirect={true} />
            </>
        );
    }

    return (
        <>
            {globalContext.ShowModalSession && (
                <div className="modal-session">
                    <div className="content">
                        <p>Sessão expirada!</p>
                        <a
                            href="javascript:document.location.reload(true);"
                            className="btn"
                        >
                            Recarregar
                        </a>
                    </div>
                </div>
            )}
            {loading ? (
                <div className="loading loadingHeight">
                    <img src={Loading} className="loadingMask" alt="" />
                </div>
            ) : (
                error ? <p>Erro ao buscar corridas!</p> :
                    <div className="passagem_mobile">
                        <div class="page">
                            <div class="info">
                                <div class="txt__info">
                                    <span>Passagem de ônibus de {steps ? steps : "ida"}</span>
                                    <p><strong>{data.origem.cidade}</strong> para <strong>{data.destino.cidade}</strong></p>
                                </div>
                            </div>

                            {(steps === "ida" || steps === "volta") && (<>
                                <div className="search-results">
                                    <Filters
                                        data={data}
                                        dataFilter={dataFilter}
                                        refreshResult={(valor) => setDataFilter(valor)}
                                        open={!showFiltros}
                                    />
                                    <ResultsMobile
                                        steps={steps}
                                        changeStep={(val, bus, login) => changeStep(val, bus, login)}
                                        canBlock={(sendSelecteds) => canBlock(sendSelecteds)}
                                        dataFilter={dataFilter}
                                        data={data}
                                        setOpenFilter={() => setshowFiltros(!showFiltros)}
                                    />
                                </div>
                                <div class="sction_dtls">
                                    <strong>* Horário de Brasília</strong>
                                </div>
                            </>
                            )}
                            {steps === "pagamento" && renderPagamento()}
                            {steps === "confirmacao" && <Resume id={purchases} />}
                        </div>
                    </div>
            )}
            <Footer hideFooterWhite={false} />
        </>
    );
}