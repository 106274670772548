import React, { useEffect, useState } from "react";
import { MDBMask, MDBView, MDBContainer } from "mdbreact";
import "mdbreact/dist/css/mdb.css";
import "./index.scss";
import DB from "../../utils/firestore";
import UTILS from "../../utils/utils";
import moment from "moment";

export default function Destinos() {
  const [listPromo, setListPromo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listPromoPhotos, setListPromoPhotos] = useState([]);
  const dateToday = moment(new Date()).format("DDMMYYYY");
  const dateTomorrow = moment(new Date()).add(1, 'days').format("DDMMYYYY");

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const response = await DB.get("offers");
    let promo = [];
    await response.map(e => {
      const img = 'https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/offer_images%2F' + e.id + '.jpeg?alt=media';
      e.url_img = img;
      promo.push(e);
    });
    setListPromo(promo);
    setLoading(false);
  }

  function ajustarLink(url) {
    const domain = UTILS.formataURL(url);

    return `${url.replace(domain, "")}`;
  }

  return (
    <section className="destinos">
      <MDBContainer>
        <h2>Seu sonho de viagem está aqui:</h2>
        <div className="box-destinos">
          {!loading && listPromo && listPromo.length > 5 && listPromo.map((e, index) =>
            <div className="item">
              <MDBView hover style={{ background: "url('" + e.url_img + "')" }}>
                <img src={e.url_img} alt="" />
                <h2>{e.from_name.split(" - ")[0]} - {e.to_name.split(" - ")[0]}</h2>
                <h3>
                  a partir de <span>{e.price}</span>
                </h3>
                <MDBMask className="flex-center" overlay="yellow-light">
                  <a className="btn btn-outline-info" href={ajustarLink(e.url) + dateTomorrow}>
                    <p className="white-text">Ver Rota</p>
                  </a>
                </MDBMask>
              </MDBView>
            </div>
          )}
        </div>
      </MDBContainer>
    </section>
  )
}
