import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../components/footer/footer";
import "./index.scss";
import { useGlobalContext } from "../../context/GlobalContext";

export default function PrivacyPolicy() {
  const globalContext = useGlobalContext();
  return (
    <>
      <Container className="containerText">
        <div className="title">
          <h1>QUEM SOMOS</h1>
        </div>
        <div className="text">
          {globalContext.configs && globalContext.configs[0] && 
            globalContext.configs[0].who_we
          }
        </div>
      </Container>
      <Footer />
    </>
  );
}
