import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { auth, Firestore } from "./utils/firebaseUtils";

import Routes from "./routes/routes";
import history from "./routes/history";
import { ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "./utils/moment-location";
import "./App.scss";


import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import { useGlobalContext } from "./context/GlobalContext";

import MaskLoading from "./components/maskLoading/maskLoading";
import UTILS from "./utils/utils";
import StatesConvert from "./components/mapaRotas/stateUf";

function App() {
  const [initializing, setInitializing] = useState(true);
  const globalContext = useGlobalContext();

  useEffect(() => {
    getConfigs();
    const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, []);

  async function onAuthStateChanged(user) {
    if (user && user.uid) {
      try {
        Firestore.collection("users")
          .doc(user.uid)
          .onSnapshot(async (snap) => {
            if (snap.exists) {
              const userAuth = await snap.data();
              globalContext.setUser({
                ...userAuth,
                id: snap.id,
                autentication: user
              });
            }
            setInitializing(false);
          });
        // auth().currentUser.onSnapshot((snap) => {
        //   debugger;
        //   globalContext.setUser({
        //     ...globalContext.user,
        //     autentication: snap,
        //   });
        // });
      } catch (error) {
        debugger;
      }
    } else if (initializing) {
      setInitializing(false);
    }
    setInitializing(false);
  }

  if (initializing) {
    return <MaskLoading />;
  }

  function getConfigs() {
    Firestore.collection("config").onSnapshot(async (snap) => {
      let data = snap.docs.map((doc) => {
        const resp = {
          id: doc.id,
          ...doc.data(),
        };
        return resp;
      });
      globalContext.setConfigs({ ...data, id: snap.id });
      setInitializing(false);
    });
  }

  return (
    <Router history={history}>
      <Routes />
      <ToastContainer />
    </Router>
  );
}

export default App;
