import React, { useEffect } from "react";
import "./index.scss";
import { Container } from "react-bootstrap";

import BannerBusca from "../../components/bannerBusca";
import Destinos from "../../components/destinos";

import KmRodados from "../../components/kmRodados";
import MapaRotas from "../../components/mapaRotas";
import Footer from "../../components/footer/footer";
import { useGlobalContext } from "../../context/GlobalContext";
import UTILS from "../../utils/utils";
import StatesConvert from "../../components/mapaRotas/stateUf";

export default function HomePage() {
  const globalContext = useGlobalContext();

  function createMarkup(data) {
    return { __html: data };
  };

  return (
    <>
      <BannerBusca />
      <Container>
        <section className="chamadaHome">
          {globalContext.configs && globalContext.configs[0] && globalContext.configs[0].icon_legend_1 && (
            <div className="item">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/banner_url_1.jpeg?alt=media"
                alt=""
              />
              <span dangerouslySetInnerHTML={createMarkup(globalContext.configs[0].icon_legend_1)} />
            </div>
          )}
          {globalContext.configs && globalContext.configs[0] && globalContext.configs[0].icon_legend_2 && (
            <div className="item">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/banner_url_2.jpeg?alt=media"
                alt=""
              />
              <span dangerouslySetInnerHTML={createMarkup(globalContext.configs[0].icon_legend_2)} />
            </div>
          )}
          {globalContext.configs && globalContext.configs[0] && globalContext.configs[0].icon_legend_3 && (
            <div className="item">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/banner_url_3.jpeg?alt=media"
                alt=""
              />
              <span dangerouslySetInnerHTML={createMarkup(globalContext.configs[0].icon_legend_3)} />
            </div>
          )}
        </section>
      </Container>
      <Destinos />
      <KmRodados />
      <MapaRotas />
      {globalContext.configs && globalContext.configs[0] && globalContext.configs[0].link_banner_footer && (
        <a href={globalContext.configs[0].link_banner_footer} target={globalContext.configs[0].target_banner_footer} className="bannerHome">
          <img className="desktop" src="https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_url.jpeg?alt=media" alt="bannerBase" />
          <img className="mobile" src="https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_url_mobile.jpeg?alt=media" alt="bannerBase" />
        </a>
      )}
      {/* <section className="bgHome" style={{backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_url.jpeg?alt=media)'}}></section> */}
      <Footer hideFooterWhite={false} />
    </>
  );
}
