import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import Footer from "../../components/footer/footer";
import "./index.scss";
import { useGlobalContext } from "../../context/GlobalContext";
import PaymentService from "../../services/payment";
import Loading from "../../assets/img/loading.svg";
import UTILS from "../../utils/utils";


export default function Contact(props) {
  const globalContext = useGlobalContext();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [fone, setFone] = useState("");
  const [maskFone, setMaskFone] = useState("(99) 99999-9999")
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setloading] = useState(false);

  const formData = {
    name: globalContext.user ? globalContext.user.name : name,
    fone: globalContext.user ? globalContext.user.fone : fone,
    email: globalContext.user ? globalContext.user.email : email,
    subject: subject,
    message: message
  };

  function sendMail(e) {
    e.preventDefault();
    setloading(true);

    if (formData.name == "") {
      toast.error("Preencha seu nome corretamente;");
      setloading(false);
      return false;
    }

    if (!UTILS.validateMail(formData.email) || formData.email == "") {
      toast.error("Preencha seu e-mail corretamente;");
      setloading(false);
      return false;
    }

    if (!UTILS.validatePhone(formData.fone) && formData.fone != "") {
      toast.error("Preencha seu telefone corretamente;");
      setloading(false);
      return false;
    }

    PaymentService.sendEmailContact({
      "contact": { ...formData, fone: formData.fone.replace(/[^\d]+/g, '') }
    }).then((success) => {
      toast.success("Contato enviado com sucesso");
      setloading(false);
    })
      .catch((error) => {
        toast.danger("Erro ao Enviar");
        setloading(false);
      });
  }

  useEffect(() => {
    if (fone.replace(/[^\d]+/g, '').length == 10) {
      setMaskFone("(99) 9999-99999");
    } else {
      setMaskFone("(99) 99999-9999");
    }
  }, [fone]);

  return (
    <>
      <Container>
        <div className="formContact">
          <h2>Fale conosco</h2>
          <form>
            <div className="box-form">
              <label htmlFor="email">Nome</label>
              <input
                value={formData.name}
                onChange={(event) => setName(event.target.value)}
                placeholder="Informe seu Nome"
                type="text"
              />
            </div>
            <div className="box-form">
              <label htmlFor="email">E-mail</label>
              <input
                value={formData.email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Informe seu e-mail"
                type="text"
              />
            </div>
            <div className="box-form">
              <label htmlFor="fone">Telefone</label>
              {/* <input
                value={formData.fone}
                onChange={(event) => setFone(event.target.value)}
                placeholder="Informe seu telefone"
                type="text"
              /> */}
              <InputMask
                value={formData.fone}
                onChange={(event) => setFone(event.target.value)}
                type="tel"
                mask={maskFone}
                pattern="^(\\([0-9]{2}\\)|([0-9]{2}))(( [0-9]{4}-[0-9]{4})|(9 [0-9]{4}-[0-9]{4})|( 9[0-9]{4}-[0-9]{4})|( 9 [0-9]{4}-[0-9]{4})|(9[0-9]{4}-[0-9]{4})|(9[0-9]{4}[0-9]{4}))$"
                placeholder="Informe seu telefone"
              />
            </div>
            <div className="box-form">
              <label htmlFor="text">Assunto</label>
              <input
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                placeholder="Informe o assunto"
                type="text"
              />
            </div>
            <div className="box-form">
              <label htmlFor="email">Mensagem</label>
              <textarea
                value={message}
                rows="5"
                onChange={(event) => setMessage(event.target.value)}
                placeholder="Sua mensagem"
                type="text"
              />
            </div>
            <div className="btnForm">
              <button type="submit" onClick={sendMail} >
                {loading ? (
                  <img
                    src={Loading}
                    className="loadingButton"
                    alt="loadingButton"
                  />
                ) : (
                  <span>Enviar</span>
                )}
              </button>
            </div>
          </form>
        </div>
      </Container>
      {!props.hideFooter && (
        <Footer />
      )}
    </>
  );
}
