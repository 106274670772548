import React from "react";
import { Container } from "react-bootstrap";
import "./index.scss";

export default function Register() {
  return (
    <Container>
      register
    </Container>
  );
}
