import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Footer from "../../components/footer/footer";
import "./index.scss";
import { useGlobalContext } from "../../context/GlobalContext";

export default function Faq() {
    const globalContext = useGlobalContext();
    return (
        <>
            <Container className="containerText">
                <div className="title">
                    <h1>PERGUNTAS FREQUENTES</h1>
                </div>
                <div className="text">
                    <p><b>Onde posso comprar a minha passagem?</b></p>
                    <p>As passagens podem ser adquiridas por meio do site www.roderotas.com, do aplicativo Rode Rotas, disponível para Android e IOS, em sites parceiros, nos guichês em rodoviárias ou por agências terceirizadas, cuja lista pode ser conferida na página AGÊNCIAS.
                        Nas compras feitas pelos sites, há cobrança de uma taxa de conveniência sobre o valor da passagem. Já nas compras realizadas pelo aplicativo, o cliente está isento de cobrança.</p>
                    <p><b>Quais as formas de pagamento aceitas?</b></p>
                    <p>A RODEROTAS.COM aceita pagamentos em dinheiro, cartões de débito e de crédito das bandeiras Visa, MasterCard, Amex e Elo. As compras podem ser dividas em até seis vezes, a depender do valor da passagem.</p>
                    <p><b>Quais são os documentos necessários para aquisição do bilhete de viagem?</b></p>
                    <p>Para comprar as passagens, os brasileiros devem apresentar documento de identificação com foto, válido em território nacional. Entre esses estão o RG, a Carteira Nacional de Habilitação (CNH), a Carteira de Trabalho e Previdência Social (CTPS), o passaporte ou carteira de identidade profissional.
                        Os estrangeiros devem apresentar o passaporte, a Cédula de Identidade do Estrangeiro (CIE) ou seu protocolo de pedido expedido pela Polícia Federal com validade de 180 dias, documento oficial do país de origem ou identidade diplomática e consular.
                        É importante lembrar que, no dia da viagem, é necessário portar o documento original com foto utilizado durante a compra. Os documentos são exigidos tanto para retirada do bilhete quanto para embarque.</p>
                    <p><b>Quem possui direito a passagens de ônibus gratuitas?</b></p>
                    <p>Viajar gratuitamente é permitido aos passageiros e situações descritas abaixo. É importante lembrar que as aquisições de passagens com gratuidade não podem ser emitidas pelo portal de vendas ou pelo aplicativo; esses bilhetes devem ser adquiridos pessoalmente nos guichês da empresa, mediante à apresentação dos documentos exigidos em cada um dos casos.</p>
                    <h3>Idosos</h3>
                    <p>O Estatuto do Idoso e a ANTT determinam que as viações devem reservar aos idosos, com idade mínima de 60 anos e renda igual ou inferior a dois salários mínimos, dois assentos gratuitos no serviço convencional. Caso esses assentos já estejam ocupados, o passageiro idoso que atender a esses requisitos terá direito ao desconto mínimo de 50% do valor da passagem.</p>
                    <p>A aquisição da passagem e o embarque poderão ser feitos apenas em rodoviárias ou pontos de embarque oficiais. Para utilização do benefício, é preciso apresentar documento de identidade original com foto e comprovante de renda, que pode ser a Carteira de Trabalho e Previdência Social com anotações atualizadas; contracheque de pagamento ou documento expedido pelo empregador; carnê contribuição para o Instituto Nacional de Seguro Social – INSS; extrato de pagamento de benefício ou declaração fornecida pelo INSS ou outro regime de previdência social público ou privado; ou documento ou carteira emitida pelas Secretarias Estaduais ou Municipais de Assistência Social ou congêneres.</p>
                    <h3>ID Jovem</h3>
                    <p>Jovens com idades entre 15 e 29 anos e com renda até dois salários mínimos também têm direito à passagem gratuita. O benefício é válido apenas para carros convencionais que realizem transporte interestadual, ou seja, não vale para viagens entre municípios do mesmo estado e é limitado a dois assentos. Depois de esgotadas as vagas gratuitas, são oferecidas duas vagas com desconto mínimo de 50% no valor das passagens.</p>
                    <p>É importante lembrar que não estão incluídas no benefício as tarifas de utilização dos terminais, de pedágio e as despesas com alimentação. O bilhete deve ser solicitado nos pontos de venda da empresa com antecedência mínima de três horas em relação ao horário de partida e o beneficiário deve comparecer ao terminal de embarque pelo menos 30 minutos antes da hora marcada para o início da viagem.</p>
                    <h3>Passe Livre</h3>
                    <p>Portadores de necessidades especiais, físicas, mentais, visuais e auditivas, comprovadamente carentes, têm direito à gratuidade. Para o benefício, é necessária a apresentação do Passe Livre, cujas informações para requerimento estão disponíveis no site do Ministério dos Transportes, e de um documento oficial de identificação com foto.</p>
                    <p>A gratuidade se aplica, além da passagem, às taxas de embarque e pedágio em linhas interestaduais em serviços convencionais. Os portadores de necessidades especiais física, mental, visual ou auditiva, comprovadamente carentes, têm direito à gratuidade. Para utilizar esse benefício é necessária a obtenção de Passe Livre no Ministério dos Transportes. As informações para o requerimento do Passe Livre estão disponíveis no site do Ministério dos Transportes.</p>
                    <h3>Crianças menores de 5 anos</h3>
                    <p>Crianças com até seis anos incompletos (5 anos, 11 meses e 29 dias) poderão viajar sem pagar passagem desde que não ocupem poltrona específica para elas. Elas devem ir no colo de seu responsável, mas, se houver mais de uma criança com o mesmo responsável, uma delas precisará pagar passagem integral.</p>
                    <hr />
                    <h2>VIAGEM</h2>
                    <p><b>O que é necessário para embarque de menores de idade?</b></p>
                    <p>Para crianças menores de 16 anos que viajam acompanhadas de pais, parentes até 3º grau (avós, tios e irmãos maiores de 18 anos) ou tutores legais, é necessária a apresentação de Certidão de Nascimento ou documento com foto, além de documento do adulto, que comprove o grau de parentesco, tanto para a aquisição da passagem quanto para o embarque.</p>
                    <p>Se elas estiverem acompanhadas de outras pessoas que não sejam parentes, é necessária uma autorização dos pais ou responsáveis legais, com firma reconhecida, e ainda, uma autorização judicial, que pode ser solicitada no Juizado de Menores, Vara da Infância e da Juventude ou no Fórum da cidade onde reside.</p>
                    <p>Menores com idade superior a 16 anos podem viajar desacompanhados, desde que possuam documento de identificação original com foto.</p>
                    <p><b>Quanto tempo antes da viagem devo chegar para embarcar no terminal rodoviário?</b></p>
                    <p>Para assegurar um embarque tranquilo, é ideal que os passageiros estejam no terminal de embarque com pelo menos uma hora de antecedência. Em vésperas de feriados e feriados municipais, estaduais ou nacionais, quando as rodoviárias ficam mais cheias, com mais filas, é aconselhável que os passageiros cheguem com mais antecedência.</p>
                    <p><b>Comprei minha passagem pela internet (site ou aplicativo). Como faço para retirar o bilhete de viagem?</b></p>
                    <p>O embarque de passageiros está condicionado à emissão de passagens em papel, o que pode ser feito nos guichês e nas agências terceirizadas. Para isso, os clientes deverão apresentar impresso o voucher que comprove a compra por meios eletrônicos, acompanhado de documento oficial de identificação com foto.</p>
                    <p><b>Desisti de viajar. Posso trocar, cancelar ou remarcar a minha passagem?</b></p>
                    <h3>Troca</h3>
                    <p>A troca de data, origem ou destino é assegurada aos passageiros em viagens intermunicipais e interestaduais até 3 horas antes horário previsto para embarque em qualquer uma das empresas do Grupo Roderotas (RODEROTAS.COM; Expresso Araguari e Viação Estrela). Nesses casos, o passageiro deve dirigir-se ao guichê da empresa ou à agência terceirizada, solicitar a troca e pagar a diferença no valor da tarifa, caso haja. <b>Após esse prazo, não é possível trocar passagens de linhas intermunicipais </b>e, para as viagens interestaduais, a troca de linha, seção e horário está sujeita ao pagamento de uma multa de 20% sobre o valor da tarifa.</p>
                    <h3>Remarcação</h3>
                    <p>A remarcação ocorre quando o bilhete está vencido, ou seja, o embarque não foi realizado pelo passageiro para a data e hora prevista, por atraso ou desistência. Ou ainda, quando há desistência a menos de 3h do embarque. Nesse caso, as passagens para viagens interestaduais podem ser utilizadas em até doze meses, mas apenas para o mesmo itinerário, condicionada ao pagamento de multa de 20% do valor da tarifa e à diferença de preços das passagens, se houver. As passagens intermunicipais não podem ser reutilizadas depois de vencidas. Esse procedimento somente pode ser realizado diretamente em algum guichê da empresa.</p>
                    <h3>Cancelamento</h3>
                    <p>O cliente que desejar cancelar sua viagem e ser ressarcido deverá procurar um dos guichês da empresa com pelo menos 3 (três) horas de antecedência da viagem; <b>após esse período, não será mais possível realizar o reembolso.</b></p>
                    <p>A empresa se reserva o direito de cobrar uma multa de 5% do valor da passagem pela quebra de contrato unilateral, conforme estabelecido pelo Código Civil. Para compras feitas em dinheiro, o reembolso ocorrerá em 30 dias nos guichês ou agências. Para aquisições com o cartão de crédito, a devolução do valor, após a cobrança da multa, é feita pela administradora do cartão, de acordo com o fechamento da fatura do cliente.</p>
                    <b>Onde faço alteração da viagem?</b>
                    <p>Se a passagem foi adquirida em guichês ou agências terceirizadas, o passageiro deverá procurar esses estabelecimentos e solicitar a alteração, seguindo as recomendações descritas na pergunta anterior.</p>
                    <p><b>Posso transferir minha passagem para outra pessoa?</b></p>
                    <p>Sim, a transferência de titularidade é permitida desde que a passagem <b>não esteja vencida</b> e o titular do bilhete informe, pelos canais já designados para troca de passagem, o nome e o RG de quem deverá realizar a viagem.</p>
                    <hr />
                    <h2>BAGAGEM</h2>
                    <p><b>Qual é o tamanho / peso máximo de bagagem permitida?</b></p>
                    <p>Cada passageiro tem direito a transportar, gratuitamente, três volumes com dimensões máximas de um metro em qualquer sentido, volume máximo de 300dm³ e peso máximo de 30kg no bagageiro, além de até 5kg como bagagem de mão, desde que o conforto, a segurança e a higiene dos demais não sejam prejudicados. Caso o peso da bagagem ultrapasse o permitido, o passageiro pagará, para cada quilograma de excesso, até meio por cento do preço da passagem convencional.</p>
                    <p><b>Como devo acondicionar meus pertences em minha bagagem?</b></p>
                    <p>Recomenda-se evitar o transporte de objetos frágeis sem embalagem adequada, líquidos e gases de qualquer espécie, principalmente inflamáveis ou tóxicos. <b>É vedado o transporte de joias, dinheiro em espécie, documentos e eletrônicos como computadores portáteis, celulares e outros dentro das malas que serão despachadas. A empresa não se responsabiliza pelo eventual dano ou extravio desses objetos.</b> Se houver necessidade de transportar objetos de valor, aconselha-se que eles sejam despachados, com valor declarado, para fins de cobertura em caso de eventual sinistro.</p>
                    <p>O transporte de grandes quantias dinheiro não é acobertado por declaração de valor e por seguro e, por isso, deve ser evitado. O ideal é carregar apenas a quantia necessária para a viagem. <b>Os pertences de mão são de responsabilidade do passageiro e não são indenizáveis.</b></p>
                    <p><b>Minha bagagem foi avariada ou extraviada. O que devo fazer?</b></p>
                    <p>É importante que o passageiro acompanhe, no embarque, a etiquetagem e a colocação de suas malas no bagageiro e que confira o(s) ticket(s) numerados de identificação, para retirada da bagagem ao fim da viagem.</p>
                    <p>Em caso de dano ou extravio, o passageiro deve procurar imediatamente a bilheteria mais próxima e apresentar seu bilhete de passagem, o ticket de bagagem e documento oficial de identificação, com foto, para instauração do processo de apuração para indenização.</p>
                    <p>Os objetos transportados no porta-embrulhos ou no salão do ônibus são de responsabilidade do passageiro.</p>
                    <p><b>É permitido o transporte de animais de estimação? Como devo proceder?</b></p>
                    <p><b>Sim, cães e gatos de pequeno porte e cães-guia são permitidos, desde que observadas as seguintes orientações básicas:</b></p>
                    <h3>CONDIÇÕES e ACONDICIONAMENTO</h3>
                    <p>O animal deve estar em boas condições de saúde, o que deve ser atestado por um médico veterinário, e precisa estar com a carteira de vacinação em dia. Ele deve ser colocado em caixa ou gaiola apropriadas para o transporte, sem água ou alimentação. Se não houver objeção de nenhum passageiro, é permitido que o animal seja transportado no interior do veículo. Caso haja, o animal terá de ser colocado no bagageiro. Recomenda-se que o veterinário prescreva um tranquilizante, para evitar situações de muito estresse para os pets.</p>
                    <h3>ATESTADO SANITÁRIO</h3>
                    <p>O responsável que desejar transportar cães ou gatos a bordo do veículo deverá apresentar, preenchido e assinado por médico veterinário registrado no Conselho de Medicina Veterinária, o Atestado Sanitário para Trânsito de Cães e Gatos, conforme norma definida pela Instrução Normativa nº 18, 18 de julho de 2006, do Ministério da Agricultura, Pecuária e Abastecimento e Ofício SUPAS/ANTT nº 4.038/2006, sob pena de ter o embarque impedido.</p>
                    <p>O formulário pode ser obtido no site do Ministério. Para o trânsito de outras espécies de animais, o interessado deverá providenciar a Guia de Trânsito de Animal junto ao Ministério da Agricultura.</p>
                    <h3>RESTRIÇÕES</h3>
                    <p>Em nenhuma hipótese o animal poderá ser retirado da gaiola ou caixa e, se o animal for de porte médio ou grande, NÃO poderá ser transportado em ônibus (inclusive no bagageiro).</p>
                    <h3>ANIMAIS SILVESTRES</h3>
                    <p>Para serem transportados, os animais silvestres deverão estar devidamente acondicionados e acompanhados dos seguintes documentos: Guia de Transporte de Animal (GTA), atestado de saúde, cartão de vacinação atualizado e Autorização de Transporte emitida pelo IBAMA.</p>
                    <h3>CÃO-GUIA</h3>
                    <p>É assegurado o ingresso de cães-guia quando acompanhados de pessoa portadora de deficiência visual. A identificação do cão-guia e a comprovação de treinamento do usuário se darão por meio da apresentação de:  carteira e plaqueta de identificação, expedidas pelo centro de treinamento de cães-guia ou pelo instrutor autônomo; carteira de vacinação atualizada, que comprove vacinação múltipla e antirrábica, assinada por médico veterinário com registro no órgão regulador da profissão; coleira, guia e arreio com alça. O cão em fase de socialização e treinamento deverá ser identificado por uma plaqueta, presa à coleira, com a inscrição “cão-guia em treinamento”, aplicando-se as mesmas exigências de identificação do cão-guia, dispensado o uso de arreio com alça.</p>
                    <p><b>Para evitar transtornos com o passageiro ao lado e viabilizar conforto durante a viagem, recomenda-se a compra de poltrona adjacente.</b></p>
                </div>
            </Container>
            <Footer />
        </>
    );
}