import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { auth } from '../../utils/firebaseUtils';

import './style.css';

export default function VerifyEmail() {
  const [loadin, setLoadin] = useState(false);
  const [sended, setSended] = useState(false);

  async function sendEmail() {
    setLoadin(true);
    const user = auth().currentUser;
    user.sendEmailVerification()
      .then(() => {
        setSended(true);
        console.log('email de verificação enviado');
        toast.success("E-mail enviado, verifique sua caixa de entrada");
        setLoadin(false);
      })
      .catch((error) => {
        debugger;
        console.log(error.message);
        setLoadin(false);
        toast.error("Não foi possível enviar e-mail");
      });
  }

  function reload(){
    document.location.reload(false);
  }

  return (
    <div className="main-verifyEmail">
      <div>
          <h2>Precisamos verificar seu e-mail</h2>
          { !sended ? <p>Se ainda não recebeu e-mail de confirmação, clique no link abaixo.</p> : <p></p> }
          {loadin ? <span>Enviando e-mail</span> : 
            (sended ? <button onClick={reload}>Clique aqui para continuar</button> : <button onClick={sendEmail}>Enviar e-mail de confirmação</button>)
          }
      </div>
    </div>
  );
}
