import axios from "axios";
import StatesConvert from "../components/mapaRotas/stateUf";

const UTILS = {
  formaPagamento: (formaPagamento) => {
    switch (formaPagamento) {
      case "credcard":
        return "CREDITO";
      default:
        return formaPagamento;
    }
  },

  formataCPF_CNPJ: (cpf_cnpj) => {
    cpf_cnpj = cpf_cnpj.replace(/[^\d]/g, "");
    if (cpf_cnpj.length == 11) return cpf_cnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    else if (cpf_cnpj.length == 14) return cpf_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    else return cpf_cnpj
  },

  formataValorSemCifrao(valor) {
    try {
      valor = parseFloat(valor);
      var f2 = valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });
      return f2;
    } catch (error) {
      return valor;
    }
  },

  formataValorComCifrao(valor) {
    try {
      valor = parseInt(valor);
      var f2 = valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      return f2;
    } catch (error) {
      return valor;
    }
  },

  formataChave: (chave) => {
    chave = chave.replace(/[^\d]/g, "");
    return chave.match(/.{1,4}/g).join(" ");
  },

  formataURL: (url) => {
    return url.substr(0, url.indexOf("/", 8) + 1);
  },

  getCard: function (number) {
    const cardnumber = number.replace(/[^0-9]+/g, "");

    const cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };
    for (var flag in cards) {
      if (cards[flag].test(cardnumber)) {
        const img = {
          url: require(`../assets/img/cards/${flag}.png`),
          name: flag
        };
        return img;
      }
    }

    return false;
  },

  validationPayment: async function (params) {
    const validDtNascpassengers = await params.passageiros.filter(e => e.dtNasc === '');
    const validNamepassengers = await params.passageiros.filter(e => e.nome === '');
    const validDocpassengers = await params.passageiros.filter(e => e.doc === '');
    const validValidDocpassengers = await params.passageiros.filter(e => ((e.typeDoc === 'CPF' && !this.validateCPF(e.doc)) || (e.typeDoc === 'CNPJ' && !this.validateCNPJ(e.doc)) || (e.typeDoc === 'RG' && e.doc === '') || (e.doc === '')));
    const validCheckHasWoman = await params.passageiros.filter(e => (e.fixSexo && !e.read));

    if (validCheckHasWoman.length)
      return { status: false, msg: `É necessário aceitar o regulamento` }

    if (validNamepassengers.length)
      return { status: false, msg: `Preencha os nomes dos passageiros` }

    if (validDocpassengers.length)
      return { status: false, msg: `Preencha os documentos dos passageiros` }

    if (validValidDocpassengers.length)
      return { status: false, msg: `Verifique os documentos dos passageiros` }

    if (validDtNascpassengers.length)
      return { status: false, msg: `Informe a data de nascimento de todos os passageiros` }

    if (params.card.docCard === "")
      return { status: false, msg: `Preencha o ${params.card.typeDocInvoice} nos dados de pagamento` }
    if (((params.card.typeDocInvoice === "CPF" && !this.validateCPF(params.card.docCard)) || ((params.card.typeDocInvoice === "CNPJ" && !this.validateCNPJ(params.card.docCard)))))
      return { status: false, msg: `${params.card.typeDocInvoice} incorreto nos dados de pagamento` }
    if (params.card.addressLine1 === "")
      return { status: false, msg: `Preencha o seu endereço` }
    if (params.card.invoicePhone === "" || !this.validatePhone(params.card.invoicePhone))
      return { status: false, msg: `Informe seu telefone corretamente` }
    if (params.card.cityName === "")
      return { status: false, msg: `Preencha sua cidade` }
    if (params.card.stateAbbreviation === "")
      return { status: false, msg: `Preencha seu estado` }
    if (params.card.neighborhood === "")
      return { status: false, msg: `Preencha o bairro` }
    if (params.card.numberCard === "")
      return { status: false, msg: `Preencha o Número do cartão` }
    if (params.card.dateCard === "")
      return { status: false, msg: `Preencha a data de validade do cartão` }
    if (params.card.cvvCard === "")
      return { status: false, msg: `Preencha o CVV do cartão` }
    if (params.card.nameCard === "")
      return { status: false, msg: `Preencha o Nome do titular do cartão` }
    if (params.card.cepCard === "")
      return { status: false, msg: `Preencha o seu CEP` }
    if (params.card.invoiceEmail === "")
      return { status: false, msg: `Preencha o seu e-mail` }

    return { status: true, msg: 'Sem erros' }
  },

  validateCPF: function (strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    strCPF = strCPF.replace(/[._-]/g, "");
    // Elimina CPFs invalidos conhecidos
    if (strCPF == "00000000000" ||
      strCPF == "11111111111" ||
      strCPF == "22222222222" ||
      strCPF == "33333333333" ||
      strCPF == "44444444444" ||
      strCPF == "55555555555" ||
      strCPF == "66666666666" ||
      strCPF == "77777777777" ||
      strCPF == "88888888888" ||
      strCPF == "99999999999")
      return false;
    if (strCPF.length < 11) return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  },

  validateCNPJ: function (cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;
  },

  validateMail: function (email) {

    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },

  validateCEP: function (cep) {
    return String(cep)
      .toLowerCase()
      .match(
        /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/
      );
  },

  getDataCEP: async function (cep) {
    if (cep !== undefined && cep.replace(/[._-]/g, "").toString().length == 8) {
      return axios.get(`http://viacep.com.br/ws/${cep.replace(/[._-]/g, "")}/json/`);
    }
  },

  getLocation: async function () {
    return new Promise(async (resolve, reject) => {
      let loc = {};
      navigator.geolocation.getCurrentPosition(location => {
        if (location) {
          loc = {
            lat: location.coords.latitude,
            long: location.coords.longitude
          }
        }

        const url = `https://nominatim.openstreetmap.org/reverse?lat=${loc.lat}&lon=${loc.long}&format=json&addressdetails=1`;
        axios.get(url).then(async res => {
          resolve(res.data.address);
        }).catch(error => {
          reject(error);
        });

      }, (error) => reject(error));
    });
  },

  validatePhone: function (phone) {
    var telefone = phone.replace(/[^\d]+/g, '');
    //var regex = new RegExp('^(\\([0-9]{2}\\)|([0-9]{2}))(( [0-9]{4}-[0-9]{4})|(9 [0-9]{4}-[0-9]{4})|( 9[0-9]{4}-[0-9]{4})|( 9 [0-9]{4}-[0-9]{4})|(9[0-9]{4}-[0-9]{4})|(9[0-9]{4}[0-9]{4}))$');
    var regex = new RegExp('^([0-9]{2}[0-9]{4}[0-9]{4})|([0-9]{2}9[0-9]{4}[0-9]{4})$');
    return regex.test(telefone);
  },

  readXML: async function (xml) {
    return new Promise((resolve, reject) => {
      try {
        let body = new DOMParser().parseFromString(xml, "text/xml");
        resolve(body);
      } catch (error) {
        reject(error);
      }
    });
  },

  tryTimes: async function (fn, fnValid, time) {
    var index = 0;
    var returnFn = undefined;
    var waiting = false;
    var interval = undefined;
    return new Promise((resolve, reject) => {
      interval = setInterval(async () => {
        if (time === index) {
          clearInterval(interval);
          return reject(returnFn);
        }

        if (!waiting) {
          console.log(`Tentativa tryTimes ${index + 1}`);
          waiting = true;
          returnFn = await fn();

          try {
            fnValid(returnFn, index);
            clearInterval(interval);
            return resolve(returnFn);
          } catch (error) {
            waiting = false;
          }
          index++;
        }
      }, 1000);
    });
  },

  isMobile: () => {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }

    return false;
  },

};

export default UTILS;
