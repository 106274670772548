import React, { useEffect, useState } from "react";
import "./index.scss";
import BoxResult from "./box-result";
import Moment from "moment";
import { useHistory, useParams } from "react-router";

import { ReactComponent as Loading } from '../../../assets/img/loading.svg';
import TicketService from "../../../services/ticket";
import moment from "moment";
import { toast } from "react-toastify";



export default function Results(props) {
  const [dates, setDates] = useState([
    { loading: true },
    { loading: true },
    { loading: true },
    { loading: true },
    { loading: true },
  ]);
  const [showOtherDate, setshowOtherDate] = useState(false);

  const { origem, destino, type, date } = useParams();

  const history = useHistory();

  useEffect(() => {
    if (props.steps === "ida") {
      mountDate(date.split("-")[0]);
    } else {
      mountDate(date.split("-")[1]);
    }
  }, []);

  useEffect(() => {
    if (dates[0].date && dates[0].loading) {
      morePrices()
    }
  }, [dates]);

  function mountDate(date) {
    const arrayResults = [
      {
        loading: true,
        date: Moment(date, "DDMMYYYY").subtract(3, "days").format("ddd DD MMM")
      },
      {
        loading: true,
        date: Moment(date, "DDMMYYYY").subtract(2, "days").format("ddd DD MMM")
      },
      {
        loading: true,
        date: Moment(date, "DDMMYYYY").subtract(1, "days").format("ddd DD MMM"),
      },
      {
        loading: true,
        date: Moment(date, "DDMMYYYY").format("ddd DD MMM"),
      },
      {
        loading: true,
        date: Moment(date, "DDMMYYYY").add(1, "days").format("ddd DD MMM"),
      },
      {
        loading: true,
        date: Moment(date, "DDMMYYYY").add(2, "days").format("ddd DD MMM"),
      },
      {
        loading: true,
        date: Moment(date, "DDMMYYYY").add(3, "days").format("ddd DD MMM"),
      },
    ];
    setDates(arrayResults);
  }

  function morePrices() {
    const idOrigem = origem.split("-")[1];
    const idDestino = destino.split("-")[1];
    const results = [];

    dates.map(e => {
      const dateService = Moment(e.date, "ddd DD MMM");
      const params = {
        origem: props.steps !== 'volta' ? JSON.parse(idOrigem) : JSON.parse(idDestino),
        destino: props.steps === 'volta' ? JSON.parse(idOrigem) : JSON.parse(idDestino),
        data: dateService.format("YYYY-MM-DD"),
      };
      results.push(TicketService.BuscaCorrida(params));
      return true;
    });
    Promise.all(results).then((values) => {
      const response = values.map((e, index) => {
        const temp = dates[index];
        temp.loading = false;
        if (e.status !== 200) {
          temp.invalid = true;
          return temp;
        }
        if (e.data.lsServicos.length) {
          const menor = e.data.lsServicos.sort((a, b) => (a.preco > b.preco) ? 1 : -1);
          temp.price = menor[0].preco;
          return temp;
        }
        temp.invalid = true;
        return temp
      });
      setDates(response);
    });
  }

  function goTo(data) {
    const newDate = Moment(data, "ddd DD MMM").format("DDMMYYYY");
    if (type === "ida") {
      history.push(`/passagens/${origem}/${destino}/${type}/${newDate}`);
    } else {
      if (props.steps === "ida") {
        if (Moment(newDate, "DDMMYYYY").isAfter(Moment(date.split("-")[1], "DDMMYYYY"))) {
          let days = Moment(date.split("-")[0], "DDMMYYYY").diff(Moment(date.split("-")[0], "DDMMYYYY"), "days");
          history.push(`/passagens/${origem}/${destino}/${type}/${newDate}-${Moment(data, "ddd DD MMM").add(days, "days").format("DDMMYYYY")}`);
        } else {
          history.push(`/passagens/${origem}/${destino}/${type}/${newDate}-${date.split("-")[1]}`);
        }
      } else {
        if (Moment(newDate, "DDMMYYYY").isBefore(Moment(date.split("-")[0], "DDMMYYYY"))) {
          toast.error('Data inferior a data de ida!');
          return false;
        } else {
          history.push(`/passagens/${origem}/${destino}/${type}/${date.split("-")[0]}-${newDate}`);
        }
      }
    }
  }

  return (
    <section className="results">
      <div className="actionsResponsive">
        <button onClick={() => props.setOpenFilter()}>Filtros</button>
        <button onClick={() => setshowOtherDate(!showOtherDate)}>Outras datas</button>
      </div>
      {showOtherDate}
      <div className={`search-results ${showOtherDate && 'open'}`}>
        <ul className="date-tabs tabs-fixed-width">
          {dates.map((e, index) => (
            <li key={e + index} onClick={() => goTo(e.date)} className={index === 3 ? "activated date-tab" : "date-tab"}>
              <a >{e.date}</a>
              <span className="price">
                {
                  e.loading ? <Loading /> :
                    e.invalid ? "--" :
                      e.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                }
              </span>
            </li>
          ))}
        </ul>
        <div className="sort-results">
          <ul style={{fontWeight: "bold"}}>
            <li className="company">Empresa</li>
            <li className="hour">Saída</li>
            <li className="bus_stations">Embarque/Desembarque</li>
            <li className="duration">Duração</li>
            <li className="service_class">Classe/Assentos</li>
            <li className="price">Preço</li>
          </ul>
        </div>
      </div>
      <div>
        <h2></h2>
      </div>
      {props.dataFilter.map((e, index) => (
        <BoxResult
          key={index}
          changeStep={(val, bus) => props.changeStep(val, bus)}
          canBlock={(sendSelecteds) => props.canBlock(sendSelecteds)}
          results={e}
          data={props.data}
        />
      ))}
      {!props.dataFilter.length && (
        <div className="noResults">Nenhum resultado encontrado</div>
      )}
    </section>
  );
}
