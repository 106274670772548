import React, { useEffect, useLayoutEffect, useState } from "react";
import "./index.scss";
import Moment from "moment";
import { useHistory } from "react-router";
import UTILS from "../../utils/utils";

export default function MyRequests(props) {
  const [ativos, setAtivos] = useState(1);
  const [viagens, setViagens] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [loading, setLoading] = useState();

  const history = useHistory();

  useEffect(() => {
    const dateNow = Moment(new Date()).format("YYYYMMDD");
    var value = props.data;
    if (ativos == 1) {
      value = props.data.filter(
        (e) => Moment(e.date).format("YYYYMMDD") >= dateNow
      );
    } else {
      value = props.data.filter(
        (e) => Moment(e.date).format("YYYYMMDD") <= dateNow
      );
    }

    setListFilter([...value]);
  }, [ativos])

  useEffect(() => {
    setLoading(true);
    if (listFilter.length) {
      let tamanho = listFilter.length;
      let $viagens = [];
      listFilter.map(async (e, size) => {

        (new Promise((resolve, reject) => {
          e.viagem.map(async (viagem) => {
            try {
              var xmlBPE = e.assentos.find((el) => el.servico == viagem.bus.servico && el.bpe.prefixo == viagem.bus.prefixoLinha)['xmlBPE'];
              var xmlDocument = await UTILS.readXML(xmlBPE);
              let dtCompra = Moment(new Date(e.date.seconds * 1000)).format("dddd, DD MMMM YYYY")
              let dtSaida = Moment(xmlDocument.querySelector('dhViagem').innerHTML).format("DD/MM/YYYY");
              let hrSaida = Moment(xmlDocument.querySelector('dhViagem').innerHTML).format("HH:mm");

              if ($viagens.filter((el) => el.id == e.id).length) {
                $viagens.find((el) => el.id == e.id).parameters.push({
                  dtSaida,
                  hrSaida,
                  cidadeOrigem: viagem.origem.cidade,
                  dtChegada: Moment(viagem.bus.chegada).format("DD/MM/YYYY"),
                  hrChegada: Moment(viagem.bus.chegada).format("HH:mm"),
                  cidadeDestino: viagem.destino.cidade,
                });
                setViagens($viagens);
                resolve($viagens);
              } else {
                let mount = {
                  id: e.id,
                  dtCompra,
                  dtCompraOrder: Moment(new Date(e.date.seconds * 1000)),
                  parameters: [
                    {
                      dtSaida,
                      hrSaida,
                      cidadeOrigem: viagem.origem.cidade,
                      dtChegada: Moment(viagem.bus.chegada).format("DD/MM/YYYY"),
                      hrChegada: Moment(viagem.bus.chegada).format("HH:mm"),
                      cidadeDestino: viagem.destino.cidade,
                    }
                  ]
                }

                $viagens.push(mount);
                setViagens([...$viagens]);
                resolve($viagens);
              }



            } catch (error) {
              console.log("Não é BPe");
            }

          });

        })).then(() => {
          if (size == tamanho - 1 && $viagens.length == tamanho) {
            setLoading(false);
          }
        })
      });
    } else {
      setViagens([]);
    }
  }, [listFilter]);

  // function filter() {
  //   const dateNow = Moment(new Date()).format("YYYYMMDD");
  //   if (ativos) {
  //     return props.data.filter(
  //       (e) => Moment(e.date).format("YYYYMMDD") >= dateNow
  //     );
  //   } else {
  //     return props.data.filter(
  //       (e) => Moment(e.date).format("YYYYMMDD") <= dateNow
  //     );
  //   }
  // }

  return (
    <>
      {<div className="container-info">
        <div className="box-title-logged">
          <h1 className="title-logged">Meus Pedidos</h1>
          <div className="container-buttons">
            <button onClick={() => setAtivos(1)} className="btn-header-logged">
              <p className={`btnTextHeader ${ativos == 1 && "active"}`}>Ativos</p>
            </button>
            <button
              onClick={() => setAtivos(-1)}
              className="btn-header-logged"
            >
              <p className={`btnTextHeader ${ativos == -1 && "active"}`}>Inativos</p>
            </button>
          </div>
        </div>

        <div className="box-container-info">
          {viagens.length == 0 && (
            <div className="box-info">
              <p>Nenhuma viagem encontrada!</p>
            </div>
          )}
          {viagens.sort((val1, val2) => {
            return Moment(val1.dtCompraOrder).isBefore(Moment(val2.dtCompraOrder)) ? 1 : -1
          }).map((e) => {
            if (!loading) {
              return (
                <div className="box-info" key={e.id}>
                  <div className="info-left">
                    <p className="text-info-logged">identificador: {e.id}</p>
                    <p className="text-info-logged">
                      <b>Data da compra:</b>{" "}
                      {e.dtCompra}{" às "}{Moment(e.dtCompraOrder).format("HH:mm")}
                    </p>
                    <div className="rota">
                      {e.parameters.map((el) => (
                        <div key={`${e.id}_${Math.random() * 52}`} style={{ display: "flex", width: "100%", borderBottom: "1px dashed", marginTop: "15px" }}>
                          <>
                            <p className="text-info-logged">
                              <b>Saída:</b>{" "}{el.dtSaida}{" "}{el.hrSaida}h*<br />
                              <b>Origem:</b> {el.cidadeOrigem}
                            </p>
                            <i class='bx bx-right-arrow-alt' style={{ color: '#595959', paddingInline: "35px", paddingBlock: "10px" }} ></i>
                            <p className="text-info-logged">
                              <b>Chegada:</b>{" "}{el.dtChegada}{" "}{el.hrChegada}h*<br />
                              <b>Destino:</b>{" "}{el.cidadeDestino}
                            </p>
                          </>
                        </div>
                      ))}
                    </div>
                    <br/>
                    <small><strong>* Horário de Brasília</strong></small>
                  </div>

                  <div className="info-right">
                    {/* <p className="text-info-logged">Assento: 22B</p> */}

                    <button
                      onClick={() => history.push(`resume/${e.id}`)}
                      className="btn-show-ticket"
                    >
                      Exibir Passagem
                    </button>
                  </div>
                </div>
              );
            }

          })}
        </div>
      </div>}</>
  );
}
