import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";

import ScrollToTop from "./ScrollToTop";

// Pages
import HomePage from "../pages/home";
import Login from "../pages/login";
import PageNotFound from "../pages/notFound";
import RecoverPass from "../pages/recoverPass";
import Register from "../pages/register";
import PassagensPage from "../pages/passagens/passagens";
import Checkout from "../pages/checkout";
import Resume from "../pages/resume";
import Contact from "../pages/contact";
import PrivacyPolicy from "../pages/privacyPolicy";
import Logged from "../pages/logged";
import TermUse from "../pages/termsUse";
import About from "../pages/about";
import Faq from "../pages/faq";

// Components
import HeaderDefault from "../components/header";
import PassagemPrint from "../pages/passagemPrint";
import MyAccount from "../components/myAccount";
import Footer from "../components/footer/footer";
import UTILS from "../utils/utils";
import PassagensPageMobile from "../pages/passagens/passagens_mobile";
import Horarios from "../pages/horarios";

const CustomRoute = ({ isPrivate, hideHeader, validUpdateData, ...rest }) => {
  const globalContext = useGlobalContext();

  if (isPrivate && !globalContext.user) {
    return <Redirect to="/login" />;
  }

  if (validUpdateData) {
    if (globalContext.user && (globalContext.user.updateV === undefined || globalContext.user.updateV < 1)) {
      return <>
        {!hideHeader && <HeaderDefault />}
        <MyAccount update />
        <Footer />
      </>
    }
  }

  return (
    <>
      {/* Se necessário inserir condição para exibir component header */}
      {!hideHeader && <HeaderDefault />}
      <Route {...rest} />
    </>
  );
};

export default function Routes() {
  return (
    <ScrollToTop>
      <Switch>
        <CustomRoute exact path="/login" component={Login} />
        <CustomRoute exact path="/login/back" component={Login} />
        {/* Utilizar o atributo isPrivate para exigir usuario logado para continuar */}
        <CustomRoute exact path="/" component={HomePage} />
        {/* <CustomRoute exact path="/profile" isPrivate={true} component={Register} /> */}
        <CustomRoute exact path="/register" component={Register} />
        <CustomRoute
          exact
          path="/passagens/:origem/:destino/:type/:date"
          component={UTILS.isMobile() ? PassagensPageMobile : PassagensPage}
          validUpdateData
        />
        <CustomRoute exact path="/recoverPass" component={RecoverPass} />
        <CustomRoute exact path="/sobre_nos" component={About} />
        <CustomRoute exact path="/checkout" component={Checkout} />
        <CustomRoute exact path="/resume/:id" component={Resume} />
        {/* <CustomRoute exact path="/print/:id" hideHeader component={PassagemPrint} /> */}
        <CustomRoute exact path="/contact" component={Contact} />
        <CustomRoute exact path="/privacyPolicy" component={PrivacyPolicy} />
        <CustomRoute exact path="/profile" component={Logged} />
        <CustomRoute exact path="/termUse" component={TermUse} />
        <CustomRoute exact path="/faq" component={Faq} />
        <CustomRoute exact path="/horarios" component={Horarios} />
        <CustomRoute path="*" component={PageNotFound} />
      </Switch>
    </ScrollToTop>
  );
}
