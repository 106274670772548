import React, { useEffect, useState } from "react";
import "./index_mobile.scss";

import { ReactComponent as Cadeira } from "../../../../assets/img/assento.svg";
import { ReactComponent as CadeiraBloqueada } from "../../../../assets/img/assentoVazio.svg";

import Loading from "../../../../assets/img/loading.svg";
import TicketService from "../../../../services/ticket";
import { toast } from "react-toastify";

import MapOnibus from "./mapaOnibus";
import MaskLoading from "../../../../components/maskLoading/maskLoading";
import { useGlobalContext } from "../../../../context/GlobalContext";
import { useHistory } from "react-router-dom";

export default function BusMobile(props) {
  const [loading, setLoading] = useState(true);
  const [maskLoading, setMaskLoading] = useState(false);
  const [error, setErrorService] = useState(false);
  const [mapaOnibus, setMapOnibus] = useState(MapOnibus);
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState({});

  const globalContext = useGlobalContext();
  const history = useHistory();

  useEffect(() => {
    getData();
    var resetMap = mapaOnibus.map((e) => {
      e.selected = false;
      return e;
    });
    setMapOnibus(resetMap);
  }, []);

  async function getData() {
    const params = {
      origem: JSON.parse(props.data.grupoOrigemId),
      destino: JSON.parse(props.data.grupoDestinoId),
      data: props.data.dataSaida,
      servico: props.data.servico,
    };
    const result = await TicketService.BuscaOnibus(params);
    if (result.status === 200) {
      setErrorService(false);
      setData(result.data);
    } else {
      setData([]);
      setErrorService(true);
    }

    setLoading(false);
  }

  function renderChair(e) {
    const filter = data.mapaPoltrona.filter(
      (valid) => JSON.parse(valid.x) === e.x && JSON.parse(valid.y) === e.y
    );
    if (filter.length) {
      if (!filter[0].disponivel)
        return (
          <div className="cadeira">
            <CadeiraBloqueada className={`${validChairWoman(e) ? "mulher" : ""}`} />
          </div>
        );
      if (validChairWoman(e)) {
        return (
          <div onClick={() => selectedChair(filter[0])} className="cadeira">
            <span>{filter[0].numero}</span>
            <Cadeira className={`${e.selected ? validChairWoman(e) ? "active-mulher" : "active" : "mulher"}`} />
          </div>
        );
      } else {
        return (
          <div onClick={() => selectedChair(filter[0])} className="cadeira">
            <span>{filter[0].numero}</span>
            <Cadeira className={`${e.selected ? validChairWoman(e) ? "active-mulher" : "active" : "livre"}`} />
          </div>
        );
      }
    }

    return (
      <div className="cadeira notFound">
        <Cadeira />
      </div>
    );
  }

  function renderChairs(mapa) {
    var columns = {};

    mapa.map((e) => {
      const filter = data.mapaPoltrona.filter(
        (valid) => JSON.parse(valid.x) === e.x && JSON.parse(valid.y) === e.y
      );

      if (filter.length) {
        if (columns[filter[0]['y']] == undefined || !columns[filter[0]['y']].length)
          columns[filter[0]['y']] = [];

        if (!filter[0].disponivel){
          columns[filter[0].y].push((<li>
            <div class="assento">
              <span></span>
              <CadeiraBloqueada className={`${validChairWoman(e) ? "mulher" : ""}`} />
            </div>
          </li>));
        }else if (validChairWoman(e)) {
          columns[filter[0].y].push((<li>
            <div class="assento" onClick={() => selectedChair(filter[0])}>
              <span>{filter[0].numero}</span>
              <Cadeira className={`${e.selected ? validChairWoman(e) ? "active-mulher" : "active" : "mulher"}`} />
            </div>
          </li>));
        } else {
          columns[filter[0].y].push((<li>
            <div class="assento" onClick={() => selectedChair(filter[0])}>
              <span>{filter[0].numero}</span>
              <Cadeira className={`${e.selected ? validChairWoman(e) ? "active-mulher" : "active" : "livre"}`} />
            </div>
          </li>));
        }

      }
    });

    return Object.getOwnPropertyNames(columns).sort((val1, val2) => val1 > val2 ? -1 : 1).map((e) => {
      return (
        <ul>
          {columns[e].map((v) => {
            return v;
          })}
        </ul>
      );
    })


    // if (filter.length) {
    //   if (!filter[0].disponivel)
    //     return (
    //       <div className="cadeira">
    //         <CadeiraBloqueada className={`${validChairWoman(e) ? "mulher" : ""}`} />
    //       </div>
    //     );
    //   if (validChairWoman(e)) {
    //     return (
    //       <div onClick={() => selectedChair(filter[0])} className="cadeira">
    //         <span>{filter[0].numero}</span>
    //         <Cadeira className={`${e.selected ? validChairWoman(e) ? "active-mulher" : "active" : "mulher"}`} />
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <div onClick={() => selectedChair(filter[0])} className="cadeira">
    //         <span>{filter[0].numero}</span>
    //         <Cadeira className={`${e.selected ? validChairWoman(e) ? "active-mulher" : "active" : "livre"}`} />
    //       </div>
    //     );
    //   }
    // }

    // return (
    //   <div className="cadeira notFound">
    //     <Cadeira />
    //   </div>
    // );
  }

  function selecteds() {
    const filter = mapaOnibus.filter((e) => e.selected);
    if (filter.length) {
      const values = [];
      filter.map((e) => values.push(e));
      return values;
    } else {
      return [];
    }
  }

  async function selectedChair(e) {
    const index = mapaOnibus.findIndex(
      (valid) => valid.x === JSON.parse(e.x) && valid.y === JSON.parse(e.y)
    );
    const newValue = mapaOnibus;
    newValue[index].number = e.numero;

    // const response = await bloquearDesbloquearPontrona(newValue[index]);
    // if (response.status === 200) {
    //   if(!newValue[index].selected){
    //     newValue[index].transacao = response.data.transacao;
    //   }
    //   newValue[index].selected = !newValue[index].selected;
    //   newValue[index].woman = validChairWoman(newValue[index]);
    // }

    newValue[index].selected = !newValue[index].selected;
    newValue[index].woman = validChairWoman(newValue[index]);

    setMapOnibus(newValue);
    setRefresh(!refresh);
  }

  async function bloquearDesbloquearPontrona(values) {

    //debugger;

    const Paramns = {
      origem: JSON.parse(props.data.grupoOrigemId),
      destino: JSON.parse(props.data.grupoDestinoId),
      data: props.data.dataSaida,
      servico: props.data.servico,
      poltrona: values.number,
    };

    const result = await TicketService.BloquearPoltrona(Paramns);

    //debugger;

    if (result.status !== 200) {
      toast.error(
        `Não foi possível ${!values.selected ? "efetuar" : "desfazer"
        } a reserva!`
      );
    }
    return result;
    // return true;
  }

  function validChairWoman(e) {
    const onlyChairWoman = [{ x: 3, y: 0 }, { x: 3, y: 1 }, { x: 4, y: 0 }, { x: 4, y: 1 }];
    return onlyChairWoman.findIndex((el) => (el.x == e.x && el.y == e.y)) != -1;
    // return !data.mapaPoltrona.some(
    //   (pontrona) => JSON.parse(pontrona.x) < e.x - 1
    // );
  }

  async function changeStep() {
    const sendSelecteds = await selecteds();
    const results = [];
    if (props.canBlock(sendSelecteds)) {
      setMaskLoading(true);
      if (globalContext.user != undefined) {
        sendSelecteds.map((e) => results.push(bloquearDesbloquearPontrona(e)));
        Promise.all(results).then((bloqueios) => {
          const returnSelecteds = sendSelecteds.map((e1, index) => {
            //debugger;
            const temp = { ...e1 };
            temp.transacao = bloqueios[index];
            return temp;
          });
          props.changeStep(returnSelecteds, props.data);
        });
      }
    }
  }

  return loading ? (
    <div className="loading">
      <img src={Loading} className="loadingMask" />
    </div>
  ) : !error ? (
    <div className="marcaCaoBus">
      {maskLoading && <MaskLoading />}
      <div className="onibus">
        <div className="left__side">{renderChairs(mapaOnibus)}</div>
      </div>
      <div className="alinhamento">
        <div className="legend">
          <p className="livre" style={{marginTop:"0.5rem"}}>Livre</p>
          <p className="mulher" style={{marginTop:"0.5rem"}}>Espaço Mulher</p>
          <p className="selecionado" style={{marginTop:"0.5rem"}}>Selecionado</p>
          <p className="ocupado" style={{marginTop:"0.5rem"}}>Ocupado</p>
          <p className="ocupado mulher" style={{marginTop:"0.5rem"}}>Ocupado Mulher</p>
        </div>
        <div className="info">
          <p>Seus assentos</p>
          {!selecteds().length ? (
            <span>(Clique nos assentos desejados)</span>
          ) : (
            <div className="assentos">
              {selecteds().map((e, index) => (
                <div key={index} className={`item ${e.woman && "mulher"}`}>
                  <p>Assento {e.number}</p>
                  <div className="value">
                    <span>
                      {props.data.preco.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </span>
                    <button onClick={() => selectedChair(e)}>x</button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="action">
          {!globalContext.user && <button onClick={() => { history.push('/login/back') }}>Entrar para reservar</button>}
          {globalContext.user && <button disabled={!selecteds().length} onClick={changeStep}>
            Continuar reserva
          </button>}
        </div>
      </div>
    </div>
  ) : (
    <p>Erro ao obter dados do ônibus!</p>
  );
}
