import React, { useEffect, useState } from "react";
import "./index.scss";
import { Container } from "react-bootstrap";

import map from "../../assets/img/map.jpg";
import googleMaps from "../../assets/img/googleMaps.png";
import tripadvisor from "../../assets/img/tripadvisor.png";
import airbnb from "../../assets/img/airbnb.png";

import Footer from "../../components/footer/footer";
import { useHistory, useParams } from "react-router";
import Loading from "../../assets/img/loading.svg";
import DB from "../../utils/firestore";
import Moment from "moment";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";

export default function Resume(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [ativos, setAtivos] = useState(false);
  const [data, setData] = useState({});

  const history = useHistory();

  const { id } = useParams();
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let idConsult = props.id || id;
    if (idConsult) {
      const response = await DB.getOne("purchases", idConsult);
      if (response) {
        setData(response);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
    setLoading(false);
  }

  function valorTotal() {
    let somaTotal = 0;
    data.viagem.map((e, index) => {
      if (index === 0) {
        somaTotal += data.assentosIda.length * e.bus.preco;
      } else {
        somaTotal += data.assentosVolta.length * e.bus.preco;
      }
    });
    return somaTotal;
  }

  return loading ? (
    <div className="loading loadingHeight">
      <img src={Loading} className="loadingMask" />
    </div>
  ) : (
    <>
      <Container>
        {error ? (
          <p className="loadingHeight">Erro ao consultar dados</p>
        ) : (
          <>
            <div className="div-container">
              <div className="title-div">
                <h2 className="title-principal">
                  Sua compra foi concluída com sucesso!
                </h2>
                <p className="info">Código da compra</p>
                <h3 className="title-secundario">{data.id}</h3>
                {/* <center><Link target="_blank" to={`/print/${id}`} className="important">Emitir passagem</Link></center> */}
              </div>
            </div>

            <div className="div-container">
              <div className="container">
                <span className="title">Resumo da compra</span>
                <div>
                  {data.viagem.map((e, index) =>
                    <>
                      <div className="box-resume">
                        <p className="text">{e.origem.cidade} - {e.destino.cidade}</p>
                        <p className="text space-top">Viagem: <strong>{Moment(e.bus.saida).format("dddd, DD MMMM YYYY HH:mm")}*</strong></p>
                        <p>* Horário de Brasília</p>
                        <p className="text space-top">Assentos:</p>
                        {index === 0 ?
                          data.assentosIda.map(seat =>

                            <p className="text">Poltrona:  Nº {seat.number}<br/><strong>Localizador: {(data.assentos.find((el)=>el.poltrona == seat.number && el.servico == e.bus.servico).localizador)}</strong></p>
                          ) :
                          data.assentosVolta.map(seat =>
                            <p className="text">Poltrona:  Nº {seat.number}<br/><strong>Localizador: {(data.assentos.find((el)=>el.poltrona == seat.number && el.servico == e.bus.servico).localizador)}</strong></p>
                          )
                        }
                        <p>Por favor apresentar o localizador desta passagem no guichê da rodoviária de origem até 30 minutos antes do horário de embarque.</p>
                      </div>
                    </>
                  )}
                </div>

                <div className="box-total">
                  <div>
                    <h3 className="total">Valor total</h3>
                  </div>
                  <div>
                    <h3 className="total">
                      {valorTotal().toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="map">
                <span className="title">Tenha uma viagem inesquecível!</span>
                <div className="containerMap">
                  <div className="divContainerImg">
                    <div className="containerTitleMap">
                      <span className="tilteMap">Mapa do seu destino</span>
                    </div>
                    <a href={"https://www.google.com.br/maps/search/" + data.viagem[data.viagem.length - 1].destino?.cidade} target="_blank">
                      <figure>
                        <img src={googleMaps} className="img-fluid" alt="" />
                      </figure>
                    </a>
                  </div>
                  <div className="divContainerImg">
                    <div className="containerTitleMap">
                      <span className="tilteMap">Saiba o que fazer lá</span>
                    </div>
                    <a href={"https://www.tripadvisor.com.br/Search?q=" + data.viagem[data.viagem.length - 1].destino?.cidade} target="_blank">
                      <figure>
                        <img src={tripadvisor} className="img-fluid" alt="" />
                      </figure>
                    </a>
                  </div>
                  <div className="divContainerImg">
                    <div className="containerTitleMap">
                      <span className="tilteMap">Encontre Hospedagem</span>
                    </div>
                    <a href={"https://www.airbnb.com.br/s/" + data.viagem[data.viagem.length - 1].destino?.cidade + "/homes"} target="_blank">
                      <figure>
                        <img src={airbnb} className="img-fluid" alt="" />
                      </figure>
                    </a>
                  </div>

                </div>
                {/* <figure>
                  <img src={map} className="img-fluid" alt="" />
                </figure> */}
              </div>
            </div>

            <div className="div-container">
              <div className="buttons">
                <button onClick={() => history.push('/')} className="large">Voltar para a página inicial</button>
                <button onClick={() => history.push('/profile')} className="large">Ir para meu perfil</button>
              </div>
            </div>

            <div className="div-container">
              <div className="download">

              </div>
            </div>
          </>
        )}
      </Container>
      {!props.id && <Footer />}
    </>
  );
}
