import React, { useEffect, useState } from "react";
import "./index.scss";
import { Container } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DB from "../../utils/firestore";
import { useGlobalContext } from "../../context/GlobalContext";

import fone from "../../assets/img/iconFone.png";
import { ReactComponent as Facebook } from "../../assets/img/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/img/instagram.svg";
import { ReactComponent as Twitter } from "../../assets/img/twitter.svg";
import { ReactComponent as Whatsapp } from "../../assets/img/whatsapp.svg";

import PaymentsCards from "../paymentCards";

export default function Footer(props) {
  const globalContext = useGlobalContext();
  const history = useHistory();
  const location = useLocation();

  function goTo(href) {
    history.push(href);
  }

  function goToCompre(e){
    e.preventDefault();
    if(location.pathname !== '/home' || location.pathname !== '/') {
      goTo('/')
    }else {
      window.scrollTo(0, 0);
    }
  }

  return (
    <footer>
      <div className="footer-yellow">
        <Container>
          <div className="boxFooter">
            <nav>
              <ul>
                <span>Institucional</span>
                <li>
                  <a onClick={() => goTo("/privacyPolicy")}>
                    Política de privacidade
                  </a>
                </li>
                <li>
                  <a onClick={() => goTo("/termUse")}>Termo de Uso</a>
                </li>
                <li>
                  <a onClick={() => goTo("/sobre_nos")}>Sobre nós</a>
                </li>
              </ul>
              <ul>
                <span>Ajuda</span>
                <li>
                  <a onClick={() => goTo("/contact")}>Atendimento</a>
                </li>
                <li>
                  <a onClick={() => goTo("/faq")}>Perguntas Frequentes</a>
                </li>
                <li>
                  <a onClick={() => goTo("/horarios")}>Horários Semiurbanos</a>
                </li>
                {/* <li>
                  <a onClick={() => goTo('/')}>Dúvidas Frequentes</a>
                </li> */}
              </ul>
            </nav>
            <a href="#" onClick={goToCompre} className="goSearch">
              Compre <br></br>
              agora mesmo
            </a>
            <div className="sac">
              <span className="title">Sac</span>
              <div className="boxSac">
                <figure>
                  <img src={fone} alt="" />
                </figure>
                <div>
                  {globalContext.configs && globalContext.configs[0] && (<span>{globalContext.configs[0].phone_sac}</span>)}
                  {globalContext.configs && globalContext.configs[0] && (<span>{globalContext.configs[0].email_sac}</span>)}
                </div>
              </div>
              {globalContext.configs && globalContext.configs[0] && (
                <span className="cargaHoraria">
                  {globalContext.configs[0].opening_hours}
                </span>
              )}
            </div>
          </div>
        </Container>
      </div>
      {!props.hideFooterWhite && (
        <div className="footer-white">
          <Container>
            <div className="boxFooterWhite">
              <nav>
                <ul>
                  <span>Pagamento</span>
                  <li>
                    {/* <img src={bandeiraCartoes} alt="Logo Estrela" /> */}
                    <PaymentsCards />
                  </li>
                </ul>
              </nav>
              <div className="social">
                <span className="title">Segurança</span>
                <span className="subTitle">Compra 100% segura</span>
                <div className="box-icons">
                  {globalContext.configs && globalContext.configs[0] && (
                    <a href={globalContext.configs[0].facebook} target="_blank">
                      <Facebook className="icon" />
                    </a>
                  )}
                  {globalContext.configs && globalContext.configs[0] && (
                    <a
                      href={globalContext.configs[0].instagram}
                      target="_blank"
                    >
                      <Instagram className="icon" />
                    </a>
                  )}
                  {globalContext.configs && globalContext.configs[0] && (
                    <a href={globalContext.configs[0].twitter} target="_blank">
                      <Twitter className="icon" />
                    </a>
                  )}
                  {globalContext.configs && globalContext.configs[0] && (
                    <a href={globalContext.configs[0].whatsapp} target="_blank">
                      <Whatsapp className="icon" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </footer>
  );
}
