import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { Container, ButtonGroup, ToggleButton, Button } from "react-bootstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import "./index.scss";
import PaymentsCards from "../../components/paymentCards";
import moment from "moment";
import UTILS from "../../utils/utils";
import { useGlobalContext } from "../../context/GlobalContext";
import PaymentService from "../../services/payment";
import { toast } from "react-toastify";
import TicketService from "../../services/ticket";
import { Firestore } from "../../utils/firebaseUtils";
import { useHistory } from "react-router";
import Modal from 'react-modal';
import ReCAPTCHA from "react-google-recaptcha";

import LoadingSvg from "../../assets/img/loading.svg";
import VerifyEmail from "../../components/verifyEmail/verifyEmail";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '750px',
    textAlign: 'justify',
    overflow: 'auto',
    maxHeight: '550px',
  },
};

export default function Checkout(props) {
  let subtitle;
  const [formPassageiros, setFormPassageiros] = useState({
    card: {},
    passageiros: [],
  });
  const [refresh, setRefresh] = useState(true);
  const [captcha, setCaptcha] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [regulamentoOpened, setRegulamentoOpened] = useState(false);
  const [card, setCard] = useState(false);
  const [cep, setCEP] = useState();
  const [loading, setLoading] = useState(false);
  const [modalPayment, setmodalPayment] = useState(false);
  const [showDetails, setshowDetails] = useState([
    { show: false },
    { show: false },
  ]);

  const globalContext = useGlobalContext();
  const history = useHistory();

  function openModal() {
    setIsOpen(true);
    if (!regulamentoOpened)
      setRegulamentoOpened(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#FFC512';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function onChange(value) {
    setCaptcha(value);
  }

  useEffect(() => {
    if (cep !== undefined && cep.toString() !== "" && cep.toString().length === 10) {
      UTILS.getDataCEP(cep).then((rs) => {
        let dataCEP = rs.data;
        const temp = formPassageiros;
        temp.card.addressLine1 = temp.card.addressLine1 !== "" ? temp.card.addressLine1 : dataCEP['logradouro'];
        temp.card.stateAbbreviation = temp.card.stateAbbreviation !== "" ? temp.card.stateAbbreviation : dataCEP['uf'];
        temp.card.cityName = temp.card.cityName !== "" ? temp.card.cityName : dataCEP['localidade'];
        temp.card.neighborhood = temp.card.neighborhood !== "" ? temp.card.neighborhood : dataCEP['bairro'];
        setFormPassageiros(temp);
        setRefresh(!refresh);
      }).catch(err => {
        return;
      })
    }
  }, [cep])

  useEffect(() => {
    const contVolta = props.transaction.assentos[1]
      ? props.transaction.assentos[1].length
      : 0;
    const countPassageiros = Math.max(
      props.transaction.assentos[0].length,
      contVolta
    );

    const hasWoman = props.transaction.assentos[0].filter(el => { return el['woman'] == true; }).length > 0 || (props.transaction.assentos.length > 1 ? props.transaction.assentos[1].filter(el => { return el['woman'] == true; }).length > 0 : false);
    const countWoman = Math.max(props.transaction.assentos[0].filter(el => { return el['woman'] == true; }).length,
      (props.transaction.assentos.length > 1 ? props.transaction.assentos[1].filter(el => { return el['woman'] == true; }).length : 0));
    const valuesPassageiros = [];

    var everWoman = 0;
    for (let index = 0; index < countPassageiros; index++) {
      if (hasWoman && everWoman < countWoman) {
        everWoman++;
        valuesPassageiros.push({ nome: "", typeDoc: "CPF", doc: "", checked: false, sexo: "F", fixSexo: true, dtNasc: "", read: false });
      } else {
        valuesPassageiros.push({ nome: "", typeDoc: "CPF", doc: "", checked: false, sexo: "M", fixSexo: false, dtNasc: "", read: false });
      }
    }
    const dataCard = {
      numberCard: "",
      dateCard: "",
      cvvCard: "",
      docCard: globalContext.user.cpf,
      qtdPag: "1",
      nameCard: "",
      fullname: globalContext.user.name,
      typeDocInvoice: "CPF",
      cepCard: globalContext.user.cep,
      invoiceEmail: globalContext.user.email,
      invoicePhone: globalContext.user.fone,
      addressLine1: globalContext.user.addressLine1,
      number: globalContext.user.number,
      cityName: globalContext.user.cityName,
      stateAbbreviation: globalContext.user.stateAbbreviation,
      neighborhood: globalContext.user.neighborhood,
    };
    setFormPassageiros({
      ...formPassageiros,
      card: dataCard,
      passageiros: valuesPassageiros,
    });
  }, []);

  function valorTotal() {
    let somaTotal = 0;
    if (props.transaction && props.transaction.viagem && props.transaction.assentos) {
      props.transaction.viagem.map((e, index) => {
        somaTotal += props.transaction.assentos[index].length * e.bus.preco;
      });
    }
    return somaTotal;
  }

  function maskDoc(e) {
    if (e === "CPF") {
      return "999.999.999-99";
    } else if (e === "CNPJ") {
      return "99.999.999/9999-99";
    }
    return null;
  }

  function parcelamento() {
    let total = valorTotal();
    const parcelas = [];
    for (let index = 1; index <= 6; index++) {
      let valortotalCalc = total;
      if (index > 3) {
        const percent = 5.79;
        const factor = (total / 100) * percent;
        valortotalCalc += factor;
      }
      const valorParcela = {
        valorParcela: valortotalCalc / index,
        parcela: index,
        name: `${index}x ${(valortotalCalc / index).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })} - ${index > 3 ? 'com juros' : 'sem juros'}`,
      };
      parcelas.push(valorParcela);
    }
    return parcelas;
  }

  function detectCard(e) {
    const card = UTILS.getCard(e);
    setCard(card);
  }

  async function buyTicket() {
    // VALIDAÇÃO DE QUANTIDADE DE TENTATIVAS
    try {
      let dataActual = undefined;
      let limitBuy = undefined;
      const search = await Firestore.collection("users").doc(globalContext.user.id).get();
      const dataUser = search.data();
      if (!dataUser['dhlast_try']) {
        const dataUpdate = { 'dhlast_try': moment().format(), limit_buy: 2 };
        await Firestore.collection("users").doc(globalContext.user.id).update(dataUpdate);
        dataActual = dataUpdate.dhlast_try;
        limitBuy = dataUpdate.limit_buy;
      } else {
        dataActual = dataUser.dhlast_try;
        limitBuy = dataUser.limit_buy ?? 2;
      }

      if (moment().isAfter(moment(dataActual), 'day')) {
        limitBuy = 2;
        dataActual = moment().format();
        const dataUpdate = { 'dhlast_try': dataActual, limit_buy: limitBuy };
        await Firestore.collection("users").doc(globalContext.user.id).update(dataUpdate);
      } else {
        if (moment().isSame(moment(dataActual), 'day') && limitBuy > 0) {
          limitBuy = --limitBuy;
          dataActual = moment().format();
          const dataUpdate = { 'dhlast_try': dataActual, limit_buy: limitBuy };
          await Firestore.collection("users").doc(globalContext.user.id).update(dataUpdate);
        }
      }

      if (limitBuy == 0) {
        toast.error(
          "Máximo de tentativas excedido!"
        );
        return false;
      }

    } catch (error) {
      toast.error(
        "Máximo de tentativas excedido!"
      );
      return false;
    }

    // PAGAMENTO
    const params = formPassageiros;
    const validation = await UTILS.validationPayment(params);

    // CHECAR PAGAMENTO
    if (!validation.status) {
      toast.error(validation.msg);
      return false;
    }

    // ORDENAÇÃO DOS ASSENTOS
    setLoading(true);
    const itensPayment = [];
    props.transaction.assentos.map((itemAssento, index) => {
      const idBus = props.transaction.viagem[index].bus.servico;
      const priceItem = parseFloat(props.transaction.viagem[index].bus.preco).toLocaleString('en-us', { minimumFractionDigits: 2 }).replace(".", "");
      const origem = props.transaction.viagem[index].origem.cidade;
      const destino = props.transaction.viagem[index].destino.cidade;
      itemAssento.map(() => {
        const item = {
          id: idBus,
          description: `Passagem (${origem}/${destino})`,
          price: JSON.parse(priceItem),
          quantity: 1,
          tangible: false,
        };
        itensPayment.push(item);
      });
    });

    let valorTratado = valorTotal();

    if (params.card.qtdPag > 3) {
      const percent = 5.79;
      const factor = (valorTratado / 100) * percent;
      valorTratado += factor;
    }

    valorTratado = (valorTratado).toFixed(2) * 100;

    let ticket = {
      // amount: 100,
      captcha: captcha,
      amount: parseInt(JSON.parse(valorTratado).toFixed(0)),
      id: globalContext.user.id,
      installments: params.card.qtdPag,
      customer: {
        external_id: globalContext.user.id,
        street: params.card.addressLine1,
        street_number: params.card.number,
        neighborhood: params.card.neighborhood,
        zipcode: params.card.cepCard.replace(/-/g, "").replace(/\./g, ""),
        complementary: params.card.addressLine1,
        city_name: params.card.cityName,
        state_abbreviation: params.card.stateAbbreviation,
        ddd: params.card.invoicePhone.split(" ")[0],
        phone: params.card.invoicePhone,
        name: globalContext.user.name,
        email: globalContext.user.email,
        cpf_cnpj: params.card.docCard.replace(/-/g, "").replace(/\./g, ""),
      },
      card: {
        card_holder_name: params.card.nameCard,
        card_expiration_date: params.card.dateCard.replace("/", ""),
        card_number: params.card.numberCard.replace(/\s+/g, ""),
        card_cvv: params.card.cvvCard,
      },
      items: itensPayment,
    };

    let _ticket2 = { ...ticket, card: {}, customer: { ...ticket.customer }, dadosPassageiros: undefined, date: new Date(), date_formatted: moment().format("DD/MM/YYYY").toString(), };

    const paramsPassageiros = [];
    let transactionLocal = localStorage.getItem('transaction');
    transactionLocal = JSON.parse(transactionLocal);
    transactionLocal.assentos.map((e, indexViagem) => {
      e.sort((el) => el.woman ? -1 : 1).map((assento, index) => {

        const dadosPassageiros = {
          transacao: assento.transacao ? assento.transacao.data.transacao : null,
          nomePassageiro: formPassageiros.passageiros[index]["nome"],
          dataNascimento: formPassageiros.passageiros[index]["dtNasc"],
          sexo: formPassageiros.passageiros[index]["sexo"],
          documentoPassageiro: formPassageiros.passageiros[index]["doc"]
            .replace(/-/g, "")
            .replace(/\./g, ""),
          tipoDocumentoPassageiro: formPassageiros.passageiros[index]["typeDoc"],
          embarqueId: transactionLocal['viagem'][indexViagem]['origem']['id'] ?? null,
          desembarqueId: transactionLocal['viagem'][indexViagem]['destino']['id'] ?? null,
        };

        paramsPassageiros.push(dadosPassageiros);

      });
    });

    _ticket2['dadosPassageiros'] = [...paramsPassageiros];
    delete _ticket2.captcha;
    var payref = await Firestore.collection("payments_process").add(_ticket2);

    const efetuarPagamento = await PaymentService.transaction(ticket);

    if (efetuarPagamento.status === 200 && efetuarPagamento.data.status === 'paid') {
      const paramsConfirmVenda = [];
      let transactionLocal = localStorage.getItem('transaction');
      transactionLocal = JSON.parse(transactionLocal);
      transactionLocal.assentos.map((e, indexViagem) => {
        e.sort((el) => el.woman ? -1 : 1).map((assento, index) => {
          const paramsConfirmarVenda = {
            transacao: assento.transacao ? assento.transacao.data.transacao : null,
            nomePassageiro: formPassageiros.passageiros[index]["nome"],
            dataNascimento: formPassageiros.passageiros[index]["dtNasc"],
            sexo: formPassageiros.passageiros[index]["sexo"],
            documentoPassageiro: formPassageiros.passageiros[index]["doc"]
              .replace(/-/g, "")
              .replace(/\./g, ""),
            tipoDocumentoPassageiro: formPassageiros.passageiros[index]["typeDoc"],
            // seguro (OPCIONAL): "teste",
            // descontoPercentual (OPCIONAL): "teste",
            // descontoValor (OPCIONAL): "teste",
            numAutorizacao:
              efetuarPagamento.data.charges[0].last_transaction.acquirer_auth_code,
            numParcelas: params.card.qtdPag,
            // localizador (OPCIONAL): "teste",
            formaPagamento: "credcard",
            nsu: efetuarPagamento.data.charges[0].last_transaction.acquirer_nsu,
            hash: efetuarPagamento.data.charges[0].last_transaction.acquirer_tid,
            idPagarme: efetuarPagamento.data.charges[0].id,
            telefone: params.card.invoicePhone,
            embarqueId: transactionLocal['viagem'][indexViagem]['origem']['id'] ?? null,
            desembarqueId: transactionLocal['viagem'][indexViagem]['destino']['id'] ?? null,
            // numeroFidelidade (OPCIONAL): "teste",
            // identificacaoCartaoReduzida true|false (OPCIONAL): "teste",
            // gerarQRCodeTaxaEmbarque true|false (OPCIONAL): "teste",
            // preencherTipoTaxaEmbarqueW2i true|false (OPCIONAL): "teste",
          };
          // if (paramsConfirmVenda.findIndex((value, index) => {
          //   return paramsConfirmarVenda.documentoPassageiro === value.documentoPassageiro && paramsConfirmarVenda.tipoDocumento === value.tipoDocumento;
          // }) === -1) {
          return paramsConfirmVenda.push(paramsConfirmarVenda);
          //}
        });
      });

      //const result = await TicketService.ConfirmarVenda(paramsConfirmVenda);
      let result = undefined;
      var tryMax = 3;

      result = {
        status: 500
      }

      /**
       * Tentativa de emissão de BPe
       */
      await UTILS.tryTimes(async () => {
        return await TicketService.ConfirmarVenda(paramsConfirmVenda);
      }, (rs, index) => {
        if (rs.status != 200 || rs === null) {
          console.log(`${index + 1}ª tentativa`);
          throw `Tentando novamente! ${index + 1}º vez`;
        } else {
          return rs;
        }
      }, tryMax).then((data) => {
        result = data;
      }).catch((error) => {
        result = error;
      });

      // await (() => {
      //   return new Promise((resolve, reject) => {
      //     var interval = setInterval(async () => {
      //       if ((result === undefined || result['status'] !== 200) && canProcess && tryCount < tryMax) {
      //         console.log(`${tryCount + 1}ª tentativa`);
      //         canProcess = false;
      //         result = await TicketService.ConfirmarVenda(paramsConfirmVenda);
      //         if (result['status'] !== 200) canProcess = true;
      //         tryCount++;
      //       } else if (result !== undefined && result['status'] === 200) {
      //         console.log(`Emitida na ${tryCount}ª tentativa`);
      //         clearInterval(interval);
      //         resolve({ status: 200 });
      //       } else if (tryCount >= tryMax) {
      //         console.log(`Cancelada na ${tryCount}ª tentativa`);
      //         clearInterval(interval);
      //         resolve({ status: 500 });
      //       }
      //     }, 1000);
      //   });
      // })();

      if (result.status === 200) {
        const validaIda = [];
        props.transaction.assentos[0].map((valid) => {
          const temp = valid;
          temp.transacao = null;
          validaIda.push(temp);
        });

        const validaVolta = [];
        props.transaction.assentos[1] &&
          props.transaction.assentos[1].map((valid) => {
            const temp = valid;
            temp.transacao = null;
            validaVolta.push(temp);
          });

        const user = { ...globalContext.user };
        delete user.autentication;
        const paramsSaveVenda = {
          pagamento: efetuarPagamento.data,
          assentos: result.data,
          viagem: props.transaction.viagem,
          id_user: globalContext.user.id,
          user: user,
          assentosIda: validaIda,
          assentosVolta: validaVolta,
          date: new Date(),
          payref: payref.id.toString()
        };
        const savepurchases = await Firestore.collection("purchases").add(
          paramsSaveVenda
        );

        let formData = {
          fone: formPassageiros.card.invoicePhone,
          cpf: formPassageiros.card.docCard
        };

        if (!globalContext.user.addressLine1 || globalContext.user.addressLine1 === "") {
          formData.addressLine1 = params.card.addressLine1;
        }
        if (!globalContext.user.cityName || globalContext.user.cityName === "") {
          formData.cityName = params.card.cityName;
        }

        if (!globalContext.user.cep || globalContext.user.cep === "") {
          formData.cep = params.card.cepCard;
        }
        if (!globalContext.user.neighborhood || globalContext.user.neighborhood === "") {
          formData.neighborhood = params.card.neighborhood;
        }
        if (!globalContext.user.number || globalContext.user.number === "") {
          formData.number = params.card.number;
        }

        if (!globalContext.user.stateAbbreviation || globalContext.user.stateAbbreviation === "") {
          formData.stateAbbreviation = params.card.stateAbbreviation;
        }

        Firestore.collection("users").doc(globalContext.user.id).update(formData);

        if (!props.noRedirect) {
          props.setPurchases(savepurchases.id);
        }
        const objEmail = paramsSaveVenda;
        objEmail.id = savepurchases.id;
        await PaymentService.sendEmail(objEmail);
        history.push(`/resume/${savepurchases.id}`)

      } else {
        var cancelPromise = [];
        paramsConfirmVenda.forEach((transactionBody) => {
          cancelPromise.push(TicketService.CancelarVenda(transactionBody));
        })

        Promise.allSettled(cancelPromise).then((rs) => {
          var hasError = rs.filter((el) => el.status === "rejected");
        }).finally(async () => {
          setTimeout(async function () {
            // const retornVenda = await PaymentService.refound(
            //   efetuarPagamento.data.charges[0].id
            // );
            await UTILS.tryTimes(async () => {
              let refound = await PaymentService.refound(
                efetuarPagamento.data.charges[0].id
              );

              return refound;
            }, (rs, index) => {
              if (rs.status != 200 || rs === null) {
                throw `Tentando novamente! ${index + 1}º vez`;
              } else {
                return rs;
              }
            }, 5).then((data) => {
              toast.error(
                "Houve um erro ao reservar suas cadeiras, o pagamento será estornado em sua fatura!"
              );
              setLoading(false);
            }).catch((error) => {
              toast.error(
                "Houve um erro ao reservar suas cadeiras, entre em contato para estornar sua compra!"
              );
              setLoading(false);
            });

            // if (retornVenda.status === 200) {
            //   toast.error(
            //     "Houve um erro ao reservar suas cadeiras, o pagamento será estornado em sua fatura!"
            //   );
            // } else {
            //   toast.error(
            //     "Houve um erro ao reservar suas cadeiras, entre em contato para estornar sua compra!"
            //   );
            // }
          }, 5000);

          const user = { ...globalContext.user };
          delete user.autentication;
          const errorConfirm = {
            pagamento: efetuarPagamento.data,
            assentos: JSON.stringify(result),
            viagem: props.transaction.viagem,
            id_user: globalContext.user.id,
            user: user,
            assentosIda: null,//validaIda,
            assentosVolta: null,//validaVolta,
            date: new Date(),
            status: "Erro ao confirmar venda",
            payload: paramsConfirmVenda,
            payref: payref.id.toString()
          };
          await Firestore.collection("purchases_erros").add(errorConfirm);
        });
      }
    } else {
      const validaIda = [];
      props.transaction.assentos[0].map((valid) => {
        const temp = valid;
        temp.transacao = null;
        validaIda.push(temp);
      });

      const validaVolta = [];
      props.transaction.assentos[1] &&
        props.transaction.assentos[1].map((valid) => {
          const temp = valid;
          temp.transacao = null;
          validaVolta.push(temp);
        });

      const user = { ...globalContext.user };
      delete user.autentication;
      const paramsSaveVenda = {
        pagamento: efetuarPagamento.data,
        // assentos: result.data,
        viagem: props.transaction.viagem,
        id_user: globalContext.user.id,
        user: user,
        assentosIda: validaIda,
        assentosVolta: validaVolta,
        date: new Date(),
        status: "Erro ao efetuar pagamento",
        payload: ticket,
        payref: payref.id.toString()
      };
      await Firestore.collection("purchases_erros").add(paramsSaveVenda);


      if (efetuarPagamento.status === 500 && efetuarPagamento.data['errorCaptcha'] !== undefined) {
        toast.error(
          "O captcha não foi validado, tente novamente!"
        );
      } else {
        setmodalPayment(true);
        toast.error(
          "Erro no pagamento, verifique os dados cadastrados e tente novamente!"
        );
      }
      setLoading(false);

    }
  }

  function showDetalhe(index) {
    const temp = showDetails;
    temp[index].show = !temp[index].show;
    setshowDetails(temp);
    setRefresh(!refresh);
  }

  return (
    !globalContext.user.autentication.emailVerified ? (<VerifyEmail />) : (
      <>
        {/* {loading && <MaskLoading /> } */}
        {modalPayment && (
          <div className="modal-session">
            <div className="content">
              <p>Erro ao processar pagamento!</p>
              <span>
                Verifique os dados do cartão, ou consulte sua administradora de
                cartão!
              </span>
              <a onClick={() => setmodalPayment(false)} className="btn">
                Tentar novamente
              </a>
            </div>
          </div>
        )}
        <Container>
          <div className="grid-div">
            <div className="input">
              <h3 className="title">Passageiros</h3>
              <p className="detail">
                Cada passageiro tem um assento para ida e para volta.
              </p>
              {formPassageiros.passageiros &&
                formPassageiros.passageiros.map((e, index) => {
                  return (
                    <div className="form-box form-wrap">
                      <div className="form-group col-md-6 space-top">
                        <p className="text">Passageiro {index + 1}</p>
                      </div>
                      {((formPassageiros.passageiros[index].fixSexo && globalContext.user.sexo == formPassageiros.passageiros[index].sexo) || formPassageiros.passageiros[index].fixSexo == false) && globalContext.user.nome != "" && globalContext.user.sexo != "" && (globalContext.user.cpf != "" || globalContext.user.cnpj != "") && globalContext.dtNasc != "" && <div className="form-group col-md-6 space-top text-right">
                        Sou o passageiro?<br />
                        <BootstrapSwitchButton
                          onlabel='Sim'
                          offlabel='Não'
                          checked={e.checked}
                          width={90}
                          onChange={(checked) => {
                            if (checked) {
                              const temp = formPassageiros;
                              temp.passageiros[index].nome = globalContext.user.name;
                              temp.passageiros[index].typeDoc = globalContext.user.cpf ? "CPF" : globalContext.user.cnpj ? "CNPJ" : "RG";
                              temp.passageiros[index].doc = globalContext.user.cpf ? globalContext.user.cpf : globalContext.user.cnpj ? globalContext.user.cnpj : "";
                              temp.passageiros[index].dtNasc = globalContext.user.dtNasc;
                              temp.passageiros[index].sexo = globalContext.user.sexo;
                              temp.passageiros[index].checked = checked;
                              setFormPassageiros(temp);
                              setRefresh(!refresh);
                            } else {
                              const temp = formPassageiros;
                              temp.passageiros[index].nome = "";
                              temp.passageiros[index].typeDoc = "CPF";
                              temp.passageiros[index].doc = "";
                              temp.passageiros[index].dtNasc = "";
                              temp.passageiros[index].sexo = temp.passageiros[index].fixSexo ? temp.passageiros[index].sexo : "M";
                              temp.passageiros[index].checked = checked;
                              temp.passageiros[index].read = false;
                              setFormPassageiros(temp);
                              setRefresh(!refresh);
                            }
                          }}
                        />
                      </div>}

                      {e.checked && <>
                        <div className="form-group col-md-7">
                          <p className="text">Nome completo</p>
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            readOnly
                            disabled={loading}
                            value={e.nome}
                          />
                        </div>

                        <div className="form-group col-md-5">
                          <p className="text">Sexo biológico</p>
                          <select
                            className="form-control form-control-lg"
                            value={e.sexo}
                            disabled="true"
                          >
                            <option value={"M"}>Masculino</option>
                            <option value={"F"}>Feminino</option>
                          </select>
                        </div>

                        <div className="form-group col-md-4">
                          <p className="text">Tipo do Documento</p>
                          <select
                            className="form-control form-control-lg"
                            value={e.typeDoc}
                            disabled="true"
                          >
                            <option value={"RG"}>
                              RG
                            </option>
                            <option value={"CPF"}>CPF</option>
                            <option value={"CNPJ"}>CNPJ</option>
                          </select>
                        </div>

                        <div className="form-group col-md-4">
                          <p className="text">Nro do documento</p>
                          <InputMask
                            type="text"
                            className="form-control form-control-lg"
                            value={e.doc}
                            readOnly
                            mask={maskDoc(e.typeDoc)}
                            disabled="true"
                          />
                        </div>


                        <div className="form-group col-md-4">
                          <p className="text">Data de Nascimento</p>
                          <InputMask
                            type="text"
                            className="form-control"
                            value={e.dtNasc}
                            readOnly
                            mask="99/99/9999"
                          />
                        </div>

                        {formPassageiros.passageiros[index].fixSexo && <div className="form-group col-md-12">
                          <p className="text"><a onClick={() => { openModal() }}>Clique aqui para ler o regulamento</a></p>
                          <ToggleButton
                            className="btn"
                            id="toggle-check"
                            type="checkbox"
                            value="S"
                            active={false}
                            variant="link"
                            disabled={!regulamentoOpened}
                            checked={formPassageiros.passageiros[index].read}
                            onChange={
                              (e) => {
                                const temp = formPassageiros;
                                temp.passageiros[index].read = e.currentTarget.checked;
                                setFormPassageiros(temp);
                                setRefresh(!refresh);
                              }
                            }
                          >&nbsp;Eu li e aceito o regulamento
                          </ToggleButton>
                        </div>}
                      </>}

                      {!e.checked && <>
                        <div className="form-group col-md-7">
                          <p className="text">Nome completo</p>
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            disabled={loading}
                            value={e.nome}
                            onChange={(e) => {
                              const temp = formPassageiros;
                              temp.passageiros[index].nome = e.target.value;
                              setFormPassageiros(temp);
                              setRefresh(!refresh);
                            }}
                          />
                        </div>

                        {formPassageiros.passageiros[index].fixSexo ?
                          <div className="form-group col-md-5">
                            <p className="text">Sexo biológico</p>
                            <select
                              className="form-control form-control-lg"
                              value={e.sexo}
                              disabled="true"
                            >
                              <option value={"M"}>Masculino</option>
                              <option value={"F"}>Feminino</option>
                            </select>
                          </div> :
                          <div className="form-group col-md-5">
                            <p className="text">Sexo biológico</p>
                            <select
                              className="form-control form-control-lg"
                              value={e.sexo}
                              disabled={(loading)}
                              onChange={(e) => {
                                const temp = formPassageiros;
                                temp.passageiros[index].sexo = e.target.value;
                                setFormPassageiros(temp);
                                setRefresh(!refresh);
                              }}
                            >
                              <option value={"M"}>Masculino</option>
                              <option value={"F"}>Feminino</option>
                            </select>
                          </div>}

                        <div className="form-group col-md-4">
                          <p className="text">Tipo do Documento</p>
                          <select
                            className="form-control form-control-lg"
                            value={e.typeDoc}
                            disabled={loading}
                            onChange={(e) => {
                              const temp = formPassageiros;
                              temp.passageiros[index].typeDoc = e.target.value;
                              setFormPassageiros(temp);
                              setRefresh(!refresh);
                            }}
                          >
                            <option value={"RG"}>
                              RG
                            </option>
                            <option value={"CPF"}>CPF</option>
                            <option value={"CNPJ"}>CNPJ</option>
                          </select>
                        </div>

                        <div className="form-group col-md-4">
                          <p className="text">Nro do documento</p>
                          <InputMask
                            type="text"
                            className="form-control form-control-lg"
                            value={e.doc}
                            mask={maskDoc(e.typeDoc)}
                            disabled={loading}
                            onChange={(e) => {
                              const temp = formPassageiros;
                              temp.passageiros[index].doc = e.target.value;
                              setFormPassageiros(temp);
                              setRefresh(!refresh);
                            }}
                          />
                        </div>


                        <div className="form-group col-md-4">
                          <p className="text">Data de Nascimento</p>
                          <InputMask
                            type="text"
                            className="form-control"
                            value={e.dtNasc}
                            mask="99/99/9999"
                            onChange={(e) => {
                              const temp = formPassageiros;
                              temp.passageiros[index].dtNasc = e.target.value;
                              setFormPassageiros(temp);
                              setRefresh(!refresh);
                            }}
                          />
                        </div>

                        {formPassageiros.passageiros[index].fixSexo && <div className="form-group col-md-12">
                          <p className="text"><a onClick={() => { openModal() }}>Clique aqui para ler o regulamento</a></p>
                          <ToggleButton
                            className="btn"
                            id="toggle-check"
                            type="checkbox"
                            value="S"
                            active={false}
                            variant="link"
                            disabled={!regulamentoOpened}
                            checked={formPassageiros.passageiros[index].read}
                            onChange={
                              (e) => {
                                const temp = formPassageiros;
                                temp.passageiros[index].read = e.currentTarget.checked;
                                setFormPassageiros(temp);
                                setRefresh(!refresh);
                              }
                            }
                          >&nbsp;Eu li e aceito o regulamento
                          </ToggleButton>
                        </div>}
                      </>}
                    </div>

                  )
                })}

              {/* <div>
            <h3 className="title">Contato</h3>
            <p className="detail">
              Vamos nos comunicar através dos contatos abaixo
            </p>
            <div className="form-box-input form-wrap">
              <div className="form-group col-md-12 space-top">
                <p className="text">Email</p>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group col-md-2">
                <p className="text">Documento</p>
                <select
                  className="form-control form-control-lg"
                  value={typeDocContact}
                  onChange={(e) => setTypeDocContatc(e.target.value)}
                >
                  <option value={"RG"} selected>
                    RG
                  </option>
                  <option value={"CPF"}>CPF</option>
                  <option value={"CNPJ"}>CNPJ</option>
                </select>
              </div>
              <div className="form-group col-md-4">
                <p className="text"> .</p>
                <InputMask
                  type="text"
                  className="form-control"
                  value={docContact}
                  mask={typeMaskDocContact}
                  onChange={(e) => setDocContact(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <p className="text">Telefone</p>
                <InputMask
                  type="text"
                  className="form-control"
                  value={fone}
                  mask="99 99999-9999"
                  onChange={(e) => setFone(e.target.value)}
                />
              </div>
              <div className="form-group col-md-12">
                <input
                  type="checkbox"
                  checked={checkNewsletter}
                  onChange={() => setCheckNewsletter(!checkNewsletter)}
                ></input>
                <label className="label">
                  {" "}
                  Quero receber ofertas e novidades.
                </label>
              </div>
            </div>
          </div> */}

              {/* <div>
					<h3 className="title">Seguro</h3>
					<div className="form-box-service">
						<div className="flex-start">
							<div>
								<input
									type="checkbox"
									checked={travelInsurance}
									onChange={() => setTravelInsurance(!travelInsurance)}
								></input>
							</div>
							<div className="text left space-left">
								<span className="text"> Quer viajar sem preocupações?</span>
								<br />
								<span className="text">
									Adicione um seguro para seus passageiros
								</span>
							</div>
						</div>

						<div id="hr" className="space-top"></div>

						<div className="form-wrap">
							<div>
								<p className="text">- Viajem tranquilo</p>
								<p className="text">- Preço que cabe no bolso</p>
								<p className="text">- Sua viajem segura de começo ao fim</p>
							</div>
							<div>
								<p className="text">Ver detalhes</p>
							</div>
						</div>

						<div id="hr" className="space-top"></div>

						<div className="form-wrap">
							<div>
								<input
									type="checkbox"
									className="left"
									checked={confirmTravelInsurance}
									onChange={() =>
										setConfirmTravelInsurance(!confirmTravelInsurance)
									}
								></input>
								<span className="text left space-left">
									Sim, quero viajar tranquilo
								</span>
							</div>
							<div>
								<p className="text right">R$9,40</p>
							</div>
						</div>

					</div>
        </div> */}
              {/* <div>
					<h3 className="title">Serviços adicionais</h3>
					<div className="form-box-service">
						<div className="form-wrap">
							<div>
								<input
									type="checkbox"
									className="text left"
									checked={serviceKit}
									onChange={() => setServiceKit(!serviceKit)}
								></input>
								<span className="text left space-left">
									Kit traveseiro + manta
								</span>
							</div>
							<div>
								<p className="text right">R$9,40</p>
							</div>
						</div>

						<div id="hr" className="space-top"></div>

						<div className="form-wrap">
							<div>
								<p className="text">- Viajem tranquilo</p>
								<p className="text">- Preço que cabe no bolso</p>
								<p className="text">- Sua viajem segura de começo ao fim</p>
							</div>
							<div>
								<p className="text">Ver detalhes</p>
							</div>
						</div>

						<div id="hr" className="space-top"></div>

						<div className="form-wrap">
							<div>
								<input
									type="checkbox"
									className="left"
									checked={secondBaggage}
									onChange={() => setSecondBaggage(!secondBaggage)}
								></input>
								<span className="text left space-left"> Segunda bagagem</span>
							</div>
							<div>
								<p className="text right">R$15,90</p>
							</div>
						</div>
					</div>
        </div> */}

              <div>
                <h3 className="title">Dados de pagamento</h3>
                <div className="form-box-payment">
                  <div className="form-wrap">
                    <div className="form-group col-md-12">
                      <p className="text">Nome completo</p>
                      <input
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.fullname}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.fullname = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-3">
                      <p className="text">Documento</p>
                      <select
                        className="form-control form-control-lg"
                        value={formPassageiros.card.typeDocInvoice}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.typeDocInvoice = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      >
                        <option value={"CPF"}>CPF</option>
                        <option value={"CNPJ"}>CNPJ</option>
                      </select>
                    </div>

                    <div className="form-group col-md-5">
                      <p className="text">CPF/CNPJ do titular</p>
                      <InputMask
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.docCard}
                        mask={maskDoc(formPassageiros.card.typeDocInvoice)}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.docCard = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <p className="text">Fone</p>
                      <InputMask
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.invoicePhone}
                        mask="99 99999-9999"
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.invoicePhone = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-8">
                      <p className="text">Email</p>
                      <input
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.invoiceEmail}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.invoiceEmail = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <p className="text">CEP da fatura do cartão</p>
                      <InputMask
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.cepCard}
                        required
                        mask="99.999-999"
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.cepCard = e.target.value;
                          setCEP(e.target.value);
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-9">
                      <p className="text">Endereço</p>
                      <input
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.addressLine1}
                        required
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.addressLine1 = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-3">
                      <p className="text">Nº</p>
                      <input
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.number}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.number = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <p className="text">Cidade</p>
                      <input
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.cityName}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.cityName = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-2">
                      <p className="text">UF</p>
                      <select
                        className="form-control"
                        value={formPassageiros.card.stateAbbreviation}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.stateAbbreviation = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      >
                        <option value="" selected></option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MT">MT</option>
                        <option value="MS">MS</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                      </select>
                    </div>

                    <div className="form-group col-md-6">
                      <p className="text">Bairro</p>
                      <input
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.neighborhood}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.neighborhood = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="title">Dados do cartão</h3>
                <div className="form-box-payment">
                  <div className="form-wrap">
                    <div className="form-group col-md-12">
                      <p className="text">Dados do Cartão de Crédito</p>
                    </div>
                    <div className="form-group col-md-6">
                      <p className="text">Parcele em até 6x comprando com</p>
                    </div>
                    <div className="form-group col-md-6">
                      <PaymentsCards />
                    </div>

                    <div className="form-group col-md-12">
                      <p className="text">Nome impresso no cartão</p>
                      <input
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.nameCard}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.nameCard = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-8">
                      <p className="text textNumberCard">
                        Número do cartão{" "}
                        {card && <img src={card.url.default} alt={card.name} />}
                      </p>
                      <InputMask
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.numberCard}
                        // mask="9999 9999 9999 9999"
                        mask={
                          card && card.name === "amex"
                            ? "9999 999999 99999"
                            : "9999 9999 9999 9999"
                        }
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.numberCard = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                          detectCard(e.target.value);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <p className="text">Validade do cartão</p>
                      <InputMask
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.dateCard}
                        mask="99/99"
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.dateCard = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-5">
                      <p className="text">Código de Segurança(CVV)</p>
                      <InputMask
                        type="text"
                        className="form-control"
                        value={formPassageiros.card.cvvCard}
                        mask={card && card.name === "amex" ? "9999" : "999"}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.cvvCard = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-7">
                      <p className="text">Em quantas parcelas?</p>
                      <select
                        className="form-control"
                        value={formPassageiros.card.qtdPag}
                        disabled={loading}
                        onChange={(e) => {
                          const temp = formPassageiros;
                          temp.card.qtdPag = e.target.value;
                          setFormPassageiros(temp);
                          setRefresh(!refresh);
                        }}
                      >
                        {parcelamento().map((e) => (
                          <option value={e.parcela}>{e.name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-md-12">
                      <p className="text">
                        Clicando em "Comprar" você aceita os termos de uso e
                        política de privacidade
                      </p>
                    </div>

                    <div className="form-group col-md-12">
                      {/* <button
                        className="button lg"
                        disabled={
                          loading || globalContext.contSessionPayment === "00:00m"
                        }
                        onClick={simulateBuy}
                      >
                        {loading ? (
                          <span>
                            <img src={LoadingSvg} /> processando
                          </span>
                        ) : (
                          "Simular compra"
                        )}
                      </button> */}
                      <ReCAPTCHA
                        sitekey="6LeMfyMgAAAAAE2SP-4WjEtg5W_FVTAXsjqO5oAL"
                        onChange={onChange}
                      />
                      <br />
                      <button
                        className="button lg"
                        disabled={
                          loading || globalContext.contSessionPayment === "00:00m"
                        }
                        onClick={buyTicket}
                      >
                        {loading ? (
                          <span>
                            <img src={LoadingSvg} /> processando
                          </span>
                        ) : (
                          "Comprar"
                        )}
                      </button>
                      {globalContext.contSessionPayment === "00:00m" && (
                        <p className="section-expired">
                          Sua sessão expirou, por favor inicie a compra novamente!
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="resume">
              <h3 className="title">Resumo do Pedido</h3>
              {props.transaction.viagem.map((e, index) => (
                <div className="box-destiny">
                  <p className="text">
                    {e.origem.cidade} / {e.destino.cidade}
                  </p>
                  <p className="text">
                    {moment(e.bus.saida, "YYYY-MM-DD HH:mm").format(
                      "dddd DD MMMM [de] YYYY [às] HH:mm"
                    )}
                    h*
                  </p>
                  <small><strong>* Horário de Brasília</strong></small>
                  <div className="showDetalhes">
                    <p className="text left">
                      {props.transaction.assentos[index].length} assentos
                    </p>
                    <p
                      onClick={() => showDetalhe(index)}
                      className="text left button-verDatelhes"
                    >
                      {!showDetails[index].show ? "Ver" : "Esconde"} detalhes
                    </p>
                    {showDetails[index].show && (
                      <div className="showDetalhes">
                        <div className="assentosDetalhes">
                          <h2>Assentos:</h2>
                          {props.transaction.assentos[index].map((ass) => (
                            <p className="text">Poltrona: Nº {ass.number}</p>
                          ))}
                        </div>
                        <div className="horariosDetalhes">
                          <h2>Horários</h2>
                          <p className="text">
                            Horário de saída:{" "}
                            {moment(e.bus.saida, "YYYY-MM-DD HH:mm").format(
                              "HH:mm"
                            )}
                            h
                          </p>
                          <p className="text">
                            Horário de chegada:{" "}
                            {moment(e.bus.chegada, "YYYY-MM-DD HH:mm").format(
                              "HH:mm"
                            )}
                            h
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    <p className="text right">
                      {(
                        e.bus.preco * props.transaction.assentos[index].length
                      ).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </p>
                  </div>
                </div>
              ))}

              {/* <div className="box-discont">
              <div className="form-group col-md-8">
                <input type="text" className="form-control" />
              </div>
              <div className="form-group col-md-4">
                <button className="button">Aplicar</button>
              </div>

              <div id="hr-discount"></div>
              <div className="form-group col-md-12 wrap">
                <div>
                  <p className="text left">Taxa de Serviço</p>
                </div>
                <div>
                  <p className="text right">R$ 14,97</p>
                </div>
              </div>
            </div> */}

              <div className="box-total">
                <div>
                  <h3 className="info">Valor à vista</h3>
                </div>
                <div>
                  <h3 className="total">
                    {valorTotal().toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            style={customStyles}
            contentLabel="Regulamento">
            <center><h4 ref={(_subtitle) => (subtitle = _subtitle)}>PROJETO ESPAÇO DA MULHER</h4></center>
            <p>&nbsp;</p>
            <p>A Rotas de Viação do Triângulo Ltda, empresa de transporte rodoviário de passageiros, na busca pela prestação do melhor serviço e sensível os anseios de seu público feminino, lança o PROJETO ESPAÇO DA MULHER que tem por objetivo garantir uma experiência com maior conforto, proteção, tranquilidade e segurança.</p>
            <p>O projeto consiste na reserva de poltronas destinadas ao uso exclusivo de mulheres que viajam desacompanhadas, identificadas com capas de cor rosa e com a inscrição ESPAÇO DA MULHER.</p>
            <p>Atenta ao compromisso assumido com o Projeto, a Roderotas assegura que mesmo na hipótese de não comparecerem mulheres suficientes para ocupar as poltronas reservadas, elas seguirão vazias até o final da viagem.</p>
            <p><b>REGULAMENTO</b></p>
            <p>Em todas as viagens realizadas nas classes convencional e executiva, serão reservadas 04 (quatro) poltronas de números 9,10,13 e 14 da segunda fileira, identificadas por capas de cor rosa e com a inscrição ESPAÇO DA MULHER para uso exclusivo de mulheres que viajam desacompanhadas.</p>
            <p>Atenta à diversidade de gênero, a Roderotas esclarece que para utilização do ESPAÇO DA MULHER, a identificação das mulheres será realizada através do nome social registrado no documento de identidade, nos termos da Decreto 9.278/2018.</p>
            <p>Os bilhetes de passagem reservados ao ESPAÇO DA MULHER poderão ser adquiridos diretamente no sítio eletrônico da Roderotas https://roderotas.com.br/, ou nos guichês dos terminais rodoviários.</p>
            <p>Em nenhuma hipótese os bilhetes de passagem destinadas ao ESPAÇO DA MULHER poderão ser vendidas a pessoas do sexo masculino.</p>
            <p>No caso de a mulher pretender viajar acompanhada de pessoa do sexo masculino, ainda que seja pai, esposo, filho ou amigo, não poderá ocupar as poltronas exclusivas do ESPAÇO DA MULHER.</p>
            <p>Não haverá qualquer distinção entre os preços das passagens regulares e aquelas destinados ao ESPAÇO DA MULHER.</p>
            <center><Button onClick={closeModal} className="btn-danger">Fechar</Button></center>
          </Modal>
        </Container>
      </>
    )
  );
}
