import React, { useState, useEffect } from "react";
import "./index.scss";
import Moment from "moment";

import logoEstrela from "../../../../assets/img/logoEstrelaColor.svg";
import logoRodeRotas from "../../../../assets/img/logos_rotas.png";
import logoAraguari from "../../../../assets/img/LOGOARAGUARI.jpg";
import logoNacional from "../../../../assets/img/LogoNacionalExpresso.png";
import check from "../../../../assets/img/iconCheck.svg";
import BusMobile from "../bus/index_mobile";

export default function BoxResultMobile(props) {
  const now = Moment().format("YYYY-MM-DD HH:mm");
  const [show, setshow] = useState(true);
  const [showBus, setShowBus] = useState(false);
  const [img, setImg] = useState();

  function diffDate() {
    var startTime = Moment(props.results.saida);
    var endTime = Moment(props.results.chegada);
    var duration = Moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return `${hours}h ${minutes}m`;
  }

  function renderCompany() {
    var company = props.results.empresa;
    let logo = {};
    switch (company) {
      case "RODE ROTAS":
        logo = logoRodeRotas;
        break;
      case "ESTRELA":
        logo = logoEstrela;
        break;
      case "NACIONAL EXPRESSO":
        logo = logoNacional;
        break;
      case "EXPRESSO ARAGUARI":
        logo = logoAraguari;
        break;
      default:
        logo = logoRodeRotas;
    }
    setImg(logo);
  }

  function verifyData() {
    let nowDate = Moment(now, "YYYY-MM-DD HH:mm").add(10, "minutes");
    let propDate = Moment(props.results.saida, "YYYY-MM-DD HH:mm");
    if (nowDate < propDate) {
      setshow(true);
    } else {
      setshow(false);
    }
  }

  useEffect(() => {
    renderCompany();
    verifyData();
  });

  return (
    <>{show && (
      <div class="main__card">
        <div id="ticket__info">
          <div class="up">
            <div class="details">
              <img src={img} alt="" />
            </div>
            <div class="embarque">
              <span>Embarque</span>
              <p>{Moment(props.results.saida).format("HH:mm")}*</p>
              <span>{Moment(props.results.saida).format("DD/MM/YYYY")}</span>
            </div>
            <i class="bx bx-right-arrow-alt" style={{ color: "#595959" }}></i>
            <div class="chegada">
              <span>Chegada</span>
              <p>{Moment(props.results.chegada).format("HH:mm")}*</p>
              <span>{Moment(props.results.chegada).format("DD/MM/YYYY")}</span>
            </div>
          </div>

          <hr />

          <div class="down">
            <div class="time">
              <p>Tempo de viagem</p>
              <span>{diffDate()}</span>
            </div>
            <div class="bus__type">
              <p>Tipo de Ônibus</p>
              <span>{props.results.classe}</span>
            </div>
            <div className="money__info">
              <div className="price">
                {props.results.precoOriginal > props.results.preco && (
                  <div>
                    <span
                      style={{
                        textDecoration: "line-through",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      De:{" "}
                      {props.results.precoOriginal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </span>
                  </div>
                )}{" "}
                <div>
                  <span
                    style={{
                      color: "#06447c",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {props.results.precoOriginal > props.results.preco && (
                      <>Por: </>
                    )}
                    {props.results.preco.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </div>
                {props.results.poltronasLivres > 10 ? (
                  <span>{props.results.poltronasLivres} livres</span>
                ) : (
                  <div className="rest">
                    <i className="bx bxs-hot" style={{ color: "#595959" }}></i>
                    <p>Restam&nbsp;{props.results.poltronasLivres}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        
          <button id="select__btn" onClick={() => {setShowBus(!showBus);}} disabled={!props.results.vende.toLocaleString("pt-BR", {style: "currency",currency: "BRL",})}>
            <p>{showBus ? "Cancelar" : "Selecionar"}</p>
          </button>
          
        </div>
        {showBus && (
          <BusMobile
            changeStep={(value, bus) => props.changeStep(value, bus)}
            canBlock={(sendSelecteds) => props.canBlock(sendSelecteds)}
            data={props.results}
          />
        )}
      </div>
      )}
    </>
  );
}
