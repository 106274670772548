import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Container } from "react-bootstrap";
import Select2 from "react-select2-wrapper";
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Carousel } from "react-responsive-carousel";
import DB from "../../utils/firestore";

import BgBanner from "../../assets/img/bgBanner.png";
import TicketService from "../../services/ticket";
import { useGlobalContext } from "../../context/GlobalContext";
import UTILS from "../../utils/utils";
import StatesConvert from "../mapaRotas/stateUf";
var moment = require("moment");

var formC = {
  idaVolta: "ida",
  dateIda: moment(),
  dateVolta: moment(),
};

var now = new Date();

export default function BannerBusca() {
  const [loadOrigens, setLoadOrigens] = useState(true);
  const [startDate, setStartDate] = useState(now);
  const [endDate, setEndDate] = useState(now);
  const [form, setForm] = useState(formC);
  const [valueOrigem, setValueOrigem] = useState("");
  const [valueDestino, setValueDestino] = useState("");
  const [origens, setOrigens] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [banners, setBanners] = useState([]);

  const history = useHistory();
  const globalContext = useGlobalContext();

  const inputRef = useRef();

  useEffect(() => {
    getOrigens();
    getBanners();
  }, []);

  useEffect(() => {
    if (valueOrigem !== "") getDestinos(valueOrigem);
  }, [valueOrigem]);

  useEffect(() => {
    if (globalContext.locate !== undefined && Array.isArray(origens) && origens.length > 0 && origens !== undefined) {
      let temp = [...origens];
      let temp2 = [...origens];
      let indexLocate = temp.findIndex((value) => value.text == globalContext.locate);
      if (indexLocate != -1) {
        temp2.splice(indexLocate, 1);
        temp2.unshift(temp[indexLocate]);
        setOrigens(temp2);
      }
    }
  }, [globalContext.locate, loadOrigens]);

  function orderAlphabetically(valueDefault = undefined) {

    return (a, b) => {
      //console.log(a.text, valueDefault, a.text == valueDefault);
      if (valueDefault !== undefined) {
        if (valueDefault == a.text) { return -2; }
        if (a.text < b.text) { return -1; }
        if (a.text > b.text) { return 1; }
        return 0;
      } else {
        if (a.text < b.text) { return -1; }
        if (a.text > b.text) { return 1; }
        return 0;
      }
      if (a.text.substr(a.text.indexOf("-") + 2) < b.text.substr(a.text.indexOf("-") + 2)) {
        return -1
        if (a.text < b.text) { return -1; }
        if (a.text > b.text) { return 1; }
      } else if (a.text.substr(a.text.indexOf("-") + 2) > b.text.substr(a.text.indexOf("-") + 2)) {
        return 1;
        if (a.text < b.text) { return -1; }
        if (a.text > b.text) { return 1; }
      }
    };
  }

  async function getOrigens() {
    setLoadOrigens(true);
    try {
      const response = await TicketService.BuscaOrigem();
      if (response && response.data) {
        const data = [];
        response.data.map((e) => data.push({ text: `${e.cidade}`, id: e.id }));
        data.sort(orderAlphabetically());
        setOrigens(data);
        inputRef.click();
      }
      setLoadOrigens(false);
    } catch (err) {
      setLoadOrigens(false);
      // seterrorService(true);
    }
  }
  async function getBanners() {
    try {
      const response = await DB.get("image_carroseel");
      setBanners(response);
    } catch (err) {
      // seterrorService(true);
    }
  }
  async function getDestinos(idOrigem) {
    setValueDestino(0);
    try {
      const response = await TicketService.BuscaDestino(idOrigem);
      if (response && response.data) {
        const data = [];
        response.data.map((e) => data.push({ text: `${e.cidade}`, id: e.id }));
        data.sort(orderAlphabetically());
        setDestinos(data);
      }
    } catch (err) {
      // seterrorService(true);
    }
  }

  function verificaEndDate(dateStart) {
    const initDateFormat = moment(dateStart).format("yyyyMMDD");
    const endDateFormat = moment(endDate).format("yyyyMMDD");
    if (initDateFormat > endDateFormat) setEndDate(dateStart);
  }

  function goSearch() {
    globalContext.setcontSessionPayment("");
    if (valueOrigem === "") {
      toast.info("Informe a origem de sua viagem!");
      return;
    }
    if (valueDestino === "") {
      toast.info("Informe o destino de sua viagem!");
      return;
    }
    const nameOrigem = origens
      .filter((e) => e.id === JSON.parse(valueOrigem))[0]
      .text.replace(/\s/g, "")
      .replace(/-/g, "");
    const nameDestino = destinos
      .filter((e) => e.id === JSON.parse(valueDestino))[0]
      .text.replace(/\s/g, "")
      .replace(/-/g, "");
    const data =
      form.idaVolta === "idaVolta"
        ? `${moment(startDate).format("DDMMYYYY")}-${moment(endDate).format(
          "DDMMYYYY"
        )}`
        : moment(startDate).format("DDMMYYYY");
    localStorage.removeItem("sessionPayment");
    localStorage.removeItem("step");
    localStorage.removeItem("transaction");
    globalContext.setsessionPayment(false);
    globalContext.setStopCount(!globalContext.stopCount);
    setTimeout(() => {
      history.push(
        `/passagens/${nameOrigem}-${valueOrigem}/${nameDestino}-${valueDestino}/${form.idaVolta}/${data}`
      );
    }, 800);
  }

  function renderBanners() {
    return banners.map((e) => (
      <div>
        <img src={e.url} alt={e.name} />
      </div>
    ));
  }

  function thisFocus() {
    setTimeout(() => {
      if (document.getElementsByClassName("select2-search__field") != undefined && document.getElementsByClassName("select2-search__field").length > 0) {
        document.getElementsByClassName("select2-search__field")[0].focus();
      }
    }, 100);
  }

  return (
    <>
      {/* <section className="bannerHome" style={{ background: "url('" + BgBanner + "')" }}> */}
      <section className="bannerHome" style={{ background: "#a5a5a5" }}>
        <Container>
          <div className="formBusca">
            <h2>Compre sua passagem</h2>
            <div className="boxform">
              <div className="cardForm" style={{ width: "100vp" }}>
                <label>Viajar de:</label>
                <Select2
                  language="pt-BR"
                  data={origens}
                  options={{
                    placeholder: "Digite sua origem",
                  }}
                  value={valueOrigem}
                  ref={inputRef}
                  onChange={(e) => setValueOrigem(e.target.value)}
                  onOpen={thisFocus}
                />
              </div>
              <div className="cardForm" style={{ width: "100vp" }}>
                <label>Viajar para:</label>
                <Select2
                  data={destinos}
                  options={{
                    placeholder: "Digite seu destino"
                  }}
                  value={valueDestino}
                  onChange={(e) => setValueDestino(e.target.value)}
                  onOpen={thisFocus}
                />
              </div>
              <div className="cardForm cardFormNoBoard">
                <div className="ckeck">
                  <input
                    type="radio"
                    id="ida"
                    value="ida"
                    name="typeCompra"
                    checked={form.idaVolta === "ida"}
                    onChange={(e) =>
                      setForm({ ...form, idaVolta: e.target.value })
                    }
                  />
                  <label htmlFor="ida">Somente ida</label>
                </div>
                <div className="ckeck">
                  <input
                    type="radio"
                    id="idaVolta"
                    value="idaVolta"
                    name="typeCompra"
                    checked={form.idaVolta === "idaVolta"}
                    onChange={(e) => setForm({ ...form, idaVolta: "idaVolta" })}
                  />
                  <label htmlFor="idaVolta">Ida e Volta</label>
                </div>
              </div>
              <div className="cardForm cardFormDate">
                <DatePicker
                  selected={startDate}
                  dateFormat="dd/MM/yyyy"
                  minDate={now}
                  locale={pt}
                  onChange={(date) => (
                    setStartDate(date), verificaEndDate(date)
                  )}
                />
                {form.idaVolta !== "ida" && (
                  <DatePicker
                    selected={endDate}
                    dateFormat="dd/MM/yyyy"
                    minDate={startDate}
                    disabled={form.idaVolta === "ida"}
                    locale={pt}
                    onChange={(date) => setEndDate(date)}
                  />
                )}
              </div>
              <button onClick={goSearch} className="btnBuscarPassagem">
                Buscar passagem
              </button>
            </div>
          </div>
        </Container>
        {banners.length && (
          <Carousel
            dynamicHeight={false}
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={false}
            transitionTime={700}
            interval={8000}
          >
            {renderBanners()}
          </Carousel>
        )}
      </section>
    </>
  );
}
