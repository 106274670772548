(function (factory) {
  // eslint-disable-next-line no-undef
  if (typeof define === 'function' && define.amd) {
      // eslint-disable-next-line no-undef
      define(['moment'], factory); // AMD
  } else if (typeof exports === 'object') {
      module.exports = factory(require('../moment')); // Node
  } else {
      factory(window.moment); // Browser global
  }
}(function (moment) {
  return moment.defineLocale('pt-br', {
      months : 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_'),
      monthsShort : 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_'),
      weekdays : 'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split('_'),
      weekdaysShort : 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
      weekdaysMin : 'Dom_2ª_3ª_4ª_5ª_6ª_Sáb'.split('_'),
      longDateFormat : {
          LT : 'HH:mm',
          L : 'DD/MM/YYYY',
          LL : 'D [de] MMMM [de] YYYY',
          LLL : 'D [de] MMMM [de] YYYY [às] LT',
          LLLL : 'dddd, D [de] MMMM [de] YYYY [às] LT'
      },
      calendar : {
          sameDay: '[Hoje às] LT',
          nextDay: '[Amanhã às] LT',
          nextWeek: 'dddd [às] LT',
          lastDay: '[Ontem às] LT',
          lastWeek: function () {
              return (this.day() === 0 || this.day() === 6) ?
                  '[Último] dddd [às] LT' : // Saturday + Sunday
                  '[Última] dddd [às] LT'; // Monday - Friday
          },
          sameElse: 'L'
      },
      relativeTime : {
          future : 'em %s',
          past : '%s atrás',
          s : 'segundos',
          m : 'um minuto',
          mm : '%d minutos',
          h : 'uma hora',
          hh : '%d horas',
          d : 'um dia',
          dd : '%d dias',
          M : 'um mês',
          MM : '%d meses',
          y : 'um ano',
          yy : '%d anos'
      },
      ordinal : '%dº'
  });
}));