import React from "react";
import {ReactComponent as Visa} from "../../assets/img/visa.svg";
import {ReactComponent as MasterCard} from "../../assets/img/mastercard.svg";
import {ReactComponent as Diners} from "../../assets/img/diners.svg";
import {ReactComponent as Express} from "../../assets/img/express.svg";
import "./index.scss";

export default function PaymentsCards (){
    return(
        
        <div className="box-cards">
            <Visa className="logos"/>
            <MasterCard className="logos"/>
            <Diners className="logos"/>
            <Express className="logos"/>
        </div>
    
)
}