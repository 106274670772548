/* eslint-disable import/no-anonymous-default-export */
export default [
  { selected: false, x: 0, y: 0, number: 1},
  { selected: false, x: 0, y: 1, number: 2},
  { selected: false, x: 0, y: 2, number: 3},
  { selected: false, x: 0, y: 3, number: 4},
  { selected: false, x: 0, y: 4, number: 5},
  { selected: false, x: 1, y: 0, number: 6},
  { selected: false, x: 1, y: 1, number: 7},
  { selected: false, x: 1, y: 2, number: 8},
  { selected: false, x: 1, y: 3, number: 9},
  { selected: false, x: 1, y: 4, number: 10},
  { selected: false, x: 2, y: 0, number: 11 },
  { selected: false, x: 2, y: 1, number: 12 },
  { selected: false, x: 2, y: 2, number: 13 },
  { selected: false, x: 2, y: 3, number: 14 },
  { selected: false, x: 2, y: 4, number: 15 },
  { selected: false, x: 3, y: 0, number: 16 },
  { selected: false, x: 3, y: 1, number: 17 },
  { selected: false, x: 3, y: 2, number: 18 },
  { selected: false, x: 3, y: 3, number: 19 },
  { selected: false, x: 3, y: 4, number: 20 },
  { selected: false, x: 4, y: 0, number: 21 },
  { selected: false, x: 4, y: 1, number: 22 },
  { selected: false, x: 4, y: 2, number: 23 },
  { selected: false, x: 4, y: 3, number: 24 },
  { selected: false, x: 4, y: 4, number: 25 },
  { selected: false, x: 5, y: 0, number: 26 },
  { selected: false, x: 5, y: 1, number: 27 },
  { selected: false, x: 5, y: 2, number: 28 },
  { selected: false, x: 5, y: 3, number: 29 },
  { selected: false, x: 5, y: 4, number: 30 },
  { selected: false, x: 6, y: 0, number: 31 },
  { selected: false, x: 6, y: 1, number: 32 },
  { selected: false, x: 6, y: 2, number: 33 },
  { selected: false, x: 6, y: 3, number: 34 },
  { selected: false, x: 6, y: 4, number: 35 },
  { selected: false, x: 7, y: 0, number: 36 },
  { selected: false, x: 7, y: 1, number: 37 },
  { selected: false, x: 7, y: 2, number: 38 },
  { selected: false, x: 7, y: 3, number: 39 },
  { selected: false, x: 7, y: 4, number: 40 },
  { selected: false, x: 8, y: 0, number: 41 },
  { selected: false, x: 8, y: 1, number: 42 },
  { selected: false, x: 8, y: 2, number: 43 },
  { selected: false, x: 8, y: 3, number: 44 },
  { selected: false, x: 8, y: 4, number: 45 },
  { selected: false, x: 9, y: 0, number: 46 },
  { selected: false, x: 9, y: 1, number: 47 },
  { selected: false, x: 9, y: 2, number: 48 },
  { selected: false, x: 9, y: 3, number: 49 },
  { selected: false, x: 9, y: 4, number: 50 },
  { selected: false, x: 10, y: 0, number: 51 },
  { selected: false, x: 10, y: 1, number: 52 },
  { selected: false, x: 10, y: 2, number: 53 },
  { selected: false, x: 10, y: 3, number: 54 },
  { selected: false, x: 10, y: 4, number: 55 },
  { selected: false, x: 11, y: 0, number: 56 },
  { selected: false, x: 11, y: 1, number: 57 },
  { selected: false, x: 11, y: 2, number: 58 },
  { selected: false, x: 11, y: 3, number: 59 },
  { selected: false, x: 11, y: 4, number: 60 },
];
