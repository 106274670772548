import React, { useState, useEffect } from "react";
import moment from "moment";
import "./index.scss";
import { Container } from "react-bootstrap";
import DB from "../../utils/firestore";
import { useGlobalContext } from "../../context/GlobalContext";

export default function KmRodados() {
  const globalContext = useGlobalContext();
  const [km, setKm] = useState(0);
  const [kmAdd, setKmAdd] = useState(0);
  const [client, setClient] = useState(0);
  const [clientAdd, setClientAdd] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let totals = await DB.getTotal();
    await setKm(parseFloat(totals.km));
    await setKmAdd(parseFloat(totals.km_add));
    await setClient(parseFloat(totals.client));
    await setClientAdd(parseFloat(totals.client_add));

    setInterval(async() => {
      totals.km = parseFloat(totals.km) + parseFloat(totals.km_add);
      await setKm(totals.km.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
      totals.client = parseFloat(totals.client) + parseFloat(totals.client_add);
      await setClient(totals.client.toLocaleString('pt-BR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }));
    }, 1000);
  }


  function createMarkup(data) {
    return {__html: data};
  }; 

  return (
    <section className="kmRodados">
      <Container>
        <div className="boxKmRodados">
          <div className="left">
            <div className="box-value">
              <p className="km">{km}</p>
              <span>Km rodados em {moment().format("YYYY")}</span>
            </div>
            <div className="box-value">
              <p className="km">{client}</p>
              <span>Clientes atendidos em {moment().format("YYYY")}</span>
            </div>
          </div>
          <div className="right">
            <h2>Quem somos?</h2>
            <div className="whoWe">
              {globalContext.configs && globalContext.configs[0] && 
                <div dangerouslySetInnerHTML={createMarkup(globalContext.configs[0].who_we)} />
              }
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
