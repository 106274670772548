import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import "./index.scss";

import Loading from "../../assets/img/loadingWhite.svg";
import Google from "../../assets/img/btn_google_light_normal_ios.svg";
import iconFacebook from "../../assets/img/iconFacebook.svg";
import {
  auth,
  authFacebook,
  googleAuthProvider,
  Firestore,
} from "../../utils/firebaseUtils";
import { useHistory } from "react-router";
import { useGlobalContext } from "../../context/GlobalContext";
import UTILS from "../../utils/utils";
import InputMask from "react-input-mask";

export default function LoginPage(props) {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [cpf, setcpf] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [fone, setfone] = useState("");
  const [existCPF, setexistCPF] = useState();
  const [existEmail, setexistEmail] = useState();
  const [validCPF, setvalidCPF] = useState(false);
  const [password, setpassword] = useState("");
  const [repetpassword, setrepetpassword] = useState("");
  const [loadingSignIn, setloadingSignIn] = useState(false);

  const history = useHistory();
  const globalContext = useGlobalContext();

  function login(e) {
    e.preventDefault();
    setloadingSignIn(true);
    if (email === "") {
      toast.error("Digite o seu e-mail!");
      setloadingSignIn(false);
      return false;
    }
    if (password === "") {
      toast.error("Digite sua senha!");
      setloadingSignIn(false);
      return false;
    }
    auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        toast.success("login efetuado com sucesso!");
        setloadingSignIn(false);
        if(props.location.pathname == "/login/back"){          
          return props.history.goBack();
        }
        if (!props.noRedirect) {
          history.push("/");
        }
        if(typeof props.onLogin === "function"){
          props.onLogin();
        }
      })
      .catch((error) => {
        toast.error("Não foi possível efetuar login!");
        setloadingSignIn(false);
      });
  }

  useEffect(() => {
    if (globalContext.user && !props.noRedirect) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setcpf("");
    setemail("");
    setfone("");
    setname("");
    setpassword("");
    setrepetpassword("");
  }, [step]);

  // Check if CPF ever exists
  useEffect(() => {
    if (UTILS.validateCPF(cpf) && cpf.replace(/[._-]/g, "").length == 11 && step === 2) {
      setErrors({ ...errors, cpf: "" });
      setvalidCPF(true);
      var users = Firestore.collection("users");
      var query = users.where("cpf", "==", cpf);
      query.get().then((result) => {
        if (!result.empty) {
          setexistCPF(true);
          toast.error("CPF já está cadastrado!");
          setErrors({ ...errors, cpf: "CPF já está cadastrado" });
        } else {
          setexistCPF(false);
          setErrors({ ...errors, cpf: "" });
          document.getElementById('name').focus();
        }
      });
    } else {
      setvalidCPF(false);
      setexistCPF();
      if (cpf.replace(/[._-]/g, "").length == 11) {
        setErrors({ ...errors, cpf: "CPF não é válido" });
        toast.error("CPF não é válido!");
      }
    }
  }, [cpf]);

  // Check if EMAIL ever exists
  useEffect(() => {
    if (UTILS.validateMail(email) && step === 2) {
      var users = Firestore.collection("users");
      var query = users.where("email", "==", email);
      query.get().then((result) => {
        if (!result.empty) {
          setexistEmail(true);
          toast.error("E-mail já está em uso!");
          setErrors({ ...errors, email: "E-mail já está em uso" });
        } else {
          setexistEmail(false);
          setErrors({ ...errors, email: "" });
        }
      });
    }
  }, [email]);

  async function LoginFacebook(e) {
    e.preventDefault();
    setloadingSignIn(true);
    var provider = await new authFacebook();
    provider.setCustomParameters({
      display: "popup",
    });
    auth()
      .signInWithPopup(provider)
      .then(async function (result) {
        var user = result.user;
        const params = {
          name: user.displayName,
          email: user.email,
          profile: "cliente",
          disabled: false,
        };
        if (!user.emailVerified) {
          sendSignInLinkToEmail(user.email);
        }
        Firestore.collection("users").doc(result.user.uid).set(params);
        if (!props.noRedirect) {
          history.push("/");
        }
      })
      .catch(function (error) {
        if (error.message === "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.") {
          toast.error("Esse e-mail já está cadastrado!");
        } else {
          toast.error("Não foi possível acessar, tente novamente!");

        }
        setloadingSignIn(false);
      });
  }
  async function LoginGoogle(e) {
    e.preventDefault();
    setloadingSignIn(true);
    var provider = await new googleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

    auth()
      .signInWithPopup(provider)
      .then(function (result) {
        var user = result.user;
        const params = {
          name: user.displayName,
          email: user.email,
          profile: "cliente",
          disabled: false,
        };
        Firestore.collection("users").doc(result.user.uid).set(params);
        if (!props.noRedirect) {
          history.push("/");
        }
      })
      .catch(function (error) {
        toast.error("Não foi possível acessar, tente novamente!");
        setloadingSignIn(false);
      });
  }

  useEffect(() => {
    setErrors({});
  }, [cpf, name, email, fone, password, repetpassword]);

  function cadastrar(e) {
    e.preventDefault();
    setloadingSignIn(true);

    if (cpf === "") {
      toast.error("Digite o seu CPF!");
      setloadingSignIn(false);
      setErrors({ ...errors, cpf: "Informe seu CPF" });
      return false;
    }
    if (existCPF) {
      toast.error("CPF já está cadastrado!");
      setloadingSignIn(false);
      setErrors({ ...errors, cpf: "CPF já está cadastrado" });
      return false;
    }
    if (!validCPF) {
      toast.error("CPF não é válido!");
      setloadingSignIn(false);
      setErrors({ ...errors, cpf: "CPF não é válido" });
      return false;
    }
    if (name === "") {
      toast.error("Digite o seu Nome!");
      setloadingSignIn(false);
      setErrors({ ...errors, name: "Informe seu nome" });
      return false;
    }
    if (email === "") {
      toast.error("Digite o seu e-mail!");
      setloadingSignIn(false);
      setErrors({ ...errors, email: "Informe seu e-mail" });
      return false;
    }
    if (!UTILS.validateMail(email)) {
      toast.error("Digite um e-mail válido!");
      setloadingSignIn(false);
      setErrors({ ...errors, email: "Informe um e-mail válido" });
      return false;
    }
    if (typeof existEmail === "boolean" && existEmail) {
      toast.error("E-mail já está em uso!");
      setloadingSignIn(false);
      setErrors({ ...errors, email: "E-mail já está em uso" });
      return false;
    }
    if (fone === "") {
      toast.error("Digite o seu Telefone celular!");
      setloadingSignIn(false);
      setErrors({ ...errors, fone: "Informe seu Telefone celular" });
      return false;
    }
    if (!UTILS.validatePhone(fone)) {
      toast.error("Telefone celular informado não é válido!");
      setloadingSignIn(false);
      setErrors({ ...errors, fone: "Telefone celular não é válido" });
      return false;
    }
    if (password === "") {
      toast.error("Digite sua senha!");
      setloadingSignIn(false);
      setErrors({ ...errors, password: "Informe sua senha" });
      return false;
    }
    if (password !== repetpassword) {
      toast.error("Senhas não são iguais!");
      setloadingSignIn(false);
      setErrors({ ...errors, repetpassword: "Senhas não são iguais" });
      return false;
    }

    if (password.length < 6) {
      toast.error("Sua senha deve conter ao menos 6 caracteres!");
      setloadingSignIn(false);
      setErrors({ ...errors, password: "Sua senha deve conter ao menos 6 caracteres" });
      return false;
    }

    auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        // toast.success("Cadastrado com sucesso!");
        setloadingSignIn(false);
        const params = {
          name: name,
          email: email,
          cpf: cpf,
          fone: fone,
          profile: "cliente",
          disabled: false,
        };
        await Firestore.collection("users").doc(result.user.uid).set(params);
        if (!props.noRedirect) {
          history.push("/");
        }
      })
      .catch(async (error) => {
        const params = {
          date: new Date(),
          code: error.code,
          message: error.message
        }
        await Firestore.collection("log_register_error").add(params);
        toast.error("Não foi possível cadastrar Usuário!");
        setloadingSignIn(false);
      });
  }

  function renderBtnRedes(invert = false) {
    return (
      <div className="loginRedes">
        {!invert && (
          <>
            <hr />
            <div className="divisor">Ou</div>
          </>
        )}
        <button className="facebook" onClick={LoginFacebook}>
          <img src={iconFacebook} alt="" /> Continue com Facebook
        </button>
        {/* <button className="google" onClick={LoginGoogle}>
          <img src={Google} alt="" /> Continue com Google
        </button> */}
        {invert && (
          <>
            <hr />
            <div className="divisor">Ou</div>
          </>
        )}
      </div>
    );
  }

  async function recuperar(e) {
    e.preventDefault();

    setloadingSignIn(true);
    // debugger;
    await auth()
      .sendPasswordResetEmail(email)
      .then((result) => {
        toast.success("E-mail de recuperação enviado com sucesso!");
        setloadingSignIn(false);
        setStep(1);
      })
      .catch(() => {
        // debugger;
        toast.error("Não foi possível recuperar!");
        setloadingSignIn(false);
      });
  }

  async function sendSignInLinkToEmail(email) {
    auth().sendSignInLinkToEmail(email, { url: 'https://rotas-297b8.web.app/', handleCodeInApp: true })
      .then(() => {
        // window.localStorage.setItem("emailForSignIn", email);
        console.log('email de verificação enviado');
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  return (
    <Container>
      {step === 1 && (
        <div className="formLogin">
          <h2>Acesse sua conta</h2>
          <form onSubmit={login}>
            <div className="box-form">
              <label htmlFor="email">E-mail</label>
              <input
                value={email}
                onChange={(event) => setemail(event.target.value)}
                placeholder="Digite seu e-mail"
                type="text"
              />
            </div>
            <div className="box-form">
              <div className="duoLabel">
                <label htmlFor="email">Senha</label>
                {/* <Link to="/recoverPass">Esqueci minha senha</Link> */}
              </div>
              <input
                value={password}
                onChange={(event) => setpassword(event.target.value)}
                placeholder="Digite sua senha"
                type="password"
              />
            </div>
            <div className="btnForm">
              <button type="submit" disabled={loadingSignIn}>
                {loadingSignIn ? (
                  <img src={Loading} className="loadingButton" />
                ) : (
                  <span>Entrar</span>
                )}
              </button>
            </div>
            <div className="actions">
              <span>
                Não tem conta?{" "}
                <a onClick={() => setStep(2)}>Cadastre-se aqui</a>
              </span>
              <span>
                Esqueceu a senha?{" "}
                <a onClick={() => setStep(3)}>Recuperar aqui</a>
              </span>
            </div>
            {renderBtnRedes()}
          </form>
        </div>
      )}
      {step === 2 && (
        <div className="formLogin">
          <h2>Cadastrar-se</h2>
          <form onSubmit={cadastrar}>
            {renderBtnRedes(true)}
            <div className="box-form">
              <label htmlFor="cpf">CPF</label>
              <InputMask
                value={cpf}
                mask="999.999.999-99"
                onChange={(event) => setcpf(event.target.value)}
                placeholder="Digite seu CPF"
                className={errors['cpf'] != undefined && errors['cpf'] ? "error" : (errors['cpf'] == undefined ? "" : "nice")}
                type="text"
                onSubmit={(event) => event.preventDefault()}
              />
              <small>{errors['cpf']}</small>
            </div>
            <div className="box-form" hidden={existCPF || typeof existCPF != "boolean"}>
              <label htmlFor="email">Nome</label>
              <input
                id="name"
                value={name}
                onChange={(event) => setname(event.target.value)}
                placeholder="Digite seu Nome"
                className={errors['name'] != undefined && errors['name'] ? "error" != "" : (errors['name'] == undefined ? "" : "nice")}
                type="text"
              />
              <small>{errors['name']}</small>
            </div>
            <div className="box-form" hidden={existCPF || typeof existCPF != "boolean"}>
              <label htmlFor="fone">Telefone celular</label>
              <InputMask
                mask="(99)9 9999-9999"
                value={fone}
                onChange={(event) => setfone(event.target.value)}
                placeholder="Digite seu telefone celular"
                className={errors['fone'] != undefined && errors['fone'] != "" ? "error" : (errors['fone'] == undefined ? "" : "nice")}
                type="text"
              />
              <small>{errors['fone']}</small>
            </div>
            <div className="box-form" hidden={existCPF || typeof existCPF != "boolean"}>
              <label htmlFor="email">E-mail</label>
              <input
                value={email}
                onChange={(event) => setemail(event.target.value)}
                placeholder="Digite seu e-mail"
                className={errors['email'] != undefined && errors['email'] != "" ? "error" : (errors['email'] == undefined ? "" : "nice")}
                type="text"
              />
              <small>{errors['email']}</small>
            </div>
            <div className="box-form" hidden={existCPF || typeof existCPF != "boolean"}>
              <label htmlFor="email">Insira sua senha</label>
              <input
                value={password}
                onChange={(event) => setpassword(event.target.value)}
                placeholder="Digite sua senha"
                className={((errors['password'] != undefined && errors['password'] != "") || (errors['repetpassword'] != undefined && errors['repetpassword'] != "")) ? "error" : ((errors['password'] == undefined || errors['repetpassword'] == undefined) ? "" : "nice")}
                type="password"
              />
              <small>{errors['password']}</small>
            </div>
            <div className="box-form" hidden={existCPF || typeof existCPF != "boolean"}>
              <label htmlFor="email">Repita sua senha</label>
              <input
                value={repetpassword}
                onChange={(event) => setrepetpassword(event.target.value)}
                placeholder="Digite sua senha"
                className={errors['repetpassword'] != undefined && errors['repetpassword'] != "" ? "error" : (errors['repetpassword'] == undefined ? "" : "nice")}
                type="password"
              />
              <small>{errors['repetpassword']}</small>
            </div>
            <div className="btnForm" hidden={existCPF || typeof existCPF != "boolean"}>
              <button type="submit" disabled={loadingSignIn}>
                {loadingSignIn ? (
                  <img
                    src={Loading}
                    className="loadingButton"
                    alt="loadingButton"
                  />
                ) : (
                  <span>Cadastrar-se</span>
                )}
              </button>
            </div>
            {(typeof existCPF !== "boolean" || existCPF) &&
              <div className="btnForm">
                <button type="submit" disabled={loadingSignIn}>
                  {loadingSignIn ? (
                    <img
                      src={Loading}
                      className="loadingButton"
                      alt="loadingButton"
                    />
                  ) : (
                    <span>Verificar</span>
                  )}
                </button>
              </div>
            }
            <div className="actions">
              {(validCPF && existCPF) && (
                <span>
                  Esqueceu a senha?{" "}
                  <a onClick={() => setStep(3)}>Recuperar aqui</a>
                </span>
              )}
              <span>
                Já tem conta? <a onClick={() => setStep(1)}>Entrar</a>
              </span>
            </div>
          </form>
        </div>
      )}
      {step === 3 && (
        <div className="formLogin">
          <h2>Recuperar Senha</h2>
          <form onSubmit={recuperar}>
            <div className="box-form">
              <label htmlFor="email">E-mail</label>
              <input
                value={email}
                onChange={(event) => setemail(event.target.value)}
                placeholder="Digite seu e-mail"
                type="text"
              />
            </div>
            <div className="btnForm">
              <button type="submit" disabled={loadingSignIn}>
                {loadingSignIn ? (
                  <img src={Loading} className="loadingButton" />
                ) : (
                  <span>Recuperar</span>
                )}
              </button>
            </div>
            <div className="actions">
              <span>
                Não tem conta?{" "}
                <a onClick={() => setStep(2)}>Cadastre-se aqui</a>
              </span>
              <span>
                Esqueceu a senha?{" "}
                <a onClick={() => setStep(3)}>Recuperar aqui</a>
              </span>
            </div>
            {renderBtnRedes()}
          </form>
        </div>
      )}
    </Container>
  );
}
