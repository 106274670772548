import React from "react";
import Footer from "../../components/footer/footer"
import "./index.scss";

export default function PageNotFound() {
  return (
    <>
    <div className="naoEncontrada">
      <h2>Página não encontrada</h2>
      <p>A página acessada não foi encontrada.</p>
      <button>Ir para home</button>
    </div>
    <Footer />
    </>
  );
}
