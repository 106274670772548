import React, { useState, useEffect } from "react";
import "./index.scss";
import Moment from "moment";
import Bus from "../bus";

import logoEstrela from "../../../../assets/img/logoEstrelaColor.svg";
import logoRodeRotas from "../../../../assets/img/logoRodeRotasColor.svg";
import logoAraguari from "../../../../assets/img/LOGOARAGUARI.jpg";
import logoNacional from "../../../../assets/img/LogoNacionalExpresso.png";
import check from "../../../../assets/img/iconCheck.svg";

export default function BoxResult(props) {

  const now = Moment().format('YYYY-MM-DD HH:mm')
  const [show, setshow] = useState(true);
  const [showBus, setShowBus] = useState(false);
  const [img, setImg] = useState();
  function diffDate() {
    var startTime = Moment(props.results.saida);
    var endTime = Moment(props.results.chegada);
    var duration = Moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return `${hours}h ${minutes}m`;
  }

  function renderCompany() {
    var company = props.results.empresa;
    let logo = {};
    switch (company) {
      case 'RODE ROTAS':
        logo = logoRodeRotas;
        break;
      case 'ESTRELA':
        logo = logoEstrela;
        break;
      case 'VIACAO ESTRELA':
        logo = logoEstrela;
        break;
      case 'NACIONAL EXPRESSO':
        logo = logoNacional;
        break;
      case 'EXPRESSO ARAGUARI':
        logo = logoAraguari;
        break;
      default:
        logo = logoRodeRotas;
    }
    setImg(logo);
  }

  function verifyData(){
    let nowDate   = Moment(now, "YYYY-MM-DD HH:mm").add(10, "minutes");
    let propDate  = Moment(props.results.saida, "YYYY-MM-DD HH:mm");
    if (nowDate < propDate) {
      setshow(true)
    } else {
      setshow(false)
    }
  }

  useEffect(() => {
    renderCompany();
    verifyData();
  });
  

  return (
    <>{show && (
    <div className="result-results-box">
      <div className="result-results-box-align">
        <div className="company">
          <img
            src={img}
            height="40"
          />
        </div>
        <div className="hour">
          <div>
            <time className="departure-time">
              {Moment(props.results.saida).format("HH:mm")}*
            </time>
          </div>
          <div className="return-time">
            <time>{Moment(props.results.chegada).format("HH:mm")}*</time>
          </div>
        </div>

        <div className="bus_stations">
          <div>
            <p className="station-departure">{props.data.origem.cidade}</p>
          </div>
          <div>
            <p className="station-arrival">{props.data.destino.cidade}</p>
          </div>
        </div>
        <div className="duration">
          <time>{diffDate()}</time>
        </div>
        <div className="service_classe">
          <span>{props.results.classe}</span>
        </div>

        <div className="price">
          {props.results.precoOriginal > props.results.preco && (
            <div>
              <span className="old-price">
                De:{" "}
                {props.results.precoOriginal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
          )}{" "}
          <div className="price_value">
            <span>
              {props.results.precoOriginal > props.results.preco && <>Por: </>}
              {props.results.preco.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </div>
        </div>
        <div className="selecionar">
          <button
            onClick={() => {
              setShowBus(!showBus);
            }}
            disabled={
              !props.results.vende.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }
            className={`btn btn-sm pull-right ${showBus && "btn-cancell"}`}
          >
            {showBus ? "Cancelar" : "Selecionar"}
          </button>
        </div>
      </div>
      <div className="footer">
        <hr size="6" width="96%"></hr>
        <div className="col-md-6">
          <div className="col-md-6 option">
            <img src={check} alt="check" />
            Passagem no celular
          </div>
          <div className="col-md-6 option">
            <img src={check} alt="check" />
            Segurança reforçada{" "}
          </div>
        </div>
        <div className="col-md-6 pontronasLivres">
          <div className="item">
            <p>Poltronas Livres: {props.results.poltronasLivres}</p>
          </div>
        </div>
        {/* <div className="col-md-4 compare">
          <div className="col-md-3 photo-one">
            <img src="https://static.clickbus.com/live/travel-company-logos/real-expresso.svg" />
          </div>
          <div className="col-md-3 photo-one">
            <label>R$54,90</label>
          </div>
          <div className="col-md-3 photo">
            <img src="https://static.clickbus.com/live/travel-company-logos/rapido-federal.svg" />
          </div>
          <div className="col-md-3 photo">
            <label>R$74,90</label>
          </div>
        </div> */}
      </div>
      {showBus && (
        <Bus
          changeStep={(value, bus) => props.changeStep(value, bus)}
          canBlock={(sendSelecteds) => props.canBlock(sendSelecteds)}
          data={props.results}
        />
      )}
    </div>
  )}
  </>
  );

}
