import React, { useEffect, useState } from "react";
import "./index.scss";
import { Container } from "react-bootstrap";
import TicketService from "../../../../services/ticket";
import Loading from "../../../../assets/img/loading.svg";
import Image01 from "../../../../assets/img/rodeRotas.jpeg";

export default function OtherResults(props) {
  const orig = props.data.origem.id;
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    getDestinos();
  }, []);

  async function getDestinos() {
    try {
      const response = await TicketService.BuscaDestino(orig);
      if (response && response.data) {
        const data = [];
        response.data.map((e) => data.push({ text: `${e.cidade}`, id: e.id }));
        shuffleArray(data);
      }
    } catch (err) {

    }
  }

  async function shuffleArray(data) {
    if (data.length) {
      const img = [
        { url: 'https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_busca_url_1.jpeg?alt=media' },
        { url: 'https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_busca_url_2.jpeg?alt=media' },
        { url: 'https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_busca_url_3.jpeg?alt=media' },
        { url: 'https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_busca_url_4.jpeg?alt=media' }
      ];

      let path = window.location.pathname;

      let origStr = orig + '';
      let indexInit = path.indexOf(origStr);
      let init = path.substring(0, indexInit + origStr.length + 1);

      let indexLast = path.indexOf('/ida');
      let last = path.substring(indexLast, path.length);


      let store = [];
      let i = 0;
      let inLoop = 0;
      while (store.length < 4) {
        var j = Math.floor((Math.random() * data.length));
        var temp = data[j];
        inLoop++;

        if (!store.find((row) => { return row.id === temp.id })) {
          temp.link = temp.text.replace(' - ', '');
          temp.link = init + temp.link + '-' + temp.id + last;
          temp.url = img[i].url;
          store.push(temp);
          i++;
        }

        if (inLoop - 50 > i) {
          break;
        }

      }
      setArray(store);
    }
    setLoading(false);
  }



  return loading ? (
    <div className="loading">
      <img src={Loading} className="loadingMask" />
    </div>
  ) : (
    <section className="otherResults">
      <h2> Descubra outros Destinos a partir da sua Origem:</h2>
      <div className="items">
        {array.map((e) =>
          <div className="item" style={{ background: `url(${e.url})` }}>
            <a href={e.link}>
              <p className="white-text">{e.text}</p>
            </a>
          </div>
        )}
      </div>
    </section>
  );
}
