import React, { useState, useContext, createContext, useEffect } from "react";
import Moment from "moment";
import UTILS from "../utils/utils";
import StatesConvert from "../components/mapaRotas/stateUf";

const GlobalContext = createContext();

export function GlobalProvider({ children }) {
  const context = useGlobalProvider();
  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

function useGlobalProvider() {
  const [purchase, setPurchase] = useState();
  const [user, setUser] = useState();
  const [cidade, setCidade] = useState();
  const [estado, setEstado] = useState();
  const [locate, setLocate] = useState();
  const [limit, setLimit] = useState(process.env.REACT_APP_SEAT_LIMIT);
  const [lastSeat, setLastSeat] = useState(false);
  const [noSeat, setNoSeat] = useState(false);
  const [configs, setConfigs] = useState({});
  const [sessionPayment, setsessionPayment] = useState(false);
  const [initContTime, setinitContTime] = useState();
  const [contSessionPayment, setcontSessionPayment] = useState();
  const [stopCount, setStopCount] = useState(true);
  const [ShowModalSession, setShowModalSession] = useState(false);

  useEffect(async () => {
    const stepStorage = await localStorage.getItem("sessionPayment");
    if (sessionPayment || stepStorage) {
      regressCont();
    }
  }, [sessionPayment]);

  useEffect(() => {
    UTILS.getLocation().then(async (value) => {
      let state = await StatesConvert(value.state);
      setEstado(state.toString().toUpperCase());
      setCidade(value.city.normalize('NFD').replace(/[\u0300-\u036f]/g, ""));
      setLocate(`${value.city.toString().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")} - ${state.toString().toUpperCase()}`);
    });
  }, []);

  useEffect(async () => {
    const stepStorage = await localStorage.getItem("sessionPayment");
    if (stepStorage) {
      setsessionPayment(true);
    }
  }, []);

  useEffect(() => {
    var id = "none";
    if (user != undefined && user.id != undefined) {
      id = user.id;
      localStorage.setItem(id, JSON.stringify({ limit }));
    }

    if (limit <= 0) {
      setNoSeat(true);
      setLimit(0);
      localStorage.setItem(id, JSON.stringify({ limit }));
    } else if (limit == 1) {
      setLastSeat(true);
      setNoSeat(false);
      localStorage.setItem(id, JSON.stringify({ limit }));
    } else {
      setLastSeat(false);
      setNoSeat(false);
      localStorage.setItem(id, JSON.stringify({ limit }));
    }
  }, [limit]);

  useEffect(() => {
    var id = "none";
    if (user != undefined && user.id != undefined) {
      id = user.id;
    }

    setLimit(limit);
    localStorage.setItem(id, JSON.stringify({ limit }));
  }, [user]);

  useEffect(() => {
    clearInterval(initContTime);

  }, [stopCount]);

  useEffect(() => {
    if (contSessionPayment === "00:00m") {
      clearInterval(initContTime);
    }
  }, [contSessionPayment]);

  useEffect(() => {
    if (contSessionPayment === "00:00m") {
      localStorage.removeItem("sessionPayment");
      localStorage.removeItem("step");
      localStorage.removeItem("transaction");
      clearInterval(initContTime);
      setsessionPayment(false);
      setShowModalSession(true);
    }
  }, [contSessionPayment]);

  async function regressCont() {
    const stepStorage = await localStorage.getItem("sessionPayment");
    const startTime = stepStorage ? stepStorage : Moment().format("DD/MM/YYYY HH:mm:ss");
    const timer = setInterval(() => {
      const endTime = Moment().format("DD/MM/YYYY HH:mm:ss");
      const time = Moment.utc(Moment(endTime, "DD/MM/YYYY HH:mm:ss").diff(Moment(startTime, "DD/MM/YYYY HH:mm:ss"))).format("mm:ss").split(':');
      const minut = 14 - time[0];
      const second = 59 - time[1];
      setcontSessionPayment(`${minut < 10 ? '0' + minut : minut}:${second < 10 ? '0' + second : second}m`);
    }, 1000);
    setinitContTime(timer);
  }

  return {
    purchase,
    setPurchase,
    user,
    setUser,
    configs,
    setConfigs,
    sessionPayment,
    setsessionPayment,
    contSessionPayment,
    setcontSessionPayment,
    stopCount,
    setStopCount,
    ShowModalSession,
    setShowModalSession,
    limit,
    setLimit,
    lastSeat,
    setLastSeat,
    noSeat,
    setNoSeat,
    estado,
    setEstado,
    cidade,
    setCidade,
    locate,
    setLocate,
  };
}
