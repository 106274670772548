import * as firebase from "./firebaseUtils";

const DB = {
  get: async (collection, arrayWhere = [], order = null) => {
    try {
      let data = await firebase.Firestore.collection(collection);
      if (arrayWhere.length > 0) {
        arrayWhere.forEach((where) => {
          data = data.where(where.field, where.condition, where.value);
        });
      }
      if( order ){
        data = data.orderBy(order.name, order.type);
      }
      data = data.get().then((querySnapshot) => {

        let data = querySnapshot.docs.map((doc) => {
          const resp = {
            id: doc.id,
            ...doc.data(),
          };
          return resp;
        });
        return data;
      });
      return data;
    } catch (error) {
      return error;
    }
  },

  save: async (collection, data) => {
    try {
      return await firebase.Firestore.collection(collection)
        .add(data)
        .then((response) => {
          const resp = {
            id: response.id,
            ...response.data(),
          };
          return resp;
        });
    } catch (error) {
      return error;
    }
  },

  getOne: async (collection, uid) => {
    try {
      return await firebase.Firestore.collection(collection)
        .doc(uid)
        .get()
        .then((response) => {
          if (response.exists) {
            const resp = {
              id: response.id,
              ...response.data(),
            };
            return resp;
          }
          return false;
        });
    } catch (error) {
      return error;
    }
  },

  update: async (collection, uid, data) => {
    try {
      return await firebase.Firestore.collection(collection)
        .doc(uid)
        .update(data)
        .then((response) => {
          if (response.exists) {
            return response.data();
          }
          return false;
        });
    } catch (error) {
      return error;
    }
  },

  getTotal: async () => {
    try {
      return await firebase.Firestore.collection("config")
        .get()
        .then((response) => {
          let config = response.docs[0];
          if (config.exists) {
            config = config.data();
            let average = {
              km: 0,
              km_add: 0,
              client: 0,
              client_add: 0,
            };

            let months;
            let today = new Date();
            let lastKmAt = new Date(config.last_km_at);
            let lastClientAt = new Date(config.last_client_at);

            months = (today.getFullYear() - lastKmAt.getFullYear()) * 12;
            months -= lastKmAt.getMonth();
            months += today.getMonth();
            average.km = config.last_km + months * config.average_km;
            average.km += (config.average_km / 30) * today.getDay();
            average.km_add = config.average_km / 2591000;
            average.km +=
              average.km_add *
              (today.getHours() * 60 + today.getMinutes() * 60);
            average.km = average.km.toFixed();

            months = (today.getYear() - lastClientAt.getYear()) * 12;
            months -= lastClientAt.getMonth();
            months += today.getMonth();
            average.client =
              config.last_client + months * config.average_client;
            average.client += (config.average_client / 30) * today.getDay();
            average.client_add = config.average_client / 2591000;
            average.client +=
              average.client_add *
              (today.getHours() * 60 + today.getMinutes() * 60);
            average.client = average.client.toFixed();

            return average;
          }
          return false;
        });
    } catch (error) {
      return error;
    }
  },
};

export default DB;
