import React, { useEffect, useState } from "react";
import "./index.scss";
import Moment from "moment";

var formP = {
  period: "",
  accommodation: "",
  terminalOut: "",
  terminalInn: "",
};
var dataFiltersInit = {
  classes: [],
  periodos: []
};

var showIn = false;

export default function Results(props) {
  const [dataFilters, setDataFilters] = useState(dataFiltersInit);
  const [showFilter, setshowFilter] = useState(false);

  const [form, setForm] = useState(formP);
  const [show, setShow] = useState(showIn);

  useEffect(() => {
    listClasse();
  }, []);

  useEffect(() => {
    getFilter();
  }, [form]);

  function getFilter() {
    let trata = props.data.lsServicos;
    if (form.accommodation !== "") {
      trata = trata.filter(
        (e) => e.classe.toLowerCase() === form.accommodation.toLowerCase()
      );
    }

    if (form.period !== '') {
      let period = dataFilters.periodos.find((row) => row.name === form.period);
      trata = trata.filter(
        (e) => Moment(e.saida).isBetween(Moment(e.dataCorrida + ' ' + period.startAt), Moment(e.dataCorrida + ' ' + period.endAt))
      );

    }

    props.refreshResult(trata);
  }

  function showDiv() {
    setShow(true);
  }

  function clearFilters() {
    setForm(formP);
    setShow(false);
  }

  function renderShowFilterPeriod() {
    const filtra = dataFilters.periodos.filter((e) => e.name === form.period);
    if (!filtra.length) return <div></div>
    return (
      <li className="nav-item" style={{ marginTop: "20px" }}>
        <a>
          <input type="radio" checked={filtra[0].name === form.period} /> {filtra[0].name}
        </a>
      </li>
    );
  }

  function listClasse() {
    const savePeriod = []
    let period = {};

    props.data.lsServicos.map(e => {
      var startTime = Moment(e.saida).format("HH:MM");

      if (startTime >= '06:00' && startTime <= '11:59') {
        period = {
          name: 'Manhã',
          startAt: '06:00',
          endAt: '11:59'
        }
        if (!savePeriod.find((row) => row.name === period.name))
          savePeriod.push(period);
      }
      if (startTime >= '12:00' && startTime <= '17:59') {
        period = {
          name: 'Tarde',
          startAt: '12:00',
          endAt: '17:59'
        }
        if (!savePeriod.find((row) => row.name === period.name))
          savePeriod.push(period);
      }
      if (startTime >= '18:00' && startTime <= '23:59') {
        period = {
          name: 'Noite',
          startAt: '18:00',
          endAt: '23:59'
        }
        if (!savePeriod.find((row) => row.name === period.name))
          savePeriod.push(period);
      }
      if (startTime >= '00:00' && startTime <= '05:59') {
        period = {
          name: 'Madrugada',
          startAt: '00:00',
          endAt: '05:59'
        }
        if (!savePeriod.find((row) => row.name === period.name))
          savePeriod.push(period);
      }
      return true;
    });
    const list = [];
    props.data.lsServicos.map((e) => {
      if (!list.includes(e.classe.toLowerCase())) {
        list.push(e.classe.toLowerCase());
      }
    });

    if (list.length) setshowFilter(true);

    setDataFilters({ periodos: savePeriod, classes: list });
  }

  function renderShowFilter() {
    const filtra = dataFilters.classes.filter((e) => e === form.accommodation);
    if (!filtra.length) return <div></div>
    return (
      <li className="nav-item" style={{ marginTop: "20px" }}>
        <a>
          <input type="radio" checked={filtra[0] === form.accommodation} /> {filtra[0]}
        </a>
      </li>
    );
  }

  return (
    showFilter ?
      <section className={`filters ${props.open && 'open'}`}>
        <div id="gofilters">
          {show == true && (
            <section className="goFilters">
              <div className="sidebar">
                <nav className="sidebar-nav">
                  <ul className="nav nav__filters">
                    <li className="nav-title">Filtros</li>

                    {renderShowFilterPeriod()}

                    {renderShowFilter()}

                    {form.terminalInn == "rodoviaria" && (
                      <li className="nav-item">
                        <a>
                          <input type="radio" checked="true" /> Rodoviária
                          Brasília
                        </a>
                      </li>
                    )}
                    {form.terminalOut == "rodoviaria" && (
                      <li className="nav-item">
                        <a>
                          <input type="radio" checked="true" /> Rodoviária
                          Localidade
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
              <div className="text-center">
                <button className="btn btn-sm" onClick={clearFilters}>
                  Limpar Filtros
                </button>
              </div>
            </section>
          )}
        </div>
        <nav className="sidebar-nav">
          {dataFilters.periodos.length !== 0 && (
            <ul className="nav">
              <li className="nav-title">Saída</li>
              <ul style={{ marginTop: "10px", marginBottom: "20px"  }} className="nav nav__classes">
                {dataFilters.periodos.map((e, index) => (
                  <li className="nav-item" key={index}>
                    <a>
                      <input
                        id={e.name}
                        type="checkbox"
                        value={e.name}
                        onClick={showDiv}
                        checked={form.period === e.name}
                        onChange={() => setForm({ ...form, period: (form.period === e.name ? "" : e.name) })}
                      />{" "}
                      <label htmlFor={e.name}> {e.name}</label>
                    </a>
                  </li>
                ))}
              </ul>
            </ul>
          )}
          <br></br>

          {dataFilters.classes.length !== 0 && (
            <ul className="nav">
              <li className="nav-title">Classe</li>
              <ul style={{ marginTop: "10px", marginBottom: "0px" }} className="nav nav__classes">
                {dataFilters.classes.map((e, index) => (
                  <li className="nav-item" key={index}>
                    <a>
                      <input
                        id={e}
                        type="checkbox"
                        value={e}
                        onClick={showDiv}
                        checked={form.accommodation === e}
                        onChange={() => setForm({ ...form, accommodation: (form.accommodation === e ? "" : e) })}
                      />{" "}
                      <label htmlFor={e}> {e}</label>
                    </a>
                  </li>
                ))}
              </ul>
            </ul>
          )}
          <br></br>
          {/* <ul className="nav">
          <li className="nav-title">Terminal de saída</li>
          <li className="nav-item">
            <a>
              <input
                id="rodoviaria"
                type="checkbox"
                value="rodoviaria"
                onClick={showDiv}
                checked={form.terminalOut === "rodoviaria"}
                onChange={(e) =>
                  setForm({ ...form, terminalOut: "rodoviaria" })
                }
              />{" "}
              <label htmlFor="rodoviaria"> Rodiviária</label>
            </a>
          </li>
        </ul>
        <br></br>
        <ul className="nav">
          <li className="nav-title">Terminal de chegada</li>
          <li className="nav-item">
            <a>
              <input
                id="rodoviaria"
                type="checkbox"
                value="rodoviaria"
                onClick={showDiv}
                checked={form.terminalInn === "rodoviaria"}
                onChange={(e) =>
                  setForm({ ...form, terminalInn: "rodoviaria" })
                }
              />{" "}
              <label htmlFor="rodoviaria"> Rodoviária</label>
            </a>
          </li>
        </ul>
                 */}
        </nav>
      </section>
      : ""
  );
}
